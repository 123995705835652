.ChefMenuCard{
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    border-radius: 0.6rem;
    overflow: hidden;
    border: 1px solid #292929;
}

.ChefMenuCard .ChefMenuCardImage{
    width: 100%;
    aspect-ratio: 1/1;
    /* height: 200px; */
}

.ChefMenuCard .ChefMenuCardImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ChefMenuCard .ChefMenuCardText{
    padding: 1rem;
    display: grid;
    gap: 0.5rem;
    background-color: #1F1F1F;
}
.ChefMenuCard .ChefMenuCardText h1{
    margin: 0;
    font-size: 1.2rem;
    color: #d9d9d9;
}
.ChefMenuCard .ChefMenuCardText p{
    margin: 0;
    font-size: 1rem;
    color: #707070;
    font-weight: 500;
}
.ChefMenuCard .ChefMenuCardText .chefname{
    color: 969696;
}

@media(min-width:750px){
    .ChefMenuCard .ChefMenuCardImage{
        width: 100%;
        height: 200px;
    }    
}
@media (min-width:0) and (max-width:280px){
    .ChefMenuCard .ChefMenuCardText h1{
        font-size: 5.5vw;
    }
    .ChefMenuCard .ChefMenuCardText p{
        font-size: 5vw;
    }
}