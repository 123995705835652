.detailEventWrapper *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.detailEventWrapper{
    padding-top: 150px;
    /* max-width:1440px; */
    max-width:1100px;
    height: auto;
    margin: auto;
}
.detailEventWrapper .detEvent-maiNGrid{
    display: grid;
    grid-template-areas: 'image' 'content';
    padding: 0 50px;
}
.detailEventWrapper .detEvent-maiNGrid .detEvent-image{
    border-radius: 15px;
    grid-area: image;
    width: 100%;
    height: 400px;
    overflow: hidden;
}
.detailEventWrapper .detEvent-maiNGrid .detEvent-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transform: scale(1);
    transition: all .5s;
}
.detailEventWrapper .detEvent-maiNGrid .detEvent-image:hover img{
    transform: scale(1.2);
}
.detailEventWrapper .detEvent-maiNGrid .detEvent-secondaryGrid{
    grid-area: content;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 50px;
    padding: 30px 0;
}
.detEvent-secondaryGrid .detEvent-details .detEvent-detailsHead{
    border-bottom: 1px solid #414141;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: start;
}.detEvent-secondaryGrid .detEvent-details .detEvent-detailsHead h1{
    font-size: 36px;
    color: #fff;
    max-width: 500px;
}.detEvent-secondaryGrid .detEvent-details .detEvent-detailsHead p{
    margin: 15px 0 20px 0;
    font-size: 18px;
    color: rgb(107, 107, 107);
}
.detEvent-secondaryGrid .detEvent-details .detEvent-detailsBody .detailsBodyHead{
    padding: 30px 0 0 0;
}.detEvent-secondaryGrid .detEvent-details .detEvent-detailsBody .detailsBodyHead h1{
    font-size: 30px;
    color: #fff;
}.detEvent-secondaryGrid .detEvent-details .detEvent-detailsBody .detailsBodyHead p{
    font-size: 18px;
    color: #6b6b6b;
    margin-top: 5px;
}
.detEvent-secondaryGrid .detEvent-details .detEvent-detailsBody .detailsBodyCard{
    display: flex;
    width: 100%;
    height: 300px;
    background-color: #1F1F1F;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 30px;
}
.detEvent-secondaryGrid .detEvent-details .detEvent-detailsBody .detailsBodyCard:hover .BodyCardImage img{
    transform: scale(1.2);
}
.detEvent-details .detEvent-detailsBody .detailsBodyCard .BodyCardImage{
    flex-shrink: 0;
    width: 50%;
    overflow: hidden;
}.detEvent-details .detEvent-detailsBody .detailsBodyCard .BodyCardImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transform: scale(1);
    transition: all .5s;
}
.detEvent-details .detEvent-detailsBody .detailsBodyCard .BodyCardText{
    padding: 20px;
}
.detEvent-detailsBody .detailsBodyCard .BodyCardText .bahanFlex{
    display: flex;
    flex-direction: column;
    align-items: center;
    inline-size: max-content;
}.BodyCardText .bahanFlex p{
    color: #fff !important;
    text-align: center;
}
.detEvent-details .detEvent-detailsBody .detailsBodyCard .BodyCardText h1{
    margin-bottom: 15px;
    width:70%;
    font-size: 22px;
    color: #fff;
}
.detEvent-details .detEvent-detailsBody .detailsBodyCard .BodyCardText p{
    color: #969696;
    font-size: 16px;
}
.bold-white-medium{
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 20px;
}
.flex-col{
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
}
.flex-col h1{
    font-size: 20px !important;
    margin: 0 !important;
}.flex-col p{
    font-size: 16px !important;
    margin: 0 !important;
}
.detEvent-maiNGrid .detEvent-secondaryGrid .detEvent-form{
    position: sticky;
    align-self:start;
    top: 120px;
}
.detEvent-secondaryGrid .detEvent-form .formEvent{
    border-radius: 15px;
    background-color: #1F1F1F;
    padding: 15px;
    margin-bottom: 30px;
}
.detEvent-secondaryGrid .detEvent-form .formEvent .separatorDate{
    color: #3f3f3f;
    display: flex;
    line-height: 2px;
    gap: 5px;
    flex-direction: row;
    align-items: center;
}.detEvent-secondaryGrid .detEvent-form .formEvent .separatorDate::before,
.detEvent-secondaryGrid .detEvent-form .formEvent .separatorDate::after{
    content: "";
    width: 100%;
    height: 1px;
    background-color: #3f3f3f;
}
.detEvent-secondaryGrid .detEvent-form .formEvent h1{
    font-size: 24px;
    color: #fff;
}
.detEvent-secondaryGrid .detEvent-form .formEvent .formDate{
    margin: 15px 0;
    display: flex;
    align-items: start;
    gap: 10px;
}
.detEvent-secondaryGrid .detEvent-form .formEvent .formDate img{
    margin-top: 3px;
}
.detEvent-secondaryGrid .detEvent-form .formEvent .formDate .formDate-text p{
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}
.text-graybase{
    color: #969696 !important;
}
.text-fade{
    color: #6b6b6b !important;
}
.medium-text{
    font-weight: 400 !important;
}
.text-red{
    color:#FF7D7D !important;
}
.PDEkontainer{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20vh;
    padding-bottom: 10vh;
    font-family: Arial, Helvetica, sans-serif;
}

.PDEtrans-appear {
    opacity: 0;
    transform: translateX(50%);
}
.PDEtrans-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1000ms;
}
.PDEtrans-enter {
    opacity: 0;
}
.PDEtrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.PDEtrans-exit {
    opacity: 1; 
    transform: translateX(0);
}
.PDEtrans-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 1000ms;
}

.PDEheader{
    margin-left: 20px;
    margin-right: 20px;
    height: 391px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.PDEkontenkiri{
    flex:65%;
    margin-top: 64px;
    margin-left: 20px;
    margin-right: 20px;
}

.PDEkontenkanan{
    flex:35%;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 64px;
}

.PDEflexkiri{
    flex: 70%;
}

.PDEflexkanan{
    flex: 30%;
    text-align: right;
    margin-left: 20px;
}

.PDEline{
    height: 0.5px;
    background-color: #C4C4C4;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
}

.PDEmargin{
    margin-top: 64px;
}

.PDEmargin2{
    margin-top: 24px;
}

.PDEmargin3{
    margin-top: 36px;
}


.PDEslot{
    margin-top: 16px;
    margin-left: 36px;
}

.PDEicon2{
    height: 20px;
    margin-top: -2px;
    margin-right: 16px;
}

.PDEstyle{
    margin-top: 16px;
}
.PDEbuttonDisable{
    width: -webkit-fill-available;
    width: -moz-available;
    height: 60px;
    border: none;
    outline: none;
    background-color: grey;
    cursor: pointer;
    border-radius: 15px;
}

.PDEbutton{
    width: -webkit-fill-available;
    width: -moz-available;
    height: 60px;
    border: none;
    outline: none;
    background-color: #FCCC24;
    cursor: pointer;
    border-radius: 15px;
}

.PDEbutton2{
    width: 40%;
    margin-left: auto;
    border: none;
    outline: none;
    background-color: #FCCC24;
    cursor: pointer;
    border-radius: 15px;
}

.PDEbutton2:active{
    background-color: rgba(216, 175, 32, 1);
}

.PDEbutton:active{
    background-color: rgba(216, 175, 32, 1);
}

.PDEmenucard{
    border-radius: 15px;
    background-color: #1F1F1F;
    height: 440px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.PDEmenukiri{
    flex: 65%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.PDEmenukanan{
    flex: 35%;
    padding:25px;
}

.PDEicon{
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
}

.PDEeventdate{
    border-radius: 15px;
    padding: 25px;
    background-color: #1F1F1F;
    width: -webkit-fill-available;
    width: -moz-available;
}

.PDEflexcon{
    display: flex!important;
}

.PDElocation{
    border-radius: 15px;
    padding: 25px;
    background-color: #1F1F1F;
    width: -webkit-fill-available;
    width: -moz-available;
}

.mobile{
    display: block;
}

@media (min-width: 1000px) {
    .mobile{
        display: none;
    }
}

@media (max-width: 1000px) {
    .detEvent-maiNGrid .detEvent-secondaryGrid .detEvent-form{
        position: relative;
        top: 0;
    }
    .detailEventWrapper .detEvent-maiNGrid{
        padding: 0;
    }
    .detailEventWrapper .detEvent-maiNGrid .detEvent-secondaryGrid{
        grid-template-columns: 1fr;
        padding: 50px;
    }
    .detEvent-secondaryGrid .detEvent-details .detEvent-detailsHead h1{
        font-size: 28px;
    }
    .detEvent-secondaryGrid .detEvent-details .detEvent-detailsHead p,
    .detEvent-secondaryGrid .detEvent-details .detEvent-detailsBody .detailsBodyHead p{
        font-size: 16px;
    }
    .flex-col h1{
        font-size: 18px !important;
    }
    .bold-white-medium{
        font-size: 16px;
    }
    .detEvent-secondaryGrid .detEvent-details .detEvent-detailsBody .detailsBodyHead h1{
        font-size: 24px;
    }
    .detailEventWrapper .detEvent-maiNGrid .detEvent-image{
        border-radius: 0;
    }
    .detailEventWrapper{
        padding-top: 0;
    }
    .PDEkontainer{
        padding-top: 0px;
    }
    
    .desktop{
        display: none;
    }
    .PDEheader{
        margin-left: 0px;
        margin-right: 0px;
        height: 300px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .PDEkonten{
        flex-direction: column;
    }

    .PDEkontenkiri{
        margin-top: 32px;
    }

    .PDEmargin2{
        margin-top: 16px;
    }

    .PDEflex{
        flex-direction: column;
    }

    .PDEserving{
        margin-top: 12px;
    }

    .PDEstyle{
        margin-top: 12px;
    }

    .PDEmargin3{
        margin-top: 24px;
    }

    .PDEmargin{
        margin-top: 48px;
    }

    .PDEicon{
        height: 32px;
    }

    .PDEmenucard{
        flex-direction: column;
    }
}

@media (max-width:520px){
    .detEvent-details .detEvent-detailsBody .detailsBodyCard .BodyCardText h1{
        font-size: 20px;
        width: 100% ;
    }
    .detailEventWrapper .detEvent-maiNGrid .detEvent-secondaryGrid{
        padding: 20px;
    }
    .detEvent-secondaryGrid .detEvent-details .detEvent-detailsHead{
        flex-direction: column;
        padding-bottom: 20px;
    }
    .detEvent-details .detEvent-detailsBody .detailsBodyCard .BodyCardText h1,
    .detEvent-secondaryGrid .detEvent-form .formEvent h1{
        font-size: 18px;
    }
    .detEvent-secondaryGrid .detEvent-form .formEvent .formDate .formDate-text p{
        font-size: 14px;
        margin-bottom: 5px;
    }
}