@keyframes slideUpMobile{
    0%{
        transform: translateX(-50%) translateY(100svh);
        opacity: 0;
    }
    100%{
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
}

@keyframes slideDownMobile{
    0%{
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
    100%{
        transform: translateX(-50%) translateY(100svh);
        opacity: 0;
    }
}

.slideUpMobile{
    animation: slideUpMobile 2s ease-in-out;
    animation-fill-mode: forwards;
}

.slideDownMobile{
    animation: slideDownMobile 2s ease-in-out;
    animation-fill-mode: forwards;
}

.gap-5{
    gap: 0.5rem;
}
.align-center{
    align-items: center;
}
.pointer{
    cursor: pointer;
}

/* Bagian Parent  */
.detailMenuNavMobile{
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 10;
}
.detailMenuWrapper{
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

/* Bagian Kanan  */
.detailMenuRight .detailMenuImage{
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
}
.detailMenuRight .detailMenuImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
}
.detailMenuRight .detailMenuImage:hover .MenuImageDetail{
    bottom: 0;
}
.detailMenuRight .detailMenuImage .MenuImageDetail{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px 20px 20px;
    width: 100%;
    position: absolute;
    bottom: -25rem;
    z-index: 2;
    cursor: pointer;
    transition: all .5s;
}
.detailMenuRight .detailMenuImage .MenuImageDetail::after{
    content: "";
    width: 100%;
    height: 300px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(to top, #070707, #07070700);
}
.detailMenuRight .detailMenuImage .MenuImageDetail h1{
    font-size: 1rem;
    color: #d9d9d9;
    margin: 0;
}
.detailMenuRight .detailMenuImage .MenuImageDetail p{
    font-size: 1rem;
    color: #707070;
    font-weight: 500;
}
.detailMenuRight .detailMenuContents{
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 1rem;
    padding: 0 20px;
    position: relative;
}
.detailMenuRight .detailMenuContents .head{
    padding: 15px 0;
    border-bottom: 1px solid #212121;
    cursor: pointer;
}
.detailMenuRight .detailMenuContents .head h1{
    margin: 0 0 0.3rem 0;
    font-size: 1.5rem;
    color: #d9d9d9;
}
.detailMenuRight .detailMenuContents .head p{
    margin: 0;
    font-size: 1rem;
    color: #969696;
}
.detailMenuRight .detailMenuContents .head .chefname{
    font-weight: 500;
}
.detailMenuRight .detailMenuContents .head .ratingDetail{
    font-weight: 700;
    color: #d9d9d9;
}
.detailMenuRight .detailMenuContents .body{
    display: grid;
    gap: 1.2rem;
}
.detailMenuRight .detailMenuContents .body .bodyText.desc *{
    color: #707070 !important;
}
.detailMenuRight .detailMenuContents .body .bodyText h1{
    margin: 0;
    font-size: 1rem;
    color: #d9d9d9;
    font-weight: 500;
}
.detailMenuRight .detailMenuContents .body .bodyText p{
    font-size: 1rem;
    color: #707070;
    margin: 0;
}
.detailMenuRight .detailMenuContents .body .bodyToolsImg{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    width: 100%;
}
.detailMenuRight .detailMenuContents .body .bodyToolsImg .icons{
    display: grid;
    place-items: center;
}
.detailMenuRight .detailMenuContents .body .bodyToolsImg .icons p{
    font-size: 1rem;
    color: #d9d9d9;
}
@keyframes slideUp{
    from{
        bottom: -10rem;
    }
    to {
        bottom: 1rem;
    }
}
@keyframes slideDown{
    from{
        bottom: 1rem;
    }
    to {
        bottom: -10rem;
    }
}
.slideUp{
    animation: slideUp .5s;
    animation-fill-mode: forwards;
}
.slideDown{
    animation: slideDown .5s;
    animation-fill-mode: forwards;
}
.detailMenuRight .detailMenuContents .button{
    box-sizing: border-box;
    padding: 0.8rem;
    width: 100%;
    border-radius: 0.6rem;
    background-color: #FCCC24;
    color: #151515;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    display: none;
}
.detailMenuRight .detailMenuContents .button.mobile{
    z-index: 100;
    display: flex;
    position: fixed;
    width: calc(100% - 2.5rem);
    left: 50%;
    transform: translateX(-50%) translateY(100svh);
    bottom: 1.5rem;
    opacity: 0;
}
.detailMenuRight .detailMenuContents .button h1{
    margin: 0;
    font-size: 1.1rem;
}
.detailMenuRight .detailMenuContents .button p{
    margin: 0;
    font-weight: 500;
    font-size: 1rem;
}

/* Bagian Kiri  */
.detailMenuWrapper.chef .detailMenuLeft .bannerDouble .orline-separator::before,
.detailMenuWrapper.chef .detailMenuLeft .bannerDouble .orline-separator::after{
    content: "";
    width: 100%;
    height: 1px;
    background-color: #202020;
}
.detailMenuWrapper.chef .detailMenuLeft .bannerDouble .orline-separator{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
    color: #202020;
}
.detailMenuWrapper.chef .detailMenuLeft{
    padding: 15px 20px 30px 20px;
}
.detailMenuWrapper.chef .detailMenuLeft .line-separator{
    padding: 1rem 0;
}
.detailMenuWrapper.chef .detailMenuLeft .bannerDouble{
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}
.detailMenuWrapper.chef .detailMenuLeft .bannerDouble .chefBannerSingle{
    margin-top: 0;
}
.detailMenuWrapper.chef .detailMenuLeft{
    padding-top: 1rem;
    border-top: 1px solid #1f1f1f;
}
.detailMenuWrapper.chef .detailMenuLeft .cardList{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}
.detailMenuLeft{
    padding: 15px 20px 120px 20px;
    height: fit-content;
    position: sticky;
    top: 120px;
}
.detailMenuLeft .cardListHead{
    margin-bottom: 1rem;
}
.detailMenuLeft .cardListHead h1{
    margin: 0;
    font-size: 1.5rem;
    color: #d9d9d9;
}
.detailMenuLeft .cardListHead p{
    font-size: 1rem;
    color: #d9d9d9;
}

/* Profile chef  */
.detailMenuLeft .cardChefProfile{
    margin-top: 1.5rem;
    background-color: #1F1F1F;
    border: 1px solid #292929;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.6rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: none;
}
.detailMenuLeft .cardChefProfile:hover .pattern{
    right: 5rem;
}
.detailMenuLeft .cardChefProfile .pattern{
    z-index: 1;
    opacity: 30%;
    position: absolute;
    right: -5rem;
    width: 200px;
    transition: all .5s;
}
.profile {
    position: relative;
    z-index: 2;
}
.detailMenuLeft .cardChefProfile .ChefProfileImage{
    max-width: 70px;
    width: 100%;
    height: 70px;
    border-radius: 0.3rem;
    overflow: hidden;
}
.detailMenuLeft .cardChefProfile .ChefProfileImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
}
.detailMenuLeft .cardChefProfile .ChefProfileText p{
    font-size: 1rem;
    font-weight: 500;
    color: #707070;
}
.detailMenuLeft .cardChefProfile .ChefProfileText h1{
    margin: 0;
    font-size: 1.3rem;
    color: #d9d9d9;
}
.detailMenuLeft .cardChefProfile .ChefProfileRating h1{
    margin: 0;
    font-size: 1.3rem;
    color: #d9d9d9;
}



/* untuk detail chef  */
.detailMenuWrapper.chef .detailMenuRight .detailMenuContents .body .bodyText p{
    overflow: hidden;
}
.detailMenuWrapper.chef .detailMenuRight .detailMenuContents .body .bodyText.desc button{
    background-color: transparent;
    border: none;
    font-size: 1rem;
    color: #FCCC24 !important;
    padding: 0;
    cursor: pointer;
    margin: 0.5rem 0 0 0;
}

@media (min-width:281px) and (max-width:430px){
    .detailMenuWrapper.chef .detailMenuLeft .cardList{
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}
@media (min-width:0) and (max-width:280px){
    .detailMenuWrapper.chef .detailMenuLeft .cardList{
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .detailMenuRight .detailMenuContents .head h1,
    .detailMenuLeft .cardListHead h1{
        font-size: 8.3vw;
    }
    .detailMenuRight .detailMenuContents .head p,
    .detailMenuRight .detailMenuContents .body .bodyText p,
    .detailMenuRight .detailMenuContents .body .bodyText h1,
    .detailMenuRight .detailMenuContents .body .bodyToolsImg .icons p,
    .detailMenuLeft .cardListHead p,
    .detailMenuRight .detailMenuContents .button h1,
    .detailMenuRight .detailMenuContents .button p,
    .detailMenuRight .detailMenuImage .MenuImageDetail p,
    .detailMenuRight .detailMenuImage .MenuImageDetail h1
    {
        font-size: 5.2vw;
    }
}

@media (min-width:750px){
    .detailMenuWrapper.chef .detailMenuLeft .bannerDouble .orline-separator{
        font-size: 1.5rem;
    }
    .detailMenuLeft .cardChefProfile{
        display: flex;
    }
    .detailMenuRight .detailMenuContents .button{
        margin-top: 1.5rem;
        display: flex;
    }
    .detailMenuRight .detailMenuContents .button.mobile{
        display: none;
    }
    .detailMenuRight .detailMenuImage{
        display: none;
    }
    .detailMenuWrapper{
        max-width: 1100px;
        margin: auto;
        box-sizing: border-box;
        padding: 100px 20px 0 20px;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .detailMenuRight .detailMenuContents{
        padding: 0;
    }
    .detailMenuRight{
        order: 2;
        padding-left: 2rem;
        border-left: 1px solid #1f1f1f;
    }
    .detailMenuWrapper.chef .detailMenuRight .detailMenuImage{
        display: block;
        border-radius: 0.6rem;
        height: 350px;
        overflow: hidden;
    }
    .detailMenuWrapper.chef .detailMenuRight .detailMenuContents{
        height: fit-content;
    }
    .detailMenuWrapper.chef .detailMenuRight .detailMenuContents .head{
        padding: 0 0 15px 0;
    }
    .detailMenuWrapper.chef{
        grid-template-columns: 1fr;
    }
    .detailMenuWrapper.chef .detailMenuRight{
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        gap: 1.5rem;
        order: 1;
        padding-left: 0;
        border-left: none;
    }
    .detailMenuWrapper.chef .detailMenuLeft{
        padding: 1rem 0;
        order: 2;
    }
    .detailMenuWrapper.chef .detailMenuLeft .cardList{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.5rem;
    }
    .detailMenuLeft{
        padding: 0;
        order: 1;
    }
}

@media (min-width:1000px){
    .detailMenuNavMobile{
        display: none;
    }
    .detailMenuWrapper{
        padding: 150px 50px 50px 50px;
    }
}