.SBPtrans-appear {
    opacity: 0;
    transform: translateX(50%);
}
.SBPtrans-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1000ms;
}
.SBPtrans-enter {
    opacity: 0;
}
.SBPtrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.SBPtrans-exit {
    opacity: 1; 
    transform: translateX(0);
}
.SBPtrans-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 1000ms;
}

.SBPkontainer{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20vh;
    padding-bottom: 10vh;
    font-family: Arial, Helvetica, sans-serif;
}

.SBPtittle{
    margin-left: 20px;
}

.SBPkontainerkiri{
    flex: 50%;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 64px;
    margin-bottom: 32px;
}

.SBPkontainerkanan{
    flex: 50%;
    padding-left: 20px;
    padding-right: 20px;
}

.SBPmargin{
    margin-top: 18px;
}

.SBPmargin2 {
    /* gap: 15px; */
    margin-top: 14px;
    /* margin-left: 15px; */
}

.SBPmargin3{
    margin-top: 4px;
}

.SBPmargin4{
    margin-top: 47px;
}

.SBPimage{
    height: 71px;
    width: 71px;
    border-radius: 15px;
    margin-right: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.SBPline{
    height: 0.5px;
    background-color: #C4C4C4;
}

.SBPicon{
    margin-top: 2px;
    height: 24px;
    margin-right: 8px;
}

.SBPicon2{
    height: 31px;
    
}

.SBPtools{
    text-align: center;
    margin-right: 16px;
}

.SBPkontenputih{
    background-color: white;
    padding: 45px;
    border-radius: 15px;
}

.SBPform{
    width: 100%;
    border-radius: 15px;
    border: 1px solid #C4C4C4;
    outline: none;
    height: 56px;
    flex:80%;
    padding-left: 15px;
}

.SBPform:focus{
    border: 1px solid #FCCC24;
}

.SBPbutton{
    flex:20%;
    height: auto;
    border: none;
    outline: none;
    margin-left: 15px;
    background-color: #FCCC24;
    cursor: pointer;
    border-radius: 15px;
}

.SBPbutton2{
    width: -webkit-fill-available;
    width: -moz-available;
    height: 56px;
    border: none;
    outline: none;
    background-color: #FCCC24;
    cursor: pointer;
    border-radius: 15px;
}

.SBPbutton:active{
    background-color: rgba(216, 175, 32, 1);
}

.SBPbutton2:active{
    background-color: rgba(216, 175, 32, 1);
}

@media (max-width: 1000px) {
    .SBPkontainer{
        padding-top: 80px;
    }

    .SBPkonten{
        /* flex-direction: column-reverse; */
        flex-direction: column;
        margin-top: 32px;
    }

    .SBPkontenputih{
        padding: 35px;
    }

    .SBPmargin{
        margin-top: 16px;
    }

    .SBPform{
        height: 38px;
        flex: 65%!important;
    }

    .SBPbutton{
        flex: 35%!important;
    }

    .SBPmargin2{
        /* gap: 15px; */
        margin-top: 12px;
    }
    
    .SBPmargin4{
        margin-top: 30px;
    }

    .SBPkontainerkiri{
        margin-right: 0;
    }

    .SBPicon{
        height: 21px;
        margin-right: 8px;
    }

    .SBPmargin3{
        margin-top: 8px;
    }
}

@media (max-width:400px){
    .SBPmargin2{
        flex-wrap:wrap;
    }
    .SBPbutton{
        height: 40px;
    }
}