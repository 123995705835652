/** otpInputs.css **/

.LoginKonten.FPOTP .OTPInputWrap input{
    width: 100%;
    height: 60px;
}
.LoginKonten.FPOTP .OTPInputWrap input::placeholder{
    font-size: 24px;
    color: #e1e1e1;
}
.LoginKonten.FPOTP .OTPInputWrap input:focus::placeholder{
    color: #FCCC24;
}

.digitGroup {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
    }
    
    .digitGroup input {
     outline: 0;
     user-select: none !important;
     width: 50px;
     height: 50px;
     background-color: #f5f5f5;
     font-weight: bold !important;
     border-radius: 5px;
     border: 0 !important;
     line-height: 50px;
     text-align: center;
     font-size: 24px;
     color: #8d8d8d;
     margin: 0;
    }
    
    .digitGroup input:focus {
        color: #FCCC24;
        background-color: #fccd2441;
        outline: 2px solid #FCCC24 !important;
        outline-offset: 2px;
    }
    
    .digitGroup input:active {
        color: #FCCC24;
        background-color: #fccd2441;
        outline: 2px solid #FCCC24 !important;
        outline-offset: 2px;
    }
    
    .digitGroup .splitter {
     padding: 5px 0;
     color: rgb(0, 0, 0);
     font-size: 25px;
     margin: 0;
    }
    
    .prompt {
     margin-bottom: 20px;
     font-size: 20px;
     color: white;
    }
    
    .formSection {
     max-width: 500px;
     margin: auto;
     border-radius: 10px;
     box-shadow: 0px 0px 8px #ddd;
     padding: 20px;
    }
    
    .w-100 {
     width: 100%;
    }
    
    /* Media query for mobile devices */
    @media (max-width: 480px) {
     .digitGroup {
      gap: 5px !important;
     }
     .digitGroup input {
      width: 40px !important;
     }
     .digitGroup .splitter {
      font-size: 25px !important;
     }
    }