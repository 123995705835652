.CardContainer{
    position: relative;
    width: 100%;
    height: 650px;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    cursor: pointer;
    overflow: hidden;
}

.CardContainer .cardImage{
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all .5s;
    transform: scale(1) rotate(0);
}

.CardContainer:hover .cardImage{
    transform: scale(2) rotate(45deg);
}

.CardContainer .gradient{
    position: absolute;
    width: 100%;
    height: 75%;
    padding: 0px;
    bottom: 0;
    background: linear-gradient(transparent,black);
}

.CardContainer .CategoryTitle{
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 600;
    position: absolute;
    bottom: 50px;
    left: 50px;
    transition: all .5s;
    margin: 0;
}

.CardContainer:hover .CategoryTitle{
    bottom:100px;
}

.CardContainer .CategoryDesc{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    width: 80%;
    position: absolute;
    top:90%;
    left:50%;
    transform: translateX(-50%);
    margin: 0;
    transition: top 0.5s, color 0.3s;
}

.CardContainer:hover .CategoryDesc{
    top:84%;
    color: white;
}
@media(max-width:1500px){
    .CardContainer{
        height: 500px;
    }
}