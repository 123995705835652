.MyBookWrap *{
    margin: 0;
}
.MyBookWrap{
    max-width:1100px;
    /* max-width:1440px; */
    height: 400px;
    padding: 150px 0 0 0;
    margin: auto;
    background-color: red;
}
.MyBookWrap .MyBookHeader

.rating {
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    line-height: 56px;
}

.MBPtrans-appear {
    opacity: 0;
    transform: translateY(100%);
}
.MBPtrans-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 1000ms;
}
.MBPtrans-enter {
    opacity: 0;
}
.MBPtrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.MBPtrans-exit {
    opacity: 1; 
    transform: translateY(0);
}
.MBPtrans-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 500ms, transform 1000ms;
}

.rating > input{ 
    display:none;
}
    
.rating > label {
    position: relative;
    font-size: 49px;
    color: #C4C4C4;
    cursor: pointer;
    margin-right: 32px;
}
    
.rating > label::before{
    content: "\2605";
    position: absolute;
    opacity: 1;
}
    
.rating > label:hover:before,
.rating > label:hover ~ label:before {
    opacity: 1 !important;
    color: #FCCC24!important;
}
    
.rating > input:checked ~ label:before{
    opacity:1;
    color: #FCCC24;
}
    
.rating:hover > input:checked ~ label:before{ 
    opacity: 1; 
    color: #C4C4C4;
}

.PSSformgroup{
    gap: 0px;
    margin-top: 26px;
    display: flex;
    /* cursor: pointer; */
}
.PSSformgroup.mbp{
    width: 200px !important;
    margin-top: 0 !important;
    cursor: pointer !important;
}
.PSSpertanyaan.mbp{
    margin-top: 0 !important;
}
.PSSpertanyaanflex{
    flex: 100%;
    display: flex;
    /* flex-direction: column; */
}

.PSSpertanyaantext{
    color: #6F6F6F;
    margin-right: 8px;
}

.PSSiconkanan{
    height: 16px;
    margin-left: auto;
    cursor: pointer;
}
.textPSS{
    padding: 15px;
    transition: all .5s;
}
.textPSS:hover{
    background-color: #f1f1f1;
}
.PSSpopupkontainer{
    position: absolute;
    width: auto;
    height: fit-content;
    border:1px solid #f3f3f3;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin-top: 75px;
}
.PSSpopupkontainer.mbp{
    width: 100%;
    top: 45px;
    box-sizing: border-box;
    margin-top: 0 !important;
}
.PSSpertanyaantext.mbp{
    color: #fff !important;
}
.grid-booking-1{
    display:grid;
    grid-template-columns:1fr;
    gap: 1rem;
}
.MBPkontainer{
    /* max-width: 1440px; */
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding: 150px 100px;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing:border-box;
}
.hidden{
    display: none;
}
.MBPkonten{
    position: relative;
    background-color: white;
    padding: 45px;
    border-radius: 15px;
    margin: 2rem 0;
}
.MBPStamp{
    right: 1rem;
    top: 1rem;
    position: absolute;
    max-width: 200px;
    width: 100%;
    height: 200px;
    border-radius: 100%;
}
.MBPStamp img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 10%;
}

.MBPstatus1{
    margin-left: 24px;
    padding: 10px;
    border-radius: 15px;
    color: #7F6200;
    border: 2px solid #7F6200;
}

.MBPstatus2{
    margin-left: 24px;
    padding: 10px;
    border-radius: 15px;
    color: #006C1E;
    border: 2px solid #006C1E;
}

.MBPstatus3{
    margin-left: 24px;
    padding: 10px;
    border-radius: 15px;
    color: #FA591D;
    border: 2px solid #FA591D;
}

.MBPmargin1{
    margin-top: 55px;
}

.MBPmargin2{
    margin-top: 24px;
}

.MBPmargin3{
    margin-top: 12px;
}

.MBPimage{
    height: 47px;
    width: 47px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
    margin-right: 16px;
}

.MBPicon{
    height: 22px;
}


.MBPiconqr{
    height: 16px;
    position: absolute;
    top: 35px;
    cursor: pointer;
}

.MBPform{
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #C4C4C4;
    outline: none;
    width: -webkit-fill-available;
    width: -moz-available;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
}

.MBPform:focus{
    border: 1px solid #FCCC24;
}

.MBPyellow{
    border: 1px solid #FCCC24!important;
}

.MBPsubject{
    height: 56px;
    padding-left: 25px;
    border: 1px solid #C4C4C4;
    border-radius: 15px;
    align-items: center;
    padding-right: 25px;
    cursor: pointer;
    position: relative;
}

.MBPsubjectchoice{
    overflow: scroll;
    max-height: 132px;
    position: absolute;
    bottom: 0;
    padding: 20px;
    transform: translateY(110%);
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: -webkit-fill-available;
    width: -moz-available;
    left: 0;
}

.MBPsubjectchoice::-webkit-scrollbar{
    width:0;
    height:0;
    scrollbar-width: none;
}

.MBPkontenkiri{
    flex:65%;
    margin-right: 50px;
}

.MBPkontenkanan{
    flex:35%;
    position: relative;
}

.MBPkananwrapper{
    position: absolute;
    bottom: 0;
    width: -webkit-fill-available;
    width: -moz-available;
    margin-right: 76px;
}

.MBPbutton1{
    width: -webkit-fill-available;
    width: -moz-available;
    height: 60px;
    border: none;
    outline: none;
    background-color: #FCCC24;
    cursor: pointer;
    border-radius: 15px;
}
.medium.mbp{
    color: #6b6b6b;
    cursor: pointer;
}
.medium.MBPunderline{
    color: #fff !important;
}
.MBPbutton3{
    width: -webkit-fill-available;
    width: -moz-available;
    height: 60px;
    border: none;
    outline: none;
    background-color: #00ff48;
    cursor: pointer;
    border-radius: 15px;
}

.MBPbuttonpopup{
    display: block;
    margin-left: auto;
    border: none;
    outline: none;
    background-color: #FCCC24;
    cursor: pointer;
    border-radius: 15px;
    padding: 15px 25px 15px 25px;
}

.MBPbutton2{
    width: -webkit-fill-available;
    width: -moz-available;
    height: 60px;
    border: none;
    outline: none;
    background-color: #D8D8D8;
    cursor: pointer;
    border-radius: 15px;
}

.MBPmoremobile{
    display: none;
}

.MBPbuttonmore{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 56px;
    height: 56px;
    border-radius: 15px;
    border: 2px solid #6F6F6F;
    cursor: pointer;
}

.MBPiconbuttonmore{
    position: absolute;
    width: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
/* 
.MBPtittle{
    margin-left: 20px;
} */

.MBPheader{
    display:flex;
    align-items:center;
    justify-content:space-between;
}

.MBPheadernavBookType{
    margin-left: auto;
}

.MBPheadernav{
    margin-top: 2rem;
}

.MBPunderline{
    border-bottom: 2px solid #FCCC24;
    padding-bottom: 8px;
}

.MBPqrwrapper{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
}

.MBPqrouter{
    width: 100%;
    height: 100%;
    background-color: #111111;
    opacity: 0.8;
}

.MBPqrkonten{
    min-width: 400px;
    width: 40vw;
    height: max-content;
    z-index: 10;
    position: absolute;
    padding: 35px;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 15px;
}

.ratingwrapper{
    max-height: 40vh;
    overflow-y: scroll;
}

.ratingwrapper::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}

.ratingwrapper::-webkit-scrollbar-thumb {
	background-color: #000000;
    border-radius: 4px;
}

.ratingwrapper::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
    border-radius: 4px;
}

.MBPqrimg{
    height: 200px;
    display: flex;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
}

.MBPqrchefname{
    margin-top: 32px;
    text-align: center;
}

.MBPpopup1{
    position: absolute;
    background-color: white;
    padding: 25px;
    width: max-content;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    right: 0;
    bottom: 0;
    transform: translateY(110%);
}
.mobile{
    display: none;
}
.gap-10{
    gap: 2rem;
}

@media (max-width: 1000px) {
    .MBPkontainer{
        padding: 150px 50px;
    }

    .mobile{
        display: unset;
    }

    .rating {
        display: flex;
        flex-direction: row-reverse;
        justify-content: left;
        line-height: 50px;
    }
        
    .PSSformgroup{
        flex-wrap: wrap;
    }

    .PSSiconkanan{
        margin-left: auto;
        cursor: pointer;
    }

    .PSSpopupkontainer{
        width: 100%;
    }
        
    .rating > input{ 
        display:none;
    }
        
    .rating > label {
        position: relative;
        font-size: 32px;
        color: #C4C4C4;
        cursor: pointer;
        margin-right: 16px;
        margin-top: 16px;
    }

    .desktop{
        display: none;
    }

    .MBPmoremobile{
        display: unset;
        height: 24px;
        margin-left: 4px;
    }

    .MBPsubject{
        height: 46px;
    }

    .MBPqrkonten{
        min-width: unset;
        width: 70%;
    }

    .MBPkonten{
        padding: 30px;
    }

    /* .MBPheadernav{
        margin-top: 36px;
        margin-left: 20px;
    } */
    .MBPkonten{
        flex-direction: column;
    }

    .MBPkananwrapper{
        position: relative;
    }

    .MBPkontenkiri{
        margin-right: 0;
        margin-bottom: 24px;
    }

    .MBPimage{
        height: 70px;
        width: 70px;
    }

    .MBPmargin1{
        margin-top: 32px;
    }
    
    .MBPmargin2{
        margin-top: 16px;
    }

    .flex-center{
        display: flex;
        align-items: center;
        gap: 2px;
    }

    .MBPmobileline{
        height: 1px;
        background-color: #C4C4C4;
        margin-bottom: 24px;
    }

    .MBPbuttonmore{
        display: none;
    }

    .MBPharga{
        flex-direction: column;
    }

    .MBPkananwrapper{
        margin-right: 0;
        display: flex;
    }

    .MBPbutton1{
        font-size: 12px!important;
        height: 36px;
        width: 140px;
        margin-left: auto;
    }

    .MBPbutton3{
        font-size: 12px!important;
        height: 36px;
        width: 140px;
        margin-left: auto;
    }

    .MBPbutton2{
        font-size: 12px!important;
        height: 36px;
        width: 140px;
        margin-left: auto;
    }
}

@media (max-width:580px){
    .MBPheader{
        flex-direction: column;
        gap: 2rem;
    }
    .MBPheadernav{
        place-content: center;
    }
}

@media (max-width:520px){
    .MBPkontainer{
        padding: 100px 20px;
    }
    .big.mbp{
        font-size: 1.6rem !important;
    }
    .medium.mbp{
        font-size: 1rem !important;
    }
}

@media (max-width:329px){
    .big.mbp{
        font-size: 1.4rem !important;
    }
    .medium.mbp{
        font-size: 0.8rem !important;
    }
}