body{
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
}
main *{
    box-sizing: border-box;
}

@keyframes slideUp {
    0%{
        transform: translateY(100%);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slideDown {
    0%{
        transform: translateY(0);
        opacity: 1;
    }
    100%{
        transform: translateY(100%);
        opacity: 0;
    }
}
.slideUp{
    animation-name: slideUp;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
}
.slideDown{
    animation-name: slideDown;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
}


.hidden{
    display: none !important;
}
.show{
    display: block !important;
}
.container{
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 50px;
    box-sizing: border-box;
}
.container.first{
    padding: 150px 50px 0 50px;
}
.container.separating{
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,2fr);
}
.container.separating.plan{
    grid-template-columns: minmax(0,1.3fr) minmax(0,2fr);
}
.right__parts{
    height: 100%;
    border-left: 1px solid #1f1f1f;
    padding: 1.5rem 0 0 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;
}
.right__parts .scrollPoint{
    position: absolute;
    top: -90px;
}
.right__parts .back__navigate{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding: 2rem;
    border: none;
    background: none;
    cursor: pointer;
}
.back__navigate p,
.back__navigate i{
    color: #d9d9d9;
    opacity: .6;
}
.back__navigate p{
    font-size: 1rem;
}
.back__navigate i{
    font-size: 1.3rem;
}
.right__parts .title__parts,
.right__parts .point__parts{
    margin-bottom: 1rem;
}
.right__parts .button__navigate{
    margin-bottom: 1.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    gap: 1rem;
}
.button__navigate button{
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: none;
    border: 1px solid #6a6a6a;
    color: #6a6a6a;
    padding: 1rem;
    border-radius: .5rem;
    cursor: pointer;
    transition: all .5s;
}
.button__navigate button:nth-child(even){
    background: #FCCC24;
    color: #1A1A1A;
    border: none;
}
.button__navigate button i{
    font-size: 1.3rem;
    margin-left: .5rem;
}
.button__navigate button:hover{
    transform: scale(0.97);
}
.button__navigate button:active{
    transform: scale(0.92);
}
.button__navigate button:active:nth-child(even){
    background: #deb319;
}

.point__parts li{
    list-style: none;
    margin-top: 2rem;
}
.point__parts .image__point{
    width: 100%;
    height: 250px;
    border-radius: .5rem;
    overflow: hidden;
    margin-top: 1.5rem;
}
.point__parts .image__point:hover img{
    transform: scale(1.3);
}
.image__point img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .5s;
}
.title__parts h1,
.point__parts li h1{
    color: #d9d9d9;
    font-size: 1.5rem;
    margin: 0;
}
.title__parts p,
.point__parts p,
.footer__parts p{
    color: #d9d9d9;
    opacity: .6;
    font-size: 1rem;
    margin: 1rem 0 0 0;
    font-weight: 300;
}   


.left__parts{
    height: fit-content;
    position: sticky;
    top: 5rem;
}
.left__parts .button__parts{
    display: grid;
    gap: .5rem;
    width: calc(100% - 1.5rem);
    margin-top: 1.5rem;
}
.button__parts button{
    font-family: inherit;
    text-align: left;
    font-size: 1rem;
    padding: 1.3rem 2rem;
    background: none;
    border: none;
    border-radius: .5rem;
    cursor: pointer;
    color: #6a6a6a;
    opacity: .6;
    transition: all .5s;
}
.button__parts button.active{
    color: #dcb010;
    background-color: #1A1A1A;
    opacity: 1;
    font-weight: 600;
}
.button__parts button:hover{
    opacity: 1;
    background-color: #1a1a1a6f;
    padding: 1.3rem 2rem 1.3rem 2.5rem;
}
.button__parts button:active{
    transform: scale(0.5);
}

.breadcrumbs{
    height: 400px;
    position: relative;
    display: grid;
    place-items: end;
    border-radius: .5rem;
    overflow: hidden;
}
.breadcrumbs img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}
.breadcrumbs .breadcrumbs_text{
    width: 100%;
    padding: 2rem;
    position: relative;
    z-index: 2;
}
.breadcrumbs_text::after{
    content: "";
    width: 100%;
    height: 300px;
    background: linear-gradient(to top, #111111e3, #11111105);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}

.breadcrumbs_text h1{
    margin: 0;
    color: #fff;
}
.breadcrumbs_text p{
    margin-top: .5rem;
    color: #fff;
    opacity: .8;
}

.left__parts .wizard__parts{
    opacity: .2;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
}
.wizard__parts .wizard__image{
    width: 64px;
    height: 62px;
}
.wizard__parts .wizard__image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.wizard__parts .wizard__text p{
    font-size: 1rem;
    color: #d9d9d9;
}
.wizard__parts .wizard__text h1{
    font-weight: 500;
    font-size: 1.3rem;
    margin: 0;
    color: #d9d9d9;
}
.wizard__parts.current{
    opacity: 1;
    background-color: #1A1A1A;
    border-radius: .5rem;
}
.wizard__parts.current .wizard__text h1{
    color: #FCCC24;
}

@media (max-width: 1000px){
    .container.first{
        /* padding: 100px 50px 0 50px; */
        padding: 0;
    }
    .breadcrumbs{
        border-radius: 0;
    }
    .container{
        padding: 0 20px;
    }
    .container.separating,
    .right__parts .button__navigate,
    .container.separating.plan{
        grid-template-columns: minmax(0,1fr);
    }
    .left__parts{
        position: relative;
        top: 0;
    }
    .left__parts .button__parts{
        width: 100%;
        margin: 1.5rem 0;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid #373737;
    }
    .right__parts{
        padding: 0;
        border: none;
    }
    .right__parts .point__parts{
        padding: 0;
    }
    .button__parts button{
        text-align: center;
    }
}