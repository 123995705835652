.DCPkontainer{
    max-width: 1100px;
    /* max-width: 1440px; */
    margin: auto;
    padding: 150px 0 0 0;
    box-sizing:border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.DCPdetailchefkontainer{
    width: auto;
    display: flex;
    height: min-content;
}

.DCPpopupwrapper{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
}

.DCPqrouter{
    width: 100%;
    height: 100%;
    background-color: #111111;
    opacity: 0.8;
}

.DCPpopupkonten{
    width: 50vw;
    height: max-content;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #1F1F1F;
    transform: translate(-50%, -50%);
    border-radius: 15px;
}

.DCPleft{
    z-index: 11;
    position: absolute;
    top: 50%;
    height: 32px;
    cursor: pointer;
    left: 15%;
    transform: translateY(-50%);
}

.DCPright{
    z-index: 11;
    position: absolute;
    top: 50%;
    height: 32px;
    cursor: pointer;
    right: 15%;
    transform: translateY(-50%);
}

.DCPclosepopup{
    z-index: 11;
    position: absolute;
    top: 82%;
    height: 32px;
    cursor: pointer;
    left: 50%;
    transform: translate(-50%, -50%);
}

.DCPpopupkiri{
    min-height: 50vh;
    border-radius: 15px 0px 0px 15px;
    flex: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.DCPpopupkanan{
    flex: 50%;
}

.DCPpopupkanankonten{
    padding: 25px;
}

.DCtrans-appear {
    opacity: 0;
    transform: translateX(50%);
}
.DCtrans-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1000ms;
}
.DCtrans-enter {
    opacity: 0;
}
.DCtrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.DCtrans-exit {
    opacity: 1; 
    transform: translateX(0);
}
.DCtrans-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 1000ms;
}

/* .DCPkontainertengah{
    flex: 33.3%;
    margin-left: 10px;
    margin-right: 10px;
} */

/* .DCPkontenkanandetail{
    flex: 33.3%;
    margin-left: 20px;
    margin-top: 74px;
} */
.d-none{
    display:none !important;
}
.DCPlistmenucard{
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-wrap: wrap;
}

.DCPfotochef{
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    border-radius: 15px;
    margin-left: 50px;
    width: 500px;
    height: 450px;
    flex-shrink: 0;
}
.DCPdeskripsichef .grey-text *{
    color: #696969 !important;
}
.DCPnamarate{
    display: flex;
    align-items: baseline;
}

.DCPnama{
    color: white;
    font-weight: 600;
    font-size: 36px;
    margin: 0;
}

.DCPiconrate{
    height: 24px;
    margin-left: auto;
}

.DCPrate{
    color: white;
    font-size: 24px;
    margin-left: 10px;
}

.DCPdeskripsichef{
    color: #969696;
    font-size: 14px;
    text-align: justify;
    position: relative;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.DCPtypelistkontainer{
    display: flex;
    width: 100%;
}

.DCPtypelist{
    display: flex;
    flex-wrap:wrap;
    flex: 70%;
    color: #696969;
    font-size: 14px;
    font-weight: 500;
}

.DCPapa{
    flex: 30%;
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.DCPSocialMediaContainer{
    display: flex;
    margin-top: 10px;
}

.DCPicon{
    height: 32px;
    margin-right: 16px;
}

.line{
    height: 1px;
    width: 100%;
    background-color: #6F6F6F;
    margin-top: 24px;
}

.mobileline{
    height: 1px;
    width: 100%;
    background-color: #6F6F6F;
    margin-top: 14px;
    margin-bottom: 14px;
}
.DCPkontainerdetail{
    width: 100%;
    margin: 0px 50px 0 50px;
}
.DCPeducation{
    font-size: 14px;
    color: white;
    font-weight: 600;
    position: relative;
    margin-top: 16px;
}

.DCPinstitusi{
    font-size: 14px;
    color: white;
    margin-bottom: 0;
}

.DCPtahun{
    font-size: 14px;
    color: #6F6F6F;
    margin-top: 0;
}

.white{
    color: white;
}

.fontbold{
    font-weight: 600;
}

.DCPmygallery{
    margin-left: 12px;
    margin-right: 12px;
    justify-content: left;
}


.flex{
    display: flex;
}

.DCPmygallerykontainer{
    flex:16.6% 0;
    aspect-ratio: 1 / 1;
    position: relative;
    display: unset;
}

.DCPbeforeload:nth-child(n+7){
    display: none;
}

.DCPmygalleryimg{
    height: 90%;
    aspect-ratio: 1 / 1;
    border-radius: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    transition: all 0.3s;
}

.DCPmygallerykontainer:nth-child(n) .DCPmygalleryimg{
    margin-left: auto;
    margin-right: auto;
}

.DCPgradient{
    position: absolute;
    width: 100.8%;
    height: 70%;
    margin: 0px;
    left: -0.4%;
    padding: 0px;
    bottom: -0.5%;
    border-radius: 15px;
    transition: all 0.3s;
}

.DCPimgtext{
    position: absolute;
    text-align: left;
    left:10%;
    width: 90%;
    bottom: 10%;
    opacity: 0;
    transition: all 0.3s;
}

.DCPmygalleryimg:hover .DCPimgtext{
    opacity: 1;
}

.DCPmygalleryimg:hover .DCPgradient{
    background: linear-gradient(transparent,black);
}

.DCPmygalleryimg:hover{
    transform: scale(1.1);
}

.flexwrap{
    display: flex;
    flex-wrap: wrap;
}

.DCPpilihanmenu{
    font-size: 36px;
    /* margin-left: 20px; */
}

.DCPlistpilihanmenu{
    height: min-content;
    width: auto;
}

.DCPupcomingkontainerList{
    display: flex;
    overflow: scroll;
    width: 95%;
    margin-left: auto;
    padding-bottom: 10vh;
}

.backmobile{
    z-index:30;
    position: fixed;
    margin-top: 20px;
    margin-left: calc(20px + 4vw);
}

.DCPupcomingkontainerList::-webkit-scrollbar{
    width:0;
    height:0;
    scrollbar-width: none;
}

.DCPupcomingkontainerList> .kontainerCard{
    flex: 33.3% 0;
    margin-top: 36px;
}

.DCPupcomingkontainerList>.kontainerCard> .card{
    width: 90%;
    margin:0;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #1F1F1F;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
}

.DCPupcomingkontainerList>.kontainerCard> .card>.gambarEvent{
    height: 160px;
    /* width: -webkit-fill-available; */
    width: 100%;
    object-fit:cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 15px 15px 0px 0px;
    transition: background-size 0.5s;
    margin: 0;
}

.DCPupcomingkontainerList>.kontainerCard> .card>.gambarEvent:hover{
    background-size: 120% 120%;
}

.DCPupcomingkontainerList>.kontainerCard> .card>.kontainerDetail{
    flex: 50%;
    padding: 0;
    margin-left: 20px;
    width: -webkit-fill-available;
    width: -moz-available;
    margin-top: 10px;
    margin-right: 20px;
    overflow-wrap: break-word;
}

.DCPupcomingkontainerList>.kontainerCard> .card>.kontainerDetail>.judulEvent{
    color: white;
    font-size: 36px;
    margin: 0;
    width: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.DCPupcomingkontainerList>.kontainerCard> .card>.kontainerDetail>.foodstyle{
    font-size: 14px;
    width: inherit;
    color: #AAAAAA;
    margin: 4px 0px 0px 0px;
}

.DCPupcomingkontainerList>.kontainerCard> .card>.kontainerDetail>.seatleft{
    font-size: 14px;
    color: white;
    font-weight: 600;
    margin: 32px 0px 0px 0px;
}

.DCPupcomingkontainerList>.kontainerCard> .card>.kontainerDetail>.seatleftred{
    font-size: 14px;
    color: #FF7D7D;
    font-weight: 600;
    margin: 32px 0px 0px 0px;
}


.DCPupcomingkontainerList>.kontainerCard> .card>.kontainerDetail>.totalseat{
    font-size: 14px;
    color: white;
    margin: 4px 0px 0px 0px;
}


.DCPupcomingkontainerList>.kontainerCard> .card>.kontainerDetail>.kontainerjam{
    font-size: 14px;
    color: white;
    margin-top: 32px;
    display: flex;
}

.DCPupcomingkontainerList>.kontainerCard> .card>.kontainerDetail>.kontaineralamat{
    font-size: 14px;
    color: white;
    margin-top: 8px;
    display: flex;
}

.DCPupcomingkontainerList>.kontainerCard> .card>.kontainerDetail>.kontainerjam>.iconcard{
    height: 16px;
    margin-right: 15px;
}

.DCPupcomingkontainerList>.kontainerCard> .card>.kontainerDetail>.kontaineralamat>.iconcard{
    height: 16px;
    margin-right: 15px;
}

.DCPshowmore1{
    position: absolute;
    color: #FCCC24!important;
    margin: 0;
    bottom: 0;
    background-color: rgba(17, 17, 17, 1);
    right: 0;
    padding-left: 10px;
}

.DCPshowmore2{
    color: #FCCC24!important;
    margin: 0;
    text-align: right;
}

@media (min-width: 1000px) {
    .mobileline{
        display: none;
    }

    .backmobile{
        display: none;
    }

    .DCPshowmore1{
        display: none;
    }

    .DCPshowmore2{
        display: none;
    }

    .mobileonly{
        display: none;
    }
}

@media (max-width: 1000px) {
    .DCPkontainerdetail{
        width:100%;
    }
    .DCPdetailchefkontainer{
        flex-wrap: wrap;
        padding: 0;
    }

    .DCPlistmenucard{
        margin-left: calc(20px + 4vw);
        margin-right: calc(20px + 4vw);
        flex-wrap: wrap;
    }

    .DCPpopupkiri{
        min-height: 30vh;
        border-radius: 15px 15px 0px 0px;
    }

    .DCPpopupkanan{
        min-height: 20vh;
    }

    .DCPmygallery{
        margin-left: calc(16px + 4vw);
        margin-right: calc(16px + 4vw);
    }

    .DCPpopupkonten{
        flex-direction: column;
        width: 85vw;
    }

    .DCPleft{
        top:41%;
    }

    .DCPright{
        top:41%;
    }

    /* .DCPkontenkanandetail{
        flex: 100%;
        margin-left: calc(20px + 4vw);
        margin-right: calc(20px + 4vw);
        margin-top: 0;
    } */

    .DCPmygallerykontainer{
        flex:33.3% 0;
    }

    /* .DCPkontainertengah{
        margin-top: 24px;
        flex: 100%;
        margin-left: calc(20px + 4vw);
        margin-right: calc(20px + 4vw);
    } */

    .DCPnama{
        font-size: 24px;
    }
    
    .DCPiconrate{
        height: 16px;
    }
    

    .desktoponly{
        display: none;
    }

    .line{
        margin-top: 14px;
        margin-bottom: 14px;
    }

    .DCPrate{
        font-size: 18px;
    }
/* 
    .DCPdeskripsichef{
        font-size: 12px;
        height: 60px;
        position: relative;
        overflow: hidden;
    } */

    .DCPapa{
        font-size: 12px;
        margin-right: 4px;
    }
/* 
    .DCPtypelist{
        font-size: 12px;
    } */

    .DCPeducation{
        font-size: 14px;
    }

    .DCPinstitusi{
        font-size: 12px;
    }

    .DCPtahun{
        font-size: 12px;
    }

    .DCPpilihanmenu{
        margin-left: calc(20px + 4vw);
        margin-right: calc(20px + 4vw);
    }

    .DCPclosepopup{
        top:22%;
        left:78%;
        height: 22px;
    }
    .DCPkontainerdetail{
        margin: 30px 50px 0 50px;
    }
}

@media (max-width:520px){
    .DCPkontainerdetail{
        margin: 20px 20px 0 20px;
    }
}