@keyframes confirmationIn{
    0%{
        margin-top: 100vh;
    }
    100%{
        margin-top: 5vh;
    }
}
.confirmationIn{
    animation: confirmationIn .8s cubic-bezier(.12,.51,.64,.91);
    /* animation: confirmationIn 2s cubic-bezier(.29,1.14,.55,-0.13); */
    animation-fill-mode: forwards;
}
.submitSuccess{
    position: fixed;
    max-width: 500px;
    width: 100%;
    height: fit-content;
    inset: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 1rem;
    box-sizing: border-box;
}
.submitSuccess h1{
    font-size: 2rem;
    color: #FCCC24;
    margin: 0;
}
.submitSuccess p{
    margin-top: .5rem;
    font-size: 1rem;
    color: white;
    opacity: .8;
}
.confirmation_wrapper{
    position: fixed;
    max-width: 700px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 100vh;
    z-index: 999;
    overflow-y: auto;
    padding: 0 1rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    pointer-events: none;
    -webkit-overflow-scrolling: touch;
}
.confirmation_wrapper::-webkit-scrollbar{
    display: none;
}
.confirmation_wrapper .confirmation{
    pointer-events: all;
    width: 100%;
    background: white;
    border-radius: .5rem;
    margin-bottom: 5rem;
    cursor: grab;
    cursor: -webkit-grab;
    transition: all .5s;
    box-shadow: 
        0.9px 3.3px 5.3px rgba(0, 0, 0, 0.027),
        3.1px 11.2px 17.9px rgba(0, 0, 0, 0.043),
        14px 50px 80px rgba(0, 0, 0, 0.06)
    ;
    -webkit-overflow-scrolling: touch;
}
.confirmation_wrapper .confirmation:active{
    cursor: grabbing;
    cursor: -webkit-grabbing;
}
.confirmation_wrapper .confirmation:hover{
    background: #fbfbfb;
}

/* Confirmation header part  */
.confirmation .confirmation_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #CCCCCC;
}
.confirmation .confirmation_header img{
    max-width: 28px;
    margin-right: .5rem;
}
.confirmation .confirmation_header h1{
    font-size: 1.3rem;
    color: #111;
    margin: 0;
}
.confirmation .confirmation_header i{
    font-size: 2rem;
    color: #969696;
    cursor: pointer;
}

/* Confirmation body part  */
/* .confirmation .confirmation_body{
    padding: 1rem;
} */
.confirmation_body .confirmation_info{
    padding: 1rem;
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    border-bottom: 1px solid #cccccc1c;
}
.confirmation_body .confirmation_info.single{
    grid-template-columns: minmax(0,1fr);
}
.confirmation_body .confirmation_info.single .text:nth-child(odd){
    margin-right: 0;
}
.confirmation_body .confirmation_info .text:nth-child(odd){
    margin-right: .5rem;
}
.confirmation_body .confirmation_info .text:nth-child(even){
    margin-left: .5rem;
}
.confirmation_info .text h1{
    font-size: 1.1rem;
    color: #111;
    margin: 0;
}
.confirmation_info .text p{
    font-size: 1rem;
    color: #969696;
    margin: 0 0 .3rem 0;
}

/* Confirmation footer part */
.confirmation .confirmation_footer{
    padding: 1rem;
}

.lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform:scale(0.6);
  }
  .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #dfdfdf;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }

.TBCForm{
    width: 100%;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
    outline: none;
    height: 45px;
    flex:80%;
    margin-top: 10px;
    padding-left: 15px;
}

.TBCForm:focus{
    border: 1px solid #FCCC24;
}

.PSScontainer{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20vh;
    padding-bottom: 10vh;
    font-family: Arial, Helvetica, sans-serif;
}

.PSStrans-appear {
    opacity: 0;
    transform: translateX(50%);
}
.PSStrans-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1000ms;
}
.PSStrans-enter {
    opacity: 0;
}
.PSStrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.PSStrans-exit {
    opacity: 1; 
    transform: translateX(0);
}
.PSStrans-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 1000ms;
}

.PSStitle{
    padding-left: 20px;
}

.PSSyellow{
    border: 1px solid #FCCC24!important;
}
.outline{
    outline: 2px solid #111111;
    outline-offset: -2px;
}
@keyframes informasiIn{
    from{
        top: 150%;
    }
    top{
        top: 50%;
    }
}
@keyframes informasiOut{
    from{
        top: 50%;
    }
    to{
        top: 150%;
    }
}
.informasiIn {
    animation: informasiIn .6s;
    animation-fill-mode: forwards;
}
.informasiOut {
    animation: informasiOut .6s;
    animation-fill-mode: forwards;
}
.informasiWrapper.cancellation{
    max-width:700px;
}
.informasiWrapper{
    max-width: 400px;
    width: 100%;
    height: fit-content;
    position: fixed;
    z-index: 1001;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    background-color: #181818;
}
.informasiWrapper *{
    margin: 0 !important;
    box-sizing: border-box;
}
.informasiWrapper .informasiHead *{
    color: #d9d9d9 !important;
}
.informasiWrapper .informasiHead{
    background-color: #1F1F1F;
    width: 100%;
    align-items: center;
    display: flex;
    gap: 0.5rem;
    padding: 1rem 1rem;
    border-radius: 1rem 1rem 0 0;
}
.informasiWrapper .informasiHead i{
    font-size: 1.5rem;
}
.informasiWrapper .informasiHead h1{
    font-size: 1rem;
}

.informasiWrapper .informasiBody *{
    color: #767676 !important;
}
.informasiWrapper.cancellation .informasiBody{
    /* min-height:230px; */
    padding: 1rem 1rem;
    max-height:230px;
    height:100%;
}
.informasiWrapper .informasiBody{
    min-height: 150px;
    max-height: 150px;
    overflow: scroll;
    height: 100%;
    padding: 0.5rem 1rem;
    background-color: #181818;
}
.informasiWrapper .informasiBody p{
    line-height: 1.2rem;
    font-size: 0.9rem;
}
.informasiWrapper .informasiBody p span{
    font-weight: bold;
}
.informasiWrapper.cancellation .informasiBody a img{
    margin-bottom: 1rem;
    width: 100%;
    height: auto;
}

.informasiWrapper .informasiFooter{
    border-top: 1px solid #1F1F1F;
    background-color: #181818;
    padding: 1rem 1rem;
    width: 100%;
    display: flex;
    justify-content: end;
    border-radius: 0 0 1rem 1rem;
}
.informasiWrapper .informasiFooter button{
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 0.5rem 2rem;
    background-color: #FCCC24;
    color: #181818;
    font-weight: bold;
    outline: none;
    border: none;
}
.backdrop{
    width: 100%;
    height: 100%;
    background-color: #151515;
    opacity: 80%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 900;
    cursor: pointer;
}
.infoPush{
    display: flex;
    align-items:center;
}
.infoPush .button{
    display: flex;
    align-items:center;
    justify-content:center;
    font-size: 0.8rem;
    font-weight: 600;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    border: 1px solid #6F6F6F;
    color: #6F6F6F;
    cursor: pointer;
}
.PSSred{
    background-color: #ffdfdf !important;
    border: 1px solid #ff5151!important;
}

.PSSmargin{
    margin-top: 68px;
}
.grey-text{
    color: #969696;
}

.PSStextareaEvent{
    resize: none;
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 25px;
    border-radius: 5px;
    margin-top: 18px;
    color: white;
    background: transparent;
}
.right__parts .PSScontainer{
    padding: 0;
    width: 100%;
}
.right__parts .PSSformkontainer{
    width: 100%;
}
.PSSformkontainer{
    width: 50%;
    background-color: white;
    padding: 35px;
    border-radius: 15px;    
    margin-bottom: 30px;
}

.PSSpertanyaanflexGap{
    width: 15px;
    height: 25px;
}

.PSSformgroupEvent {
    gap: 15px;
    /* -webkit-gap:15px; */
    /* margin-left: 15px; */
    margin-top: 20px;
    position: relative;
    /* cursor: pointer; */
}

.PSSpertanyaanflex{
    flex: 50%;
    display: flex;
    flex-direction: column;
}

.PSSpertanyaantext{
    color: #6F6F6F;
    margin-right: 15px;
}

.PSSpertanyaanEvent{
    padding: 15px;
    margin-top: 12px;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
    align-items: center;
    /* height: -webkit-fill-available; */
    width: -moz-available;
}

.questionWrapper{
    margin-top: 20px;
}

.inputWrapperEvent{
    display: flex;
    cursor: pointer;
    max-width: 100%;
    border: solid 1px #C4C4C4;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    align-items: center;
    margin-top: 10px;
}

.inputWrapperEventCheckBox{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    max-width: 100%;
    border: solid 1px #C4C4C4;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}


.PSSiconkiri{
    height: 16px;
    margin-right: 8px;
}

.PSSiconkanan{
    height: 16px;
    margin-left: auto;
    cursor: pointer;
}

.PSSbutton{
    margin-top: 10px;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 15px;
    padding: 20px;
    font-size: 21px;
    background-color: #FCCC24;
    cursor: pointer;
}

.PSSbutton:active{
    background-color: rgba(216, 175, 32, 1);
}


.PSSpopupkontainer{
    position: absolute;
    /* position: -webkit-absolute; */
    width: auto;
    height: fit-content;
    height: -webkit-fit-content;
    border:1px solid #f3f3f3;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin-top: 75px;
}
.PSSpopupkontainer3{
    right: 0;
    position: absolute;
    width: 50%;
    height: 100px !important;
    overflow-y: auto;
    height: fit-content;
    border:1px solid #f3f3f3;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin-top: 75px;
}
.PSSpopupkontainer3 p{
    padding:15px;
    margin: 0;
}
.PSSpopupkontainer3 p:hover{
    background-color: #f7f7f7;
}

.popupfont{
    font-family: Arial, Helvetica, sans-serif;
    font-size: calc(1vh + 0.6vw);
    margin-left:30px;
    cursor: pointer;
}

/* New Version  */
.dropdown_drop.range .react-datepicker{
    display: flex;
    flex-direction: row;
    position: relative;
}
.dropdown_drop.range .react-datepicker__day{
    position: relative;
    z-index: 2;
}
.dropdown_drop.range .react-datepicker__day--in-range.react-datepicker__day--range-start,
.dropdown_drop.range .react-datepicker__day--in-range.react-datepicker__day--range-end{
    position: relative;
    background-color: #FCCC24 !important;
    color: #947812 !important;
} 
.dropdown_drop.range .react-datepicker__day--in-range.react-datepicker__day--range-start{
    border-radius: .5rem 0 0 .5rem !important;
}
.dropdown_drop.range .react-datepicker__day--in-range.react-datepicker__day--range-end{
    border-radius: 0 .5rem .5rem 0 !important;
}
.dropdown_drop.range .react-datepicker__day--in-range.react-datepicker__day--range-start.react-datepicker__day--in-range.react-datepicker__day--range-end{
    border-radius: .5rem !important;
}

.dropdown_drop.range .react-datepicker__day--in-range,
.dropdown_drop.range .react-datepicker__day--in-range.react-datepicker__day--weekend{
    background-color: #fcecb5 !important;
    color: #c2a026 !important;
    border-radius: 0 !important;
}
.dropdown_drop.range .react-datepicker__day--in-range.react-datepicker__day--weekend:last-child{
    border-radius: 0 .5rem .5rem 0 !important;
}
.dropdown_drop.range .react-datepicker__day--in-range.react-datepicker__day--weekend:first-child{
    border-radius: .5rem 0 0 .5rem !important;
}
.dropdown_drop.range .react-datepicker__day--in-selecting-range.react-datepicker__day--today{
    font-weight: bold;
}
.dropdown_drop.range .react-datepicker__day--in-selecting-range,
.dropdown_drop.range .react-datepicker__day--in-selecting-range.react-datepicker__day--weekend{
    border-radius: 0 !important;
    background-color: #f0f0f0 !important;
    color: #111 !important;
    font-weight: inherit;
}
.dropdown_drop.range .react-datepicker__day--weekend{
    color: #f15c59 !important;
}
.dropdown_drop.range .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start{
    background-color: #FCCC24 !important;
    border-radius: .5rem !important;
    font-weight: bold;
}

.dropdown_drop .react-datepicker{
    margin: 0;
    font-family: inherit;
    font-size: 1rem;
}
.dropdown_drop .react-datepicker__month-container{
    width: 100%;
    float: none;
}
.dropdown_drop .react-datepicker__header {
    padding:0;
    border: none;
}
.dropdown_drop .react-datepicker__month{
    margin: 0;
}
.dropdown_drop .react-datepicker__current-month{
    font-size: 1rem;
    padding: 1rem;
    border-bottom: 1px solid #CCCCCC;
    color:#111;
}
.dropdown_drop .react-datepicker__day-name{
    font-weight: 600;
}
.dropdown_drop .react-datepicker__day-names,
.dropdown_drop .react-datepicker__week{
    display: flex;
    margin: 0;
    padding: .5rem;
}
.dropdown_drop .react-datepicker__day-names{
    background: #cccccc09;
}
.dropdown_drop .react-datepicker__week:nth-child(even){
    background: #cccccc09;
}

.dropdown_drop .react-datepicker__day-name, 
.dropdown_drop .react-datepicker__day, 
.dropdown_drop .react-datepicker__time-name{
    width: 100%;
    margin: 0 0.166rem;
}
.dropdown_drop.range .react-datepicker__day-name, 
.dropdown_drop.range .react-datepicker__day, 
.dropdown_drop.range .react-datepicker__time-name{
    padding: .5rem;
    margin: 0;
}

.calendarkontainer::-webkit-scrollbar{
    width:0;
    height:0;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #FCCC24!important;
    border-radius: .3rem!important;
    color: #111;
    font-weight: bold;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: white!important;
    color: #111;
}

/* Time Datepicker  */
.dropdown_drop .react-datepicker--time-only .react-datepicker__time-container{
    width: 100%;
}
.dropdown_drop .react-datepicker-time__header{
    text-align: left;
    padding: 1rem;
    border-bottom: 1px solid #CCCCCC;
}
.dropdown_drop .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
    padding: 1rem;
    height: auto;
    transition: all .5s;
}
.dropdown_drop .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:nth-child(even){
    background: #cccccc09;
}
.dropdown_drop .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
    color: #111;
    background: #cccccc1c;
}
.dropdown_drop .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
    width: 100%;
    text-align: left;
}
.dropdown_drop .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
    height: auto;
    max-height: 212px;
    min-height: 212px;
}

/* Datepicker icon  */
.dropdown_drop .react-datepicker__navigation{
    top: .75rem;
}

input[type="radio"],
input[type="checkbox"][child ="yes"]{
    appearance: none;
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border-radius: 100%;
    border: 2px solid #484848;
    margin: 0;
    position: relative;
}
input[type="radio"]::after,
input[type="checkbox"][child ="yes"]::after{
    position: absolute;
    content: "";
    inset: 50%;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #484848;
    transform: scale(0);
    transition: 200ms transform ease-in-out;
}
input[type="radio"]:checked::after,
input[type="checkbox"][child ="yes"]:checked::after{
    transform: scale(1) translate(-50%, -50%);
}

@keyframes stuffs{
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}
input[type="checkbox"][child="no"]{
    flex-wrap: wrap;
    appearance: none;
    margin: 0;
    -webkit-appearance: none;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    border: 2px solid #484848;
    border-radius: 5px;
    width: 24px;
    height: 24px;
}
input[type="checkbox"][child="no"]::before {
    content: "";
    width: 10px;
    height: 10px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    background-color: white;  
    transform-origin: center;
}
input[type="checkbox"][child="no"]:checked{
    background-color: #484848;
}
input[type="checkbox"][child="no"]:checked::before{
    animation: stuffs .2s ease-out;
    animation-fill-mode: forwards;
}
@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}
.mb-1{
    margin-bottom: 1rem !important;
}
.border-top{
    border-top: 1px solid #CCCCCC;
    max-height: 165px;
    min-height: 165px;
    overflow-y: scroll;
}
.btn_flex{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.btn_next,
.btn_back{
    padding: 1rem;
    font-size: 1.3rem;
    font-weight: 500;
    border: none;
    border-radius: .3rem;
    cursor: pointer;
    transition: all .5s;
    font-weight: 600;
}
.btn_next{
    width: 100%;
    background: #FCCC24;
    color: #111;
}
.btn_back{
    width: fit-content;
    background-color: white;
    border: 1px solid #111;
    color: #111;
    opacity: .4;
}
.shake{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}
.alert{
    /* width: 100%; */
    padding: .5rem;
    border-radius: .2rem;
}
.alert.alert-fail{
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: .3rem;
    background-color: #ffdfdf;
    border: 1px solid #ff5151;
}
.alert p,
.alert i{
    font-size: 1rem;
    color: #ff5151;
}
.inputFlex{
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: row;
}
.containerWrap{
    background-color: white;
    padding: 1.5rem;
    border-radius: .5rem;
}
.inputGroup{
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #11111111;
}
.inputGroup .orSeparator{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: #111;
    opacity: .3;
    margin: 1rem 0;
}
.inputGroup .orSeparator::before,
.inputGroup .orSeparator::after{
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background: #111;
}
.inputGroup .headerGroup{
    margin-bottom: 1rem;
}
.inputGroup .headerGroup h1{
    margin: 0;
    font-size: 1.3rem;
    font-weight: 600;
}
.inputGroup .headerGroup p{
    margin-top: .5rem;
    font-size: 1rem;
    color: #111;
    opacity: .6;
}
.inputText{
    width: 100%;
}
.inputText h4{
    margin: 0;
    font-size: 1rem;
    color: #111111;
    font-weight: 500;
    opacity: .8;
}
.inputText small{
    color: #111111;
    opacity: .6;
}
.dropdown_drop{
    z-index: 3;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #CCCCCC;
    border-radius: .3rem;
    box-shadow:
        0.9px 3.3px 5.3px rgba(0, 0, 0, 0.027),
        3.1px 11.2px 17.9px rgba(0, 0, 0, 0.043),
        14px 50px 80px rgba(0, 0, 0, 0.06)
    ;
}
.dropdown_drop .location_pick{
    padding: 0 1rem;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: all .5s;
}
.dropdown_drop .location_pick:nth-child(odd){
    background: #cccccc09;
}
.dropdown_drop .location_pick:hover{
    background: #cccccc1c;
}
.dropdown_drop .location_pick:hover p{
    transform: translateX(20px);
}
.dropdown_drop .location_pick p{
    align-content: center;
    max-height: 55px;
    min-height: 55px;
    font-size: 1rem;
    transition: all .5s;
}
.dropdown_drop .dropdown_search{
    padding: .5rem 1rem;
    margin: 1rem;
    display: flex;
    align-items: center;
    background: #cccccc1c;
    border: 1px solid #CCCCCC;
    border-radius: 50px;
}
.dropdown_drop .dropdown_search i{
    font-size: 1.5rem;
    color: #111;
    margin-right: 1rem;
}
.dropdown_drop .dropdown_search input{
    border: none;
    outline: none;
    width: 100%;
    background: transparent;
    font-size: 1rem;
    color: #111;
}
.dropdown_drop .dropdown_search input::placeholder{
    font-size: 1rem;
}

.inputText .dropdown{
    position: relative;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    border-radius: .2rem;
    border: 1px solid #CCCCCC;
    cursor: pointer;
    transition: all .5s;
    background: white;
    transition: all .5s;
    margin: 1rem 0 .5rem 0;
}
.inputText .dropdown.disabled{
    pointer-events: none;
    opacity: .5;
}
.inputText .dropdown.filled,
.inputText .dropdown:hover{
    background: #FBFBFB;
}
.inputText .dropdown img{
    width: 16px;
}
.inputText .dropdown .rotated{
    transform: rotate(-90deg);
}
.inputText .dropdown .text{
    display: flex;
    align-items: center;
}
.inputText .dropdown .text i{
    font-size: 1.5rem;
    color: #111;
    margin-right: 1rem;
}
.inputText .dropdown .text p{
    font-size: 1rem;
    font-weight: 400;
    color: #111111;
}

.inputText .input{
    padding: 1rem;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: .2rem;
    border: 1px solid #CCCCCC;
    cursor: text;
    font-size: 1rem;
    transition: all .5s;
    outline: none !important;
    margin: 1rem 0 .5rem 0;
}
.inputText textarea.input{
    resize: none;
    box-sizing: border-box;
    font-family: inherit;
}
.inputText textarea::placeholder{
    font-size: 1rem;
}
.inputText .input.filled,
.inputText .input:hover{
    background: #FBFBFB;
}
.inputText .input:focus-within{
    outline: 2px solid #111;
    outline-offset: -2px;
}
.inputText .input i{
    font-size: 1.5rem;
    color: #111111;
    margin-right: 1rem;
}
.inputText .input input::placeholder{
    font-size: 1rem;
}
.inputText .input input{
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 1rem;
    color: #111111;
    font-weight: 500;
}
.inputText .input.tags input{
    width: auto !important;
}
.input .tag_wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;
    position: relative;
}
.input .tag_wrapper .tag{
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: fit-content;
    gap: .3rem;
    padding: .3rem 1rem;
    background-color: white;
    border-radius: 50px;
    border: 1px solid #E3E3E3;
    cursor: pointer;
}
.tag_wrapper .tag p{
    font-size: 1rem;
    color: #111;
}

.checkGroup{
    cursor: pointer;
    padding: 1rem;
    border: 1px solid #CCCCCC;
    background-color: white;
    border-radius: .3rem;
    transition: all .5s;
    margin-bottom: 1rem;
}
.checkGroup:last-of-type{
    margin-bottom: 0;
}
.checkGroup:hover{
    background-color: #FBFBFB;
}
.checkGroup.checked{
    background-color: #FBFBFB;
}
.radioGroup,
.termGroup,
.checkboxGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.radioGroup,
.checkboxGroup{
    margin-top: 1rem;
    gap: .5rem;
}
.radioStyled,
.checkboxStyled{
    display: flex;
    align-items: center;
    padding: .3rem 1rem;
    border: 1px solid #E3E3E3;
    background-color: white;
    opacity: .4;
    border-radius: 50px;
    transition: all .5s;
    pointer-events: none;
}
.checkGroup.checked .radioStyled,
.checkGroup.checked .checkboxStyled{
    pointer-events: all;
    opacity: 1;
    background-color: #FBFBFB;
}
.checkGroup.checked .radioStyled.checked,
.checkGroup.checked .checkboxStyled.checked{
    background-color: white;
}

/* error contrast  */
.checkGroup.PSSred .checkboxStyled,
.checkGroup.PSSred .radioStyled{
    background-color: #ffdfdf;
    border-color: #ff5151;
}
.checkGroup.PSSred .radioStyled label,
.checkGroup.PSSred .checkboxStyled label,
.checkGroup.PSSred .termGroup label,
.input.PSSred i,
.input.PSSred input::placeholder,
.input.PSSred::placeholder,
.dropdown.PSSred .text p,
.dropdown.PSSred .text i{
    color: #ff5151;
}
.checkGroup.PSSred input[type="radio"],
.checkGroup.PSSred input[type="checkbox"][child="no"],
.checkGroup.PSSred input[type="checkbox"][child="yes"]{
    border-color: #ff5151;
}

.radioStyled label,
.termGroup label,
.checkboxStyled label{
    cursor: pointer;
    margin-left: .5rem;
    color: #484848;
}

@media (min-width: 1000px) {
    .backmobile{
        display: none;
    }
}

@media (max-width: 1000px) {
    .confirmation_wrapper{
        pointer-events: all;
    }
    /* Datepicker  */
    .dropdown_drop .react-datepicker__month-container:last-child .react-datepicker__current-month{
        border-top: 1px solid #CCCCCC;
    }
    .dropdown_drop.range .react-datepicker{
        flex-direction: column;
    }
    .dropdown_drop.range{
        margin-bottom: 2rem;
    }

    .PSScontainer{
        flex-direction: column;
        padding-top: 80px;
    }
    .PSSmainwrapper{
        flex-direction: column;
    }

    .PSSkontenkanan{
        margin-left: 20px;
        margin-top: 32px;
    }
    
    .SBPform{
        height: 38px;
        flex: 65%!important;
    }

    .PSSkananwrapper{
        position: relative;
        width: -webkit-fill-available;
        width: -moz-available;
        margin-left: 0;
        margin-right: 0;
    }

    .PSSkananwrapper2{
        position: relative;
        width: -webkit-fill-available;
        width: -moz-available;
        margin-left: 0;
        margin-right: 0;
    }
    
    .PSSpertanyaan{
        padding: 10px;
        margin-top: 12px;
        border-radius: 15px;
        border: 1px solid #C4C4C4;
        align-items: center;
    }
    
    .PSSiconkiri{
        margin-right: 8px;
    }
    
    .PSSiconkanan{
        margin-left: auto;
        cursor: pointer;
    }

    .PSSbutton{
        font-size: 16px;
    }

    .PSSmargin{
        margin-top: 48px;
    }

    .PSSimg{
        margin-right: 24px;
    }

    .PSSimg2{
        flex: 50%;
    }

    
}

@media (max-width:500px){
    .breadcrumbs_text h1{
        font-size: 1.2rem;
    }
    .breadcrumbs_text p{
        font-size: .9rem;
    }
    .inputFlex{
        flex-direction: column;
        gap: 1rem;
    }
}

@media (max-width:450px){
    .inputGroup{
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }
    .PSSformkontainer{
        padding: 10px 5px;
    }
    .informasiWrapper{
        width: calc(90% - 1rem);
    }
    .inputGroup .headerGroup h1,
    .wizard__parts.current .wizard__text h1,
    .wizard__parts .wizard__text h1,
    .title__parts h1, .point__parts li h1{
        font-size: 1.1rem;
    }
    .inputText .input i,
    .inputText .dropdown .text i{
        font-size: 1.2rem;
        margin-right: .5rem;
    }
    input[type="checkbox"]{
        width: 18px;
        height: 18px;
        border-radius: 3px;
    }
    .inputGroup .headerGroup p,
    .inputText h4,
    .inputText .input input,
    .inputText .input input::placeholder,
    .inputText textarea.input,
    .inputText textarea.input::placeholder,
    .radioStyled label, .termGroup label,
    .inputText .dropdown .text p,
    .wizard__parts .wizard__text p,
    .confirmation_info .text h1,
    .title__parts p, .point__parts p, .footer__parts p,
    .button__navigate button{
        font-size: .9rem;
    }
    .inputText .input,
    .inputText .dropdown{
        margin: .5rem 0;
    }
    .btn_next,
    .btn_back{
        font-size: .9rem;
    }
    .confirmation_info .text p{
        font-size: .7rem;
    }
    .wizard__parts .wizard__image{
        width: 48px;
        height: 46px;
    }
}

@media (max-width:400px){
    .PSSformgroup{
        flex-wrap: wrap;
    }
    .PSSpopupkontainer3{
        width: 100%;
        margin-top: 160px;
    }
    
    .PSSpopupkontainer{
        width: 100%;
    }
}