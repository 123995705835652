.App {
  text-align: center;
  background-color:#111111;
}

body{
  background-color:#111111;
}

.taplakLoading{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 128px;
}

.flex{
  display: flex;
}

.flexwrap{
  display: flex;
  flex-wrap: wrap;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.white{
  color: white;
}

.bold{
  font-weight: 600;
}

.normal{
  font-weight: normal;
}

.big{
  font-size: 48px!important;
  margin: 0;
}

.semibig{
  font-size: 36px!important;
  line-height: 36px;
  margin:0;
}

.medium{
  font-size: 24px!important;
  margin: 0;
}

.small{
  font-size: 18px!important;
  margin: 0;
}

.tiny{
  font-size: 14px!important;
  margin:0;
}

.verytiny{
  font-size: 12px!important;
  margin:0;
}

.red{
  color:#FF7D7D;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.black{
  color:black!important;
}

.grey{
  color:#6F6F6F!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1000px) {
  .big{
      font-size: 36px!important;
  }

  .semibig{
    font-size: 24px!important;
    line-height: 24px;
  }

  .medium{
      font-size: 18px!important;
  }

  .small{
      font-size: 14px!important;
  }

  .tiny{
    font-size: 10px!important;
  }

  .verytiny{
    font-size: 9px!important;
  }
}
