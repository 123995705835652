.CategoryCardContainer.category{
    margin: auto;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 25px;
    padding: 150px 50px 100px 50px;
    /* max-width: 1440px; */
    max-width: 1100px;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.KPtrans-appear {
    opacity: 0;
    transform: translateY(100%);
}
.KPtrans-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 1000ms;
}
.KPtrans-enter {
    opacity: 0;
}
.KPtrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.KPtrans-exit {
    opacity: 1; 
    transform: translateY(0);
}
.KPtrans-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 500ms, transform 1000ms;
}


@media (max-width:1132px){
    .CategoryCardContainer.category{
        grid-template-columns: auto auto;
    }
}

@media (max-width:1000px){
    .CategoryCardContainer.category{
        display: grid;
    }
}
/* @media (max-width: 1000px) {
    .CategoryCardContainer{
        display: flex;
        width: 100%;
        padding-top: 20vh;
        flex-direction: column;
    }
} */

@media (max-width:632px){
    .CategoryCardContainer.category{
        grid-template-columns: auto;
    }
}
@media (max-width: 520px){
    .CategoryCardContainer.category{
        padding: 20vh 20px 100px 20px;
    }
}