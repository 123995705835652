.PUSkontainer{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20vh;
    padding-bottom: 10vh;
    font-family: Arial, Helvetica, sans-serif;
}

.PUSkonten{
    margin-left: 20px;
    margin-right: 20px;
}

.PUSformwrapper{
    width: 70%;
    margin-top: 64px;
}

.PUSred{
    border: 1px solid #FA591D!important;
}

.PUSform{
    position: relative;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #C4C4C4;
    color: white;
    margin-top: 20px;
    outline: none;
    width: -webkit-fill-available;
    background-color: transparent;
    width: -moz-available;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
}

.PUSform:focus{
    border: 1px solid #FCCC24;
}

.PUSform2:focus{
    border: 1px solid #FCCC24;
}

.PUSval2{
    margin-top: 12px;
    text-align: right;
    margin-right: 20px;
}

.PUSval{
    margin-top: 12px;
    text-align: right;
}


.PUSform2{
    position: relative;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #C4C4C4;
    color: white;
    margin-right: 20px;
    margin-top: 20px;
    outline: none;
    width: -webkit-fill-available;
    background-color: transparent;
    width: -moz-available;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
}

.PUSform:disabled{
    border: 1px solid #6F6F6F;
    color: #6F6F6F;
}

.PUSmargin{
    margin-top: 36px;
}

.PUSbutton{
    border: none;
    outline: none;
    background-color: #FCCC24;
    padding: 15px 150px 15px 150px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    margin-left: auto;
}

@media (max-width: 1000px) {
    .PUSkontainer{
        padding-top: 150px;
    }

    .PUSval2{
        margin-right: 0px;
    }

    .PUSflex{
        flex-direction: column;
    }

    .PUSform2{
        margin-right: 0;
    }

    .PUSformwrapper{
        width: inherit;
        margin-top: 64px;
    }

    .PUSmargin2{
        margin-top: 36px;
    }

    .PUSbutton{
        padding: 15px 15px;
        width: 100%;
        display: block;
    }
}