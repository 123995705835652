.section_title.center{
    text-align: center;
}

.section_title h1{
    font-size: 1.5rem;
    margin: 0;
    color: white;
}
.section_title button{
    background: none;
    border:none;
    outline:none;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    margin: 1rem auto 0 auto;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.section_title button i{
    font-size: 1.5rem;
}

.section_title p{
    font-size: 1rem;
    margin: 0;
    color: #6A6A6A;
    line-height: 28px;
    margin-top: .5rem;
}

.line-separator.portfolio{
    margin: 1rem 0;
    padding: 0;
}

.section_portfolio{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.section_portfolio .portfolio{
    border-radius: .5rem;
    overflow: hidden;
    width: 100%;
}
.section_portfolio .portfolio .image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.lazy-load-image-background.blur.lazy-load-image-loaded{
    width: 100%;
    height: 100%;
}
.TaplakGalleries__body.portfolio{
    margin-top: 0;
}
.back__navigate{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding: 2rem;
    border: none;
    background: none;
    cursor: pointer;
}
.back__navigate p,
.back__navigate i{
    color: #d9d9d9;
    opacity: .6;
}
.back__navigate p{
    font-size: 1rem;
}
.back__navigate i{
    font-size: 1.3rem;
}

@media (max-width:1000px){
    .container.first.portfolio{
        padding: 100px 20px 20px 20px;
    }
}

@media (max-width:650px){
    .TaplakGalleries__body{
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    }
}

@media (max-width:450px){
    .section_title h1{
        font-size: 1.1rem;
    }
    .section_title p{
        font-size: 0.9rem;
    }
    .TaplakGalleries__body{
        grid-template-columns: minmax(0,1fr);
    }
}