.LoginKontainer{
    position: fixed;
    top: 0;
    text-align: center;
    left: 0;
    display: flex;
    background-color: black;
    opacity: 0.6;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    z-index: 100;
}

/* .LoginKonten{
    position: fixed;
    max-width:800px;
    width: 100%;
    height: 350px;
    margin:auto;
    z-index: 120;
    background-color: white;
    top: 50%;
    border-radius: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    overflow-y:auto;
} */

.LGbuttonok.FPOTP{
    background-color: #FCCC24;
    border: none;
    padding: 15px;
    width: 100%;
    margin-top: 24px;
    cursor: pointer;
    display: block;
    border-radius: 15px;
}
.LGbuttonok.FPOTP.disabled{
    cursor: default;
    filter: grayscale(1);
}

.LGbuttonok2{
    background-color: transparent;
    border: 2px solid #FCCC24;
    padding: 15px;
    width: 60%;
    margin-top: 8px;
    cursor: pointer;
    display: block;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
}
/* 
.LoginKonten::-webkit-scrollbar{
    width:0;
    height:0;
} */

.LGwelcome{
    font-weight: 700;
    text-align: center;
    margin-bottom: 36px;
}

.LGkiri{
    flex: 49.9%;
}

.LGkanan{
    flex: 49.9%;
    padding-left: 48px;
}

.LGline{
    flex: 0.2%;
    background-color: #6F6F6F;
    min-height: 1px;
}

.LGunderline{
    border-bottom: 2px solid #FCCC24;
    padding-bottom: 8px;
}

.LGerror{
    border: 1px solid #FA591D!important;
}

.LGyellow{
    border: 1px solid #FCCC24!important;
}

.LGform{
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 15px;
    border-radius: 15px;
    outline: none;
    margin-bottom: 8px;
    border: 1px solid #6F6F6F;
}

.LGform:focus{
    border: 1px solid #FCCC24;
}

.LGcheckbox{
    margin: 0;
    margin-right: 8px;
}

.LGprefix{
    height: auto;
    border: 1px solid #6F6F6F;
    border-radius: 15px;
    margin-bottom: 8px;
    margin-right: 8px;
    flex:30%;
    background-color: white;
    color: black;
    position: relative;
    cursor: pointer;
}

.LGbirthdate{
    border: 1px solid #6F6F6F;
    border-radius: 15px;
    padding: 11px 15px 11px 15px;
    margin-bottom: 8px;
    background-color: white;
    color: #6F6F6F;
    position: relative;
    cursor: pointer;
    align-items: center;
}

.LGicon{
    height: 16px;
}

.LGpopup{
    position:absolute;
    margin-top: 20px;
    background-color: white;
    color: #6F6F6F;
    border-radius: 15px;
    width: -webkit-fill-available;
    width: -moz-available;
    min-width: max-content;
    left: 0;
    padding: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 10;
}

.LGpopup1{
    position:absolute;
    background-color: white;
    border-radius: 15px;
    margin-bottom: 50px;
    width: 80%;
    min-width: max-content;
    bottom: 0;
    left: 0;
    padding: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 10;
}

.LGmargin{
    margin-top: 16px;
}

.LGmargin2{
    margin-top: 48px;
}

.OTPInputWrap{
    margin-top: 20px;
    margin-right: 0px;
}

.LGformwrap{
    margin-top: 20px;
    margin-right: 48px;
}

.LGforget{
    color: #6F6F6F;
    text-align: right;
    cursor: pointer;
}

.LGbutton1{
    width: 100%;
    margin-top: 16px;
    padding: 15px;
    border: none;
    border-radius: 15px;
    background-color: #FCCC24;
    cursor: pointer;
}

.ButtonChef.base{
    display:flex;
    align-items:center;
    gap: 10px;
    margin-top: 16px;
    padding: 15px;
    border: none;
    border-radius: 15px;
    background-color: #FCCC24;
    cursor: pointer;
}
.ButtonChef.outline{
    display:flex;
    align-items:center;
    gap: 10px;
    margin-top: 16px;
    padding: 15px;
    border: none;
    border-radius: 15px;
    color: #FCCC24;
    border: 2px solid #FCCC24;
    cursor: pointer;
}


.LGgoogle{
    padding: 15px;
    background-color: #FFC1BC;
    border-radius:15px;
    margin-top: 25px;
    align-items: center;
    cursor: pointer;
}

.ChefButton.base{
    padding: 15px;
    background-color: #FCCC24;
    border-radius:15px;
    margin-top: 25px;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
}
.ChefButton.outline{
    padding: 15px;
    border: 2px solid #343434;
    color: #343434;
    border-radius:15px;
    margin-top: 25px;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
}
.ChefButton .iconChef{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.LGFacebook{
    padding: 15px;
    background-color: #99B9FF;
    border-radius:15px;
    margin-top: 8px;
    align-items: center;
    cursor: pointer;
}
.orLogin{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    color: #e3e3e3;
    margin: 25px 0;
}
.orLogin::before,
.orLogin::after{
    content: "";
    width: 2px;
    height: 100%;
    background-color: #e3e3e3;
}

div.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select{
    margin-top: 20px!important;
    margin-bottom: 20px!important;
}


.react-datepicker__month-container{
    width: 100%;
}

.react-datepicker{
    width: 100%;
    border: none!important;
    font-size:0.8rem;
    margin-bottom: 15px;
    margin-top: 20px;
}

.react-datepicker__header {
    border:none!important;
    background-color: white!important;
}

.react-datepicker__current-month{
    color:grey
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: rgba(255, 0, 0, 0.5);
    color: white;
}

.calendarkontainer::-webkit-scrollbar{
    width:0;
    height:0;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #FCCC24!important;
    border-radius: 50%!important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #FCCC24!important;
    border-radius: 50%!important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: white!important;
    color: black;
}

.LGnotifreg.FPOTP{
    opacity: 50%;
    font-size:20px; 
    text-align:center;
    width:100%;
    margin: 15px auto;
}
.LGnotifreg2.FPOTP{
    margin-top: 15px;
    margin-bottom: 15px;
    font-size:20px; 
    text-align:center;
    width:100%;
    margin-left:auto;
    margin-right:auto
}

.LoginKonten.FPOTP .LGafterreg{
    max-width: 500px;
    margin: 0 auto;
}

.LGemail{
    margin-top: 24px;
    margin-bottom: 24px;
    height: 57px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1000px) {
    .orLogin{
        flex-direction: row;
    }
    .orLogin::before,
    .orLogin::after{
        width: 100%;
        height: 2px;
    }
    /* .LoginKonten{
        max-width: 600px;
    } */

    .LGform[placeholder] {
        overflow: hidden;  
        text-overflow:ellipsis;
        white-space: nowrap;
    }

    .LGkontenwrapper{
        flex-direction: column;
    }

    .LGkiri{
        flex: 100%;
    }

    .LGline{
        flex:100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .LGkanan{
        flex: 100%;
        padding-left: 0;
    }

    .LGformwrap{
        margin-top: 20px;
        margin-right: 0px;
    }
    .OTPInputWrap{
        margin-top: 20px;
        margin-right: 0px;
    }

    .LGor{
        text-align: center;
    }

    .LGnotifreg{
        font-size: 15px;
    }
    .LGnotifreg2{
        font-size: 15px;
    }
    .ChefButton.base,
    .ChefButton.outline{
        font-size: 15px;
    }
}

@media (max-width: 735px){
    /* .LoginKonten{
        max-width: 450px;
    } */
}
@media (max-width: 570px){
    /* .LoginKonten{
        max-width: 350px;
        padding: 30px;
    } */
}
@media (max-width: 490px){
    /* .LoginKonten{
        width: 70%;
        padding: 25px;
    } */
}