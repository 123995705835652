.EPatas{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 150px 0;
    font-family: Arial, Helvetica, sans-serif;
}
.EPtrans-appear {
    opacity: 0;
    transform: translateY(100%);
}
.EPtrans-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 1000ms;
}
.EPtrans-enter {
    opacity: 0;
}
.EPtrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}

.EPtrans-exit {
    opacity: 1; 
    transform: translateX(0);
}
.EPtrans-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 1000ms;
}

.EPtittle{
    margin-left: 20px;
}

.EPupcomingevent{
    margin-left: 4px;
    margin-right: 4px;
}

.EPatas> .EPupcomingevent> .kontainerCard{
    flex: 33.3% 0;
    margin-top: 36px;
}

.EPatas>.EPupcomingevent>.kontainerCard> .card{
    width: 90%;
    margin:0;
    margin-left: auto;
    margin-right: auto;
    height: 5431px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #1F1F1F;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
}

.EPatas>.EPupcomingevent>.kontainerCard> .card>.gambarEvent{
    height: 50%;
    /* width: -webkit-fill-available; */
    width: -moz-available;
    object-fit:cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 15px 15px 0px 0px;
    transition: background-size 0.5s;
    margin: 0;
}

.EPatas>.EPupcomingevent>.kontainerCard> .card>.gambarEvent:hover{
    background-size: 120% 120%;
}

.EPatas>.EPupcomingevent>.kontainerCard> .card>.kontainerDetail{
    flex: 50%;
    padding: 0;
    margin-left: 20px;
    width: -webkit-fill-available;
    width: -moz-available;
    margin-top: 10px;
    margin-right: 20px;
    overflow-wrap: break-word;
}

.EPatas>.EPupcomingevent>.kontainerCard> .card>.kontainerDetail>.judulEvent{
    color: white;
    font-size: 36px;
    margin: 0;
    width: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.EPatas>.EPupcomingevent>.kontainerCard> .card>.kontainerDetail>.foodstyle{
    font-size: 14px;
    width: inherit;
    color: #AAAAAA;
    margin: 4px 0px 0px 0px;
}

.EPatas>.EPupcomingevent>.kontainerCard> .card>.kontainerDetail>.seatleft{
    font-size: 14px;
    color: white;
    font-weight: 600;
    margin: 32px 0px 0px 0px;
}

.EPatas>.EPupcomingevent>.kontainerCard> .card>.kontainerDetail>.seatleftred{
    font-size: 14px;
    color: #FF7D7D;
    font-weight: 600;
    margin: 32px 0px 0px 0px;
}


.EPatas>.EPupcomingevent>.kontainerCard> .card>.kontainerDetail>.totalseat{
    font-size: 14px;
    color: white;
    margin: 4px 0px 0px 0px;
}


.EPatas>.EPupcomingevent>.kontainerCard> .card>.kontainerDetail>.kontainerjam{
    font-size: 14px;
    color: white;
    margin-top: 32px;
    display: flex;
}

.EPatas>.EPupcomingevent>.kontainerCard> .card>.kontainerDetail>.kontaineralamat{
    font-size: 14px;
    color: white;
    margin-top: 8px;
    display: flex;
}

.EPatas>.EPupcomingevent>.kontainerCard> .card>.kontainerDetail>.kontainerjam>.iconcard{
    height: 16px;
    margin-right: 15px;
}

.EPatas>.EPupcomingevent>.kontainerCard> .card>.kontainerDetail>.kontaineralamat>.iconcard{
    height: 16px;
    margin-right: 15px;
}



















.EPatas> .kontainerCard{
    width: -webkit-fill-available;
    width: -moz-available;
    margin-top: 36px;
    margin-bottom: 64px;
}

.EPatas>.kontainerCard> .card{
    display: flex;
    width: -webkit-fill-available;
    width: -moz-available;
    margin-left: 20px;
    margin-right: 20px;
}

.EPatas>.kontainerCard> .card> .gambarEvent{
    height: 100%;
    flex: 50%;
    object-fit:cover;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 15px 0px 0px 15px;
    transition: all 0.5s;
    margin-right: 20px;
}

.EPatas>.kontainerCard> .card>.gambarEvent:hover{
    background-size: 150% 150%;
}

.EPatas>.kontainerCard> .card>.kontainerDetail{
    height: 100%;
    flex: 50%;
    padding: 0;
    margin-left: 20px;
    width: -webkit-fill-available;
    width: -moz-available;
    margin-top: 40px;
    margin-right: 20px;
    overflow-wrap: break-word;
}

.EPatas>.kontainerCard> .card>.kontainerDetail>.judulEvent{
    color: white;
    font-size: 48px;
    margin: 0;
}

.EPatas>.kontainerCard> .card>.kontainerDetail>.foodstyle{
    font-size: 18px;
    width: inherit;
    color: #AAAAAA;
    margin: 8px 0px 0px 0px;
}

.EPatas>.kontainerCard> .card>.kontainerDetail>.seatleft{
    font-size: 18px;
    color: white;
    font-weight: 600;
    margin: 52px 0px 0px 0px;
}

.EPatas>.kontainerCard> .card>.kontainerDetail>.seatleftred{
    font-size: 18px;
    color: #FF7D7D;
    font-weight: 600;
    margin: 52px 0px 0px 0px;
}


.EPatas>.kontainerCard> .card>.kontainerDetail>.totalseat{
    font-size: 18px;
    color: white;
    margin: 8px 0px 0px 0px;
}


.EPatas>.kontainerCard> .card>.kontainerDetail>.kontainerjam{
    font-size: 18px;
    color: white;
    margin-top: 52px;
    display: flex;
}

.EPatas>.kontainerCard> .card>.kontainerDetail>.kontaineralamat{
    font-size: 18px;
    color: white;
    margin-top: 8px;
    display: flex;
}

.EPatas>.kontainerCard> .card>.kontainerDetail>.kontainerjam>.iconcard{
    height: 20px;
    margin-right: 15px;
}

.EPatas>.kontainerCard> .card>.kontainerDetail>.kontaineralamat>.iconcard{
    height: 20px;
    margin-right: 15px;
}

@media (max-width: 1000px) {

    .EPatas{
        padding-top: 150px;
    }


    .EPatas> .EPupcomingevent> .EPdeepupcomingevent>.kontainerCard{
        flex: 50% 0;
        margin-top: 36px;
    }
    
    .EPatas>.EPupcomingevent>.EPdeepupcomingevent>.kontainerCard> .card{
        width: 92%;
        margin:0;
        margin-left: auto;
        margin-right: auto;
        height: 340px;
        font-family: Arial, Helvetica, sans-serif;
        background-color: #1F1F1F;
        border-radius: 15px;
        overflow:hidden;
        cursor: pointer;
        display: flex;
        flex-direction: column;
    }
    
    .EPatas>.EPupcomingevent>.EPdeepupcomingevent>.kontainerCard> .card>.gambarEvent{
        flex: 35%;
        width: -webkit-fill-available;
        width: -moz-available;
        object-fit:cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 15px 15px 0px 0px;
        transition: background-size 0.5s;
        margin: 0;
    }
    
    .EPatas>.EPupcomingevent>.EPdeepupcomingevent>.kontainerCard> .card>.kontainerDetail{
        flex: 65%;
        padding: 0;
        margin-left: 20px;
        width: -webkit-fill-available;
        width: -moz-available;
        margin-top: 10px;
        margin-right: 20px;
        overflow-wrap: break-word;
    }
    
    .EPatas>.EPupcomingevent>.EPdeepupcomingevent>.kontainerCard> .card>.kontainerDetail>.judulEvent{
        color: white;
        font-size: 14px;
        margin: 0;
    }
    
    .EPatas>.EPupcomingevent>.EPdeepupcomingevent>.kontainerCard> .card>.kontainerDetail>.foodstyle{
        font-size: 2.2vw;
        width: inherit;
        color: #AAAAAA;
        margin: 2px 0px 0px 0px;
    }
    
    .EPatas>.EPupcomingevent>.EPdeepupcomingevent>.kontainerCard> .card>.kontainerDetail>.seatleft{
        font-size: 10px;
        color: white;
        font-weight: 600;
        margin: 24px 0px 0px 0px;
    }
    
    .EPatas>.EPupcomingevent>.EPdeepupcomingevent>.kontainerCard> .card>.kontainerDetail>.seatleftred{
        font-size: 10px;
        color: #FF7D7D;
        font-weight: 600;
        margin: 24px 0px 0px 0px;
    }
    
    
    .EPatas>.EPupcomingevent>.EPdeepupcomingevent>.kontainerCard> .card>.kontainerDetail>.totalseat{
        font-size: 10px;
        color: white;
        margin: 2px 0px 0px 0px;
    }
    
    
    .EPatas>.EPupcomingevent>.EPdeepupcomingevent>.kontainerCard> .card>.kontainerDetail>.kontainerjam{
        font-size: 10px;
        color: white;
        margin-top: 24px;
        display: flex;
    }
    
    .EPatas>.EPupcomingevent>.EPdeepupcomingevent>.kontainerCard> .card>.kontainerDetail>.kontaineralamat{
        font-size: 10px;
        color: white;
        margin-top: 4px;
        display: flex;
    }
    
    .EPatas>.EPupcomingevent>.EPdeepupcomingevent>.kontainerCard> .card>.kontainerDetail>.kontainerjam>.iconcard{
        height: 12px;
        margin-right: 15px;
    }

    
    .EPatas>.EPupcomingevent>.EPdeepupcomingevent>.kontainerCard> .card>.kontainerDetail>.kontaineralamat>.iconcard{
        height: 12px;
        margin-right: 15px;
    }

    .EPupcomingevent{
        margin-left: 10px;
        margin-right: 10px;
    }











    .EPatas>.kontainerCard> .card{
        display: flex;
        height: 521px;
        width: -webkit-fill-available;
        width: -moz-available;
        margin-left: 20px;
        margin-right: 20px;
        flex-direction: column;
    }

    .EPatas>.kontainerCard> .card> .gambarEvent{
        height: 100%;
        flex: 50%;
        object-fit:cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 15px 15px 0px 0px;
        transition: background-size 0.5s;
        margin-right: 0;
    }

    .EPatas>.kontainerCard> .card>.kontainerDetail>.judulEvent{
        color: white;
        font-size: 24px;
        margin: 0;
    }

    .EPatas>.kontainerCard> .card>.kontainerDetail>.foodstyle{
        font-size: 12px;
        width: inherit;
        color: #AAAAAA;
        margin: 8px 0px 0px 0px;
    }

    .EPatas>.kontainerCard> .card>.kontainerDetail{
        height: 100%;
        flex: 50%;
        padding: 0;
        margin-left: 20px;
        margin-top: 20px;
        margin-right: 20px;
    }

    .EPatas>.kontainerCard> .card>.kontainerDetail>.seatleft{
        font-size: 12px;
        color: white;
        font-weight: 600;
        margin: 32px 0px 0px 0px;
    }
    
    .EPatas>.kontainerCard> .card>.kontainerDetail>.seatleftred{
        font-size: 12px;
        color: #FF7D7D;
        font-weight: 600;
        margin: 32px 0px 0px 0px;
    }
    
    
    .EPatas>.kontainerCard> .card>.kontainerDetail>.totalseat{
        font-size: 12px;
        color: white;
        margin: 8px 0px 0px 0px;
    }
    
    
    .EPatas>.kontainerCard> .card>.kontainerDetail>.kontainerjam{
        font-size: 12px;
        color: white;
        margin-top: 32px;
        display: flex;
    }
    
    .EPatas>.kontainerCard> .card>.kontainerDetail>.kontaineralamat{
        font-size: 12px;
        color: white;
        margin-top: 8px;
        display: flex;
    }
    
    .EPatas>.kontainerCard> .card>.kontainerDetail>.kontainerjam>.iconcard{
        height: 14px;
        margin-right: 15px;
    }
    
    .EPatas>.kontainerCard> .card>.kontainerDetail>.kontaineralamat>.iconcard{
        height: 14px;
        margin-right: 15px;
    }
}