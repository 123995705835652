.PNPbg{
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0.1;
    z-index: 1;
}

.PNPkontainer{
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    padding-top: 20vh;
    padding-bottom: 10vh;
    font-family: Arial, Helvetica, sans-serif;
}

.PNPmargin{
    margin-top: 20px;
}

.PNP404{
    font-size: 128px;
    margin: 0;
    color: #FCCC24;
}

.PNPkonten{
    margin-left: 20px;
    margin-right: 20px;
}

@media (max-width: 1000px) {
    .PNP404{
        font-size: 81px;;
    }
}