
.DCPmenucard{
    display: flex;
    /* width: auto; */
    flex: 33%;
    height: 370px;
    border: 2px solid #292929;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
}
.DCPmenucard .DCPCarousel{
    align-items: center;
    display:flex;
    flex-shrink: 0;
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.DCPmenucard .DCPmenudetail{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #1F1F1F;
    width: 100%;
    height: 100%;
}
.DCPmenucard .DCPmenudetail .DCPmenuheader h1{
    font-size: 28px;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    margin-bottom: 10px;
}
.DCPmenucard .DCPmenudetail .DCPmenuheader .DCPcardfoodlist{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 15px;
}
.DCPmenucard .DCPmenudetail .DCPmenuheader .DCPcardfoodlist p{
    font-size: 18px;
    font-weight: 600;
    color: #969696;
}
.DCPmenucard .DCPmenudetail .DCPmenuheader .linefoodlist{
    display: block;
    width: 45%;
    height: 1px;
    background-color: #969696;
}
.DCPmenucard .DCPmenudetail .DCPcardkontainerbawah p{
    font-size: 16px;
    color: #969696;
}
.DCPmenucard .DCPmenudetail .DCPcardkontainerbawah h1{
    font-size: 18px;
    color: white;
}
.DCPmenucard .DCPmenudetail .DCPcardkontainertengah p{
    font-size: 16px;
    color: #969696;
}
.line-clamps-1{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.line-clamps-1-half{
    width: 60%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
@media (max-width:1365px){
    .DCPmenucard .DCPCarousel{
        width: 50%;
    }
}

@media (max-width: 1000px){
    .DCPmenucard{
        flex: 100%;
        height: 300px;
    }
    .DCPmenucard .DCPmenudetail .DCPmenuheader h1{
        font-size: 24px;
    }
    .DCPmenucard .DCPmenudetail .DCPcardkontainertengah p,
    .DCPmenucard .DCPmenudetail .DCPcardkontainerbawah p
    {
        font-size: 14px;
    }
    .DCPmenucard .DCPmenudetail .DCPcardkontainerbawah h1,
    .DCPmenucard .DCPmenudetail .DCPmenuheader .DCPcardfoodlist p
    {
        font-size: 16px;
    }
}

@media (max-width: 460px){
    .DCPmenucard .DCPmenudetail .DCPmenuheader h1{
        font-size: 22px;
    }
    .DCPmenucard .DCPmenudetail .DCPmenuheader .DCPcardfoodlist p{
        font-size: 14px;
    }
    .DCPmenucard{
        height: auto;
        flex-direction: column;
    }
    .DCPmenucard .DCPCarousel{
        height: 200px;
        width: 100%;
    }
     .DCPmenucard .DCPmenudetail{
        gap: 20px;
        padding: 15px;
    }
    .DCPmenucard .DCPmenudetail{
        height: auto;
    }
}

@media (max-width: 330px){
    .DCPmenucard .DCPCarousel{
        height: 150px;
    }
}
/* 
.DCPmenucard{
    flex: 50% 1;
    height: 290px;
}

.DCPkontenmenucard{
    background-color: #1F1F1F;
    border-radius: 15px;
    width: auto;
    height: inherit;
}

.DCPcaraousel{
    height: inherit;
    flex: 65%;
    border-radius: 15px 0px 0px 15px;
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.DCPmenudetail{
    height: inherit;
    flex: 35%;
    position: relative;
    cursor: pointer;
}

.DCPjenismenu{
    color: white;
    margin-left: 20px;
    font-weight: 600;
    font-size: 24px;
    margin-right: 20px;
    margin-bottom: 0;

}

.DCPcardfoodlist{
    margin: 0;
    margin-top: 4px;
    color: #AAAAAA;
    font-size: 14px;
    width: auto;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
}

.DCPcardfoodlisttext{
    margin: 0;
    letter-spacing: 0.5;
}

.DCPcardkontainerbawah{
    width: 100%;
    position: absolute;
    bottom: 0;
}

.DCPtextmax{
    margin-left: 20px ;
    margin-right: 20px;
    color: white;
    font-size: 14px;
}

.DCPtextstart{
    color: white;
    margin: 0;
    margin-left: 20px ;
    margin-right: 20px;
    font-size: 18px;
}

.DCPtextharga{
    color: white;
    margin: 0;
    margin-left: 20px ;
    margin-right: 20px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 24px;
}

@media (min-width: 1000px) {
    .DCParrow{
        opacity: 0;
    }

    .DCPkontenmenucard:hover .DCParrow{
        opacity: 1;
        transition: opacity 0.5s;
    }
}

@media (max-width: 1000px) {
    .DCPmenucard{
        flex: 100%;
        height: 270px;
        margin-bottom: 24px;
    }

    .DCPkontenmenucard{
        margin: 0;
    }

    .DCPjenismenu{
        font-size: calc(6px + 2vw);
    }

    .DCPcardfoodlisttext{
        font-size: calc(2px + 2vw);
    }

    .DCPtextmax{
        font-size: calc(2px + 2vw);
        margin-bottom: 8px;
    }

    .DCPtextstart{
        font-size:  calc(6px + 2vw);
    }

    .DCPtextharga{
        font-size:  calc(6px + 2vw);
        margin-bottom: 8px;
    }

    .DCPcaraousel{
        flex: 60%;
    }
    
    .DCPmenudetail{
        flex: 40%;
    }
}
 */
