.ListChefPageCardContainer{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 50px;
    gap: 20px;
}
.space-between{
    justify-content: space-between;
}.gap-40{
    gap: 45px !important;
}
.ListChefPageCardContainer .listchefcardcontainer{
    width: 280px;
}
.listchefcardcontainer .kontenlistchefcard{
    cursor: pointer;
}
.listchefcardcontainer .kontenlistchefcard .gambarWrap{
    width: 100%;
    height: 280px;
    overflow: hidden;
    border-radius: 15px;
}
.listchefcardcontainer .kontenlistchefcard .gambarWrap img{
    width: 100%; 
    height: 100%;
    transform: scale(1);
    object-fit: cover;
    transition: transform .5s;
}
.listchefcardcontainer .kontenlistchefcard:hover .gambarWrap img{
    transform: scale(1.2);
}
.kontenlistchefcard .kontainerdetaillistchef{
    padding: 10px 0;
}
.kontenlistchefcard .kontainerdetaillistchef .kontaineratas{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}
.kontaineratas h1{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: white;
    margin: 0;
}
.kontaineratas .kontainerkanan{
    display: flex;
    align-items: center;
    gap: 5px;
}
.kontaineratas .kontainerkanan img{
    max-width: 16px;
    height: auto;
    object-fit: cover;
}
.kontaineratas .kontainerkanan p{
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin: 0;
}
.kontenlistchefcard .kontainerdetaillistchef .kontainerlistcuisine{
    margin-bottom: 10px;
    display: flex;
    align-items:center;
    gap: 5px;
    flex-wrap: wrap;
    /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden; */
}
.more{
    color: #969696 !important;
}
.kontenlistchefcard .kontainerdetaillistchef .kontainerlistcuisine p{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #b9b9b9;
    margin: 0;
}
.kontenlistchefcard .kontainerdetaillistchef .kontainerarea p{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #969696;
    margin: 0;
}
.kontenlistchefcard .kontainerdetaillistchef .kontainerlistchefbawah{
    margin-top: 20px;
}
.kontenlistchefcard .kontainerdetaillistchef .kontainerlistchefbawah p{
    margin: 0 0 2px 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #969696;
}
.kontenlistchefcard .kontainerdetaillistchef .kontainerlistchefbawah h1{
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: white;
}

@media (max-width:1000px){
    .ListChefPageCardContainer .listchefcardcontainer{
        width: 48%;
    }
    
}

@media (max-width:588px){
    .ListChefPageCardContainer .listchefcardcontainer{
        width: 100%;
    }
    .ListChefPageCardContainer{
        padding:20px;
    }
}
/* .listchefcardcontainer{
    position: relative;
    height: auto;
    padding: 0;
    overflow: hidden;
    margin-bottom: 47px;
}


.kontenlistchefcard{
    position: relative;
    width: 250px;
    height: inherit;
    margin-left: 20px;
    margin-right: 20px;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
}

.kontenlistchefcard:hover .textdatanama{
    color: #FCCC24;
}

.gambarlistchef{
    border-radius: 15px;
    height: 250px;
    width: 100%;
    min-width: inherit;
    object-fit:cover;
}
.kontainerlistcuisine{
    display: flex;
    flex-wrap: wrap;
}
.kontainerdetaillistchef{
    width: auto;
}

.kontaineratas{
    width: auto;
    display: flex;
}

.textdatanama{
    color: white;
    font-weight: 600;
    font-size: 18px;
    margin:0;
    transition: all 0.3s;
}

.kontainerkanan{
    display: flex;
    margin-left: auto;
    align-items: center;
}

.iconlistchef{
    height: 18px;
    margin-top: 12px;
    margin-bottom: auto;
}

.texticonlistchef{
    margin-top: 12px;
    margin-left: 4px;
    color: white;
    margin-bottom: auto;
    font-size: 18px;
}
.textlistcuisine{
    color: white;
    margin-top: 4px;
    font-size: calc(6px + 0.5vw);
    margin-bottom: 0;
}

.textareachef{
    color: #909090;
    margin-top: 4px;
    font-size: calc(6px + 0.5vw);
}

.textstartfrom{
    margin: 0;
    color: white;
    font-size: calc(6px + 0.5vw);
}

.texthargachef{
    margin: 0;
    color: white;
    font-weight: 600;
    margin-bottom: 8px;
    font-size: calc(8px + 0.5vw);
}

@media (max-width: 1000px){
    .listchefcardcontainer{
        flex: 50%;
    }

    .textlistcuisine{
        font-size: 12px;
    }
    
    .textareachef{
        font-size: 12px;
    }
    
    .textstartfrom{
        font-size: 12px;
    }
    
    .texthargachef{
        font-size: 12px;
    }
}

@media (max-width: 750px) {
    .listchefcardcontainer{
        flex: 100%;
        height: 450px;
    }
} */
