.chefBannerSingle{
    cursor: pointer;
    width: 100%;
    height: 250px;
    background: linear-gradient(to bottom right, #272727, #272727 ,#3C3C3C);
    margin-top: 2rem;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 0.6rem;
}
/* .chefBannerSingle.version2{
    display: flex;
    align-items: center;
    overflow: hidden;
} */
.chefBannerSingle:hover .img-bg{
    transform: scale(1.1);
}
/* .chefBannerSingle.version2{
    display: flex;
    align-items: center;
    background-image: url("../../assets/chef_bg2.png");
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
} */
.chefBannerSingle.menuBanner{
    background: linear-gradient(to top left, #FCA624,#FCA624 , #FCCC24);
}
.chefBannerSingle:hover .img-1{
    transform: scale(1.05);
}
.chefBannerSingle:hover .img-2{
    right: 1rem;
}
.chefBannerSingle:hover .img-3{
    left: 1rem;
}
.chefBannerSingle::after{
    content: "";
    background: linear-gradient(50deg, #272727,#27272789, #27272700);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
}
.chefBannerSingle.version2::after{
    content: "";
    background: linear-gradient(to right, #272727,#272727,#272727be,#2727272c, #27272700);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}
.chefBannerSingle.chefBannerSingle.menuBanner::after{
    background: linear-gradient(to top right, #FCCC24, #fccd24c6, transparent);
}
.chefBannerSingle .img-bg{
    position: absolute;
    right: -10%;
    bottom: -5%;
    transition: all .5s;
}
.chefBannerSingle .img-1{
    position: absolute;
    right: 0;
    z-index: 2;
    transition: all .5s;
}
.chefBannerSingle .img-2{
    position: absolute;
    right: -1rem;
    z-index: 1;
    transition: all .5s;
}
.chefBannerSingle .img-3{
    position: absolute;
    left: -5rem;
    top: -10rem;
    z-index: 1;
    transition: all .5s;
}
.chefBannerSingle .chefBannerText{
    max-width: 300px;
    position: absolute;
    z-index: 4;
    top: 50%;
    transform: translateY(-50%);
    left: 10%;
}
.chefBannerSingle .chefBannerText::after{
    position: absolute;
}
.chefBannerSingle .chefBannerText h1{
    margin: 0 0 0.5rem 0;
    color: #d9d9d9;
    font-size: 2rem;
}
.chefBannerSingle.chefBannerSingle.menuBanner .chefBannerText h1{
    color: #1f1f1f;
}
.chefBannerSingle.chefBannerSingle.menuBanner .chefBannerText p{
    color: #343434;
}
.chefBannerSingle .chefBannerText p{
    width: calc(80% - 1rem);
    margin: 0 0 1rem 0;
    color: #A9A9A9;
    font-size: 1rem;
}

.btnExplore {
    width: fit-content;
    border-radius: 20px;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
}

.btnExplore.chefsBanner{
    color: #1f1f1f;
    background-color: #fff;
}
.btnExplore.menusBanner{
    color: #fff;
    background-color: #1f1f1f;
}

@media (min-width: 0) and (max-width:600px){
    .chefBannerSingle .chefBannerText h1{
        font-size: 1.5rem;
    }
    .chefBannerSingle .chefBannerText p,
    .btnExplore{
        font-size: 0.8rem;
    }
    .chefBannerSingle{
        height: 200px;
    }
    .chefBannerSingle .img-bg{
        right: auto;
    }
}
@media (max-width:350px){
    .chefBannerSingle .chefBannerText h1{
        font-size: 7vw;
    }
    .chefBannerSingle .chefBannerText p,
    .btnExplore{
        font-size: 4vw;
    }
}

/* Image responsives  */
@media (max-width:600px){
    .chefBannerSingle .img-1{
        width: 40%;
    }
}
@media(max-width:400px){
    .chefBannerSingle .img-1{
        width: 10rem;
        bottom: -3rem;
    }
}

@media (min-width:0) and (max-width:800px){
    .chefBannerSingle .img-2{
        display: none;
    }
}
@media (min-width:1001px) and (max-width:1150px){
    .chefBannerSingle .img-3{
        left: -8rem;
    }
    .chefBannerSingle .img-2{
        right: -5rem;
    }
}