.RPtrans-appear {
    opacity: 0;
    transform: translateX(50%);
}
.RPtrans-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1000ms;
}
.RPtrans-enter {
    opacity: 0;
}
.RPtrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.RPtrans-exit {
    opacity: 1; 
    transform: translateX(0);
}
.RPtrans-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 1000ms;
}

.RPkontainer{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20vh;
    padding-bottom: 10vh;
    font-family: Arial, Helvetica, sans-serif;
}

.RPkontainerkiri{
    flex: 65%;
    padding-left: 20px;
    padding-right: 20px;
}

.RPkontainerkanan{
    flex: 35%;
    padding-left: 20px;
    padding-right: 20px;
}

.RPkonten{
    margin-top: 18px;
}

.RPkonten2{
    /* margin-top: 64px; */
    margin-top: 32px;
}

.RPtittle{
    margin-left: 20px;
}

.RPkontenputih{
    background-color: white;
    padding: 45px;
    border-radius: 15px;
}

.RPstatus{
    padding: 15px;
    border: 2px solid #7F6200;
    border-radius: 15px;
    margin: 0;
    background-color: white;
    color: #7F6200;
    margin-left: auto;
    cursor: pointer;
    transition: all 0.5s;
}

.RPstatus:hover{
    color: white;
    background-color: #7F6200;
}

.RPmargin{
    margin-top: 32px;
}

.RPmargin2{
    margin-top: 46px;
}

.RPmargin3{
    margin-top: 20px;
}

.RPmargin4{
    margin-top: 12px;
}

.RPimage{
    height: 71px;
    width: 71px;
    border-radius: 15px;
    margin-right: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.RPicon{
    height: 24px;
    margin-right: 8px;
}

.RPline{
    height: 0.5px;
    background-color: #C4C4C4;
}

.RPbutton{
    width: -webkit-fill-available;
    width: -moz-available;
    height: 60px;
    border: none;
    outline: none;
    background-color: #FCCC24;
    cursor: pointer;
    border-radius: 15px;
}

.RPbutton:active{
    background-color: rgba(216, 175, 32, 1);
}

.RPbutton2{
    width: -webkit-fill-available;
    width: -moz-available;
    height: 60px;
    border: 1px solid #FCCC24;
    color:#FCCC24;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 15px;
}

@media (max-width: 1000px) {
    .RPkonten{
        flex-direction: column;
    }

    .RPkonten2{
        /* flex-direction: column-reverse; */
        flex-direction: column;
        margin-top: 0px;
    }

    .RPkontainer{
        padding-top: 150px;
    }

    .RPmargin{
        margin-top: 28px;
    }
    
    .RPmargin2{
        margin-top: 32px;
    }
    
    .RPmargin3{
        margin-top: 16px;
    }

    .RPmargin4{
        margin-top: 10px;
    }

    .RPkontainerkanan{
        margin-bottom: 32px;
    }
    .RPkontainerkiri{
        margin-bottom: 32px;
    }

    .RPkontenputih{
        padding: 30px;
    }

    .RPstatus{
        padding: 10px;
        font-size: 11px!important;
    }
}