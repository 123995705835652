::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent; 
}
 
::-webkit-scrollbar-thumb {
  background: #FCCC24; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e2b40e; 
}
.ChefListPageWrapper{
    margin: auto;
    max-width: 1100px;
    /* max-width: 1440px; */
    background-color: red;
}
.ChefListPageWrapper .ListChefPageCardGrid{
    width: 100%;
    height: 500px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    background-color: blue;
    padding: 0 50px;
    box-sizing: border-box;
}
.CLtrans-appear {
    opacity: 0;
    transform: translateY(50%);
}
.CLtrans-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 1000ms;
}
.CLtrans-enter {
    opacity: 0;
}
.CLtrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.CLtrans-exit {
    opacity: 1; 
    transform: translateX(0);
}
.CLtrans-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 1000ms;
}

.chefListWrapper{
    max-width: 1100px;
    /* max-width: 1440px; */
    margin: auto;
    /* background-color: red; */
    box-sizing: border-box;
}

.headerkontainer{
    padding: 150px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}
.cuisinefilter{
    width: 50%;
    height: auto;
    position: relative;
}
.cuisinefilter .kontainercategorycuisine{
    overflow: auto;
    display: flex;
    transition: all .5s;
    scroll-snap-type: x mandatory;
}.cuisinefilter::after{
    content: "";
    position: absolute;
    top: 0;
    right: -1px;
    width: 50px;
    height: 100%;
    background: linear-gradient(to left, #111 10%, #111 30%, transparent 100%);
    pointer-events: none;
}
.cuisinefilter::before{
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
    width: 50px;
    height: 100%;
    background: linear-gradient(to right, #111 10%, #111 30%, transparent 100%);
    pointer-events: none;
}

.cuisinefilter .kontainercategorycuisine::-webkit-scrollbar{
    width:0;
    height:0;
}
.filterWrapper{
    display: flex;
}
.categorylist{
    flex-shrink: 0;
    color:white;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.3s;
    scroll-snap-align: none;
    margin-right: 50px;
}
.categorylist:last-child{
    margin-right: 40px;
}
.categorylist:first-child{
    margin-left: 40px;
}

.categorylist:hover{
    color:#FCCC24;
}

.filterchoice{
    color: white;
    margin:0;
    font-size: 12px;
    width: 70px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.filterchoice2{
    color: white;
    margin:0;
    font-size: 12px;
    width: 120px;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.fieldchef{
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    color: white;
}

.filterchef{
    display: flex;
    cursor: pointer;
    width: max-content;
    border: 1px solid #C4C4C4;
    padding: 10px;
    border-radius: 16px;
    align-items: center;
}

.containerfilterchef{
    width: min-content;
    position: relative;
}
.kontainerfiltercuisine{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0 50px;
}

.findchef{
    display: flex;
    cursor: pointer;
    width: max-content;
    border: 1px solid #C4C4C4;
    padding: 10px;
    border-radius: 16px;
    align-items: center;
}

.findchef:focus-within{
    border: 1px solid #FCCC24;
}

.LCyellow{
    border: 1px solid #FCCC24;
}

.popupkontainerlistchef{
    position: absolute;
    right: 0;
    width: 250px;
    height: 100px;
    overflow-y:auto;
    border:1px solid #f3f3f3;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 20px;
    padding: 10px 0;
    box-sizing: border-box;
}

.popupkontainerlistchefmobile{
    position: absolute;
    right: 0;
    width: 200px;
    height: fit-content;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    margin-right: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
}

/* .inputfind{
    display: flex;
    cursor: pointer;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border: 1px solid #C4C4C4;
    padding: 10px;
    border-radius: 16px;
} */

.iconsearch{
    height: calc(1vh + 0.6vw);
    margin-right: 10px;
}

.fieldlocation{
    width: 100%;
    border: none;
    outline: none;
}

.popupfontlistchef{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    padding: 15px 15px;
    cursor: pointer;
}
.popupfontlistchef:hover{
    background-color:#f7f7f7;
}

.kontainerPenggantiNavbar{
    z-index:5;
    position:fixed;
    display:flex;
    width:-webkit-fill-available;
    width: -moz-available;
    align-items:center;
}

.PenggantiNavbar{
    width: 100%;
    height: 90px;
    padding: 0 50px;
    display:flex;
    align-items:center;
    position: relative;
}

.searchbarmobile{
    width: 90%;
    display:flex;
    margin-left: auto;
    margin-right: auto;
    align-items:center;
    position: relative;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    border-radius: 25px;
    background-color: #323232;
    min-height: 30px;
}

.searchbarmobilekontainer{
    width: 90%;
    display:flex;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
    margin-left: auto;
    margin-right: auto;
    align-items:center;
    position: relative;
    min-height: 50px;
}

.iconKiriNavMo{
    width: 24px;
    height: 24px!important;
    cursor: pointer;
}

.iconKiriSerMo{
    height: 20px!important;
    margin-top: 2px;
    margin-bottom: 2px;
    cursor: pointer;
    margin-left:20px;
}

.iconKananSerMo{
    height: 20px!important;
    margin-top: 2px;
    margin-bottom: 2px;
    cursor: pointer;
    margin-left:auto;
    margin-right: 20px;
}

.iconKananNavMo{
    height: 24px!important;
    cursor: pointer;
    margin-left:auto;
    margin-right:20px;
}

@media (min-width: 1001px) {
    .kontainerPenggantiNavbar{
        display: none;
    }
}
@media (max-width: 1000px) {
    .kontainerfiltercuisine{
        display: none;
    }
    .cuisinefilter{
        width: 100%;
    }
    /* .CategoryCardContainer{
        display: flex;
        width: 100%;
        padding-top: 20vh;
        flex-direction: column;
    } */
    .popupfontlistchef{
        font-size: 12px;
    }
    .headerkontainer{
        padding:100px 50px 0px 0px;
    }
    .categorylist{
        font-size: 14px;
    }
}

@media (max-width: 1086px){
    /* .cuisinefilter .kontainercategorycuisine{
        width: 90vw;
    } */
}
@media (max-width: 588px){
    /* .cuisinefilter .kontainercategorycuisine{
        width: 90vw;
    } */
    .categorylist:last-child{
        margin-right: 40px;
    }
    .popupkontainerlistchef{
        width: 40vw;
        left: 0;
    }
    .headerkontainer{
        padding: 100px 20px 0 20px;
    }
    .PenggantiNavbar{
        padding: 0 20px;
    }
}
@media (max-width: 318px){
    /* .cuisinefilter .kontainercategorycuisine{
        width: 80vw;
    } */
    
    .categorylist{
        scroll-snap-align: center;
    }
    .iconKananNavMo{
        margin-right: 5px;
    }
}