body{
    scroll-behavior: smooth;
}
.hidden{
    display: none !important;
}
.inline{
    display: inline-block !important;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    opacity: 20%;
    transform: scale(0.9);
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
0% {
    transform: rotate(0deg);
    }
100% {
    transform: rotate(360deg);
    }
}

@keyframes cardIn {
    0%{
        transfor: scale(0)
    }
    45%{
        transform: scale(1);
    }
    60%{
        transform: scale(0.9);
    }
    100%{
        transform: scale(1);
    }
}
.cardEntry {
    animation: cardIn .5s;
    animation-fill-mode: forwards;  
    animation-delay: 150ms;
    transform: scale(0);
}
.line-clamps-2{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.line-clamps-3{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.w-full{
    width: 100%;
}
.menuListWrapper{
    max-width:1100px;
    margin: auto;
    padding: 150px 50px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,2fr);
    position: relative;
}

.MLtrans-appear {
    opacity: 0;
    transform: translateY(50%);
}
.MLtrans-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 1000ms;
}
.MLtrans-enter {
    opacity: 0;
}
.MLtrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.MLtrans-exit {
    opacity: 1; 
    transform: translateX(0);
}
.MLtrans-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 1000ms;
}

/* Floating Navbar nih  */
.FloatingmobileNavbar{
    top: 0;
    max-width: 1100px;
    /* max-width: 1440px; */
    position: sticky;
    height: 90px;
    padding: 0 50px;
    z-index: 5;
    display: none;
}
.FloatingmobileNavbar .floatingNavbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

/* Sort by mobile  */
.FloatingmobileNavbar .floatingNavbar .sortBy{
    position: absolute;
    right: 10%;
    bottom: -7.5rem;
    border-radius: 0.6rem;
    background-color: #fff;
    overflow: hidden;
}
.FloatingmobileNavbar .floatingNavbar .sortBy p{
    font-size: 1rem;
    padding: 1rem;
    cursor: pointer;
}
.FloatingmobileNavbar .floatingNavbar .sortBy p:hover{
    background-color: #f1f1f1;
}

.FloatingmobileNavbar .floatingNavbar img{
    cursor: pointer;
}

@keyframes popUpIn {
    0%{
        transform: scale(0);
        opacity: 0;
    }
    25%{
        transform: scale(1);
        opacity: 1;
    }
    50%{
        transform: scale(0.95);
    }
    100%{
        transform: scale(1);
    }
}
@keyframes popUpOut {
    0%{
        transform: scale(1);
        opacity: 1;
    }
    25%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(0);
        opacity: 0;
    }
}
.popUpIn{
    animation: popUpIn .5s;
    animation-fill-mode: forwards;
}
.popUpOut{
    animation: popUpOut .2s;
    animation-fill-mode: forwards;
}
.FloatingmobileNavbar .navbarSearch{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60%;
}
.FloatingmobileNavbar .navbarSearch .inner{
    border-radius: 10rem;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #1f1f1f;
    padding: 0 1.5rem;
}
.FloatingmobileNavbar .navbarSearch .inner img{
    cursor: pointer;
}
.FloatingmobileNavbar .navbarSearch .inner input{
    width: calc(90% - 1rem);
    font-size: 1rem;
    outline: 0;
    border: 0;
    background-color: transparent;
    color: #6a6a6a;
}
.FloatingmobileNavbar .navbarSearch .inner input::placeholder{
    font-size: 1rem;
}

@media (max-width:1000px){
    .FloatingmobileNavbar{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media (max-width:520px){
    .FloatingmobileNavbar{
        padding: 0 20px;
    }
}
@media (max-width:280px){
    .FloatingmobileNavbar .floatingNavbar .sortBy{
        right: 17vw;
        bottom: -28vw;
    }
    .FloatingmobileNavbar .floatingNavbar .sortBy p{
        font-size: 4vw;
        padding: 4vw;
    }
}

/* Ini bagian kiri  */
.menuListWrapper .menuListInfo{
    width: calc(95% - 0.5rem);
    height: fit-content;
    position: sticky;
    top: 7.5rem;
}
.menuListWrapper .menuListInfo h1{
    font-size: 2.3rem;
    margin: 0 0 1rem 0;
    color: #d9d9d9;
}
.menuListWrapper .menuListInfo p{
    margin: 0 0 1.2rem 0;
    font-size: 1.3rem;
    color: #6a6a6a;
}
@media (min-width:1000px) and (max-width:1060px){
    .menuListWrapper .menuListInfo h1{
        font-size: 2.1rem;
    }
    .menuListWrapper .menuListInfo p{
        font-size: 1.1rem;
    }
}
/* Ini bagian kiri filter search  */
.menuListWrapper .menuListInfo .menuListSearch{
    display: flex;
    align-items: center;
    height: fit-content;
    width: 100%;
    background-color: #1F1F1F;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
}
.menuListWrapper .menuListInfo .menuListSearch label img{
    cursor: pointer;
    width: 20px;
}
.menuListWrapper .menuListInfo .menuListSearch input{
    color: #6a6a6a;
    border: none;
    outline: none;
    background-color: #1F1F1F;
    padding: 0.7rem;
    font-size: 1rem;
    width: 100%;
}
.menuListWrapper .menuListInfo .menuListSearch input::placeholder{
    font-size: 1rem;
}

/* Ini bagian kiri filters  */
@keyframes animatedFloatLocate{
    from{
        opacity: 0;
        bottom: -7rem;
    }
    to{
        opacity: 1;
        bottom: -12rem;
    }
}
@keyframes animatedFloatSort{
    from{
        opacity: 0;
        bottom: -3rem;
    }
    to{
        opacity: 1;
        bottom: -8rem;
    }
}
.animatedFloatLocate{
    animation: animatedFloatLocate .5s;
    animation-fill-mode: forwards;
}
.animatedFloatSort{
    animation: animatedFloatSort .5s;
    animation-fill-mode: forwards;
}
.menuListWrapper .menuListInfo .menuListFilters{
    margin: 1rem 0;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
}
.menuListInfo .menuListFilters .filterLocation,
.menuListInfo .menuListFilters .filterSortBy{
    position: relative;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 1rem;
    background-color: #111111;
    border: 1px solid #1f1f1f;
    border-radius: 0.6rem;    
    cursor: pointer;
}
.menuListInfo .menuListFilters .filterLocation img,
.menuListInfo .menuListFilters .filterSortBy img{
    width: 1.2rem;
}
.menuListInfo .menuListFilters .filterLocation p,
.menuListInfo .menuListFilters .filterSortBy p{
    margin: 0;
    font-size: 1rem;
}

.menuListInfo .filterFloat{
    position: absolute;
    opacity: 0;
    border-radius: 0.6rem;
    width: 100%;
    height: fit-content;
    background-color: #fff;
    overflow: hidden;
}
.menuListInfo .filterFloat .floatInner{
    width: 100%;
    height: 100%;
    padding: 1rem;
    position: relative;
    box-sizing: border-box;
}
.menuListInfo .filterFloat .floatInner .floatSearch{
    height: fit-content;
    position: sticky;
    top: 0;
    border: 1px solid #969696;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
}
.menuListInfo .filterFloat .floatInner .floatSearch label{
    width: 1rem;
    height: 1rem;
}
.menuListInfo .filterFloat .floatInner .floatSearch input{
    color: #969696;
    width: 100%;
    padding: 0.5rem;
    border: none;
    outline: none;
}
.menuListInfo .filterFloat .floatInner .floatSelect{
    width: 100%;
    height: 100px;
    overflow: auto;
}
.menuListInfo .filterFloat .floatInner .floatSelect p{
    font-size: 1rem;
    margin: 0;
    padding: 1rem;
    cursor: pointer;
}
.menuListInfo .filterFloat .floatInner .floatSelect p:hover{
    background-color: #f1f1f1;
}
/* Ini bagian kanan  */
.menuListWrapper .menuListContents{
    padding-left: 1.5rem;
    border-left: 1px solid #1f1f1f;
}

.menuListWrapper .menuListContents .menuListFilters{
    position: relative;
    width: 100%;
    margin-bottom: 1.5rem;
}
.menuListWrapper .indicatorList{
    z-index: 50;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    pointer-events: none;
    left: 0;
}
.menuListWrapper .indicatorList i{
    font-size: 1rem;
    color: #2d2d2d;
}
.menuListWrapper .menuListContents .menuListFilters::after{
    content: "";
    width: 50px;
    pointer-events: none;
    height: 100%;
    background:linear-gradient(to left, #111111, #111111 ,transparent);
    position: absolute;
    right: -1px;
    bottom: 0;
}
.menuListWrapper .menuListContents .menuListFilters::before{
    content: "";
    width: 50px;
    pointer-events: none;
    height: 100%;
    background:linear-gradient(to right, #111111, #111111 ,transparent);
    position: absolute;
    left: -1px;
    bottom: 0;
}
.menuListWrapper .menuListContents .menuListFilters .inner{
    /* max-width: 50vw; */
    overflow-x: auto;
    display: flex;
    align-items: center;
    gap: 1.4rem;
}
.menuListWrapper .menuListContents .menuListFilters .inner .filters{
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    color: #6a6a6a;
    cursor: pointer;
}
.menuListWrapper .menuListContents  .memenuListContentsnuListFilters .inner .filters.active{
    color: #d9d9d9;
}

.menuListWrapper .menuListContents .cardList{
    width: 100%;
    height: fit-content;
    height: -webkit-fit-content;
    /* display: grid; */
    /* grid-template-columns: minmax(0,1fr); */
    /* gap: 2rem; */
    columns: 1;
    -webkit-columns: 1;
}

/* Bagian pagination  */
.menuListWrapper .menuListContents .dataNotfound{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 60px;
}

.menuListWrapper .menuListContents .dataNotfound .pag_not_found.active{
    color: #d9d9d9;
}

.menuListWrapper .menuListContents .pagination{
    width: 100%;
    margin: 2.5rem auto;
    display: flex;
    gap: 1rem;
    justify-content:center;
    align-items:center;
}
.menuListWrapper .menuListContents .pagination i{
    color: #6a6a6a;
    font-size: 1rem;
    cursor: pointer;
}
.menuListWrapper .menuListContents .pagination p{
    color: #6a6a6a;
}
.menuListWrapper .menuListContents .pagination span{
    color: #FCCC24;
}
.menuListWrapper .menuListContents .pagination p,
.menuListWrapper .menuListContents .pagination ul li{
    font-size: 1rem;
}
.menuListWrapper .menuListContents .pagination ul{
    list-style-type: none;
    display: flex;
    gap: 2rem;
    padding: 0;
    margin: 0;
}
.menuListWrapper .menuListContents .pagination ul li{
    font-weight: 500;
    color: #6a6a6a;
    cursor: pointer;
}
.menuListWrapper .menuListContents .pagination .pag_next.active,
.menuListWrapper .menuListContents .pagination .pag_back.active{
    color: #d9d9d9;
}

.menuListWrapper .menuListContents .pagination ul li.active{
    color: #FCCC24;
}
/* Ini styling cards  */
.byCategoryCards{
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: fit-content;
    display: grid;
    border: 1px solid #292929;
    margin-bottom: 1rem;
}
.byCategoryCards:hover .byCategoryCardsImage img{
    transform: scale(1.2);
}

.byCategoryCards .byCategoryCardsImage{
    overflow: hidden;
    aspect-ratio: 1/1;
}
.byCategoryCards .byCategoryCardsImage img{
    transition: all .5s;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.byCategoryCards .byCategoryCardsContents{
    padding: 1rem;
    height: fit-content;
    /* height: 120px; */
    /* min-height: 120px; */
    background-color: #1F1F1F;
}
.byCategoryCardsContents .head{
    margin: 0 0 0.5rem 0;
    display: flex;
    justify-content: space-between;
    /* display: grid; */
    /* grid-template-columns: 1.5fr 1fr; */
    align-items: center;
}
.byCategoryCardsContents .head h1{
    font-size: 1.1rem;
    color: #d9d9d9;
    margin: 0;
    height: fit-content;    
}

.byCategoryCards .head .headSeats{
    text-align: right;
    place-content: end;
}
.byCategoryCards .head .headSeats p{
    color: #969696;
    font-weight: 500;
    font-size: 0.7rem;
    margin: 0;
}
.byCategoryCards .head .headSeats h1{
    font-size: 0.8rem;
    color: #969696;
    margin: 0;
}
.byCategoryCards .body .cuisines{
    display: flex;
    align-items: center;
    gap: 2px;
    flex-wrap: wrap;
}
.byCategoryCards .body{
    margin: 0 0 0.8rem 0;
}
.byCategoryCards .body p{
    margin: 0 0 0.3rem 0;
    font-size: 0.9rem;
    font-weight: 500;
    color: #707070;
}
.byCategoryCards .body .bodyChefs{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    -webkit-gap: 0.5rem;
}
.byCategoryCards .body .bodyChefs img{
    width: 1.3rem;
    height: 1.3rem;
}
.byCategoryCards .body .bodyChefs h1{
    font-size: 0.8rem;
    margin: 0;
    color: #969696;
}
.byCategoryCards .footer{
    display: flex;
    align-items: start;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: space-between;
}
.byCategoryCards .footer .dietaryMenuCard .dietaryMenuImg{
    display: flex;
    gap: 0.2rem;
    justify-content: end;
    align-items: center;
}
.byCategoryCards .footer .dietaryMenuCard img{
    width: 2rem;
}
.text-right{
    text-align: right;
}
.byCategoryCards .footer .price p,
.byCategoryCards .footer .dietaryMenuCard p{
    font-size: 0.7rem;
    margin: 0;
    color: #969696;
    font-weight: 500;
}
.byCategoryCards .footer .price h1{
    font-size: 0.9rem;
    margin: 0;
    color: #d9d9d9;
}

/* Responsive  */

@media (min-width: 1001px) and (max-width:1099px){
    .menuListWrapper .menuListContents .cardList{
        columns: 2;
        -webkit-columns: 2;
        /* grid-template-columns: minmax(0,1fr) minmax(0,1fr); */
    }
}

@media (min-width:1100px){
    .menuListWrapper .menuListContents .cardList{
        columns: 2;
        -webkit-columns: 2;
        /* grid-template-columns: minmax(0,1fr) minmax(0,1fr); */
        /* gap: 1.5rem; */
    }
}
@media (max-width:1000px){
    .menuListWrapper .menuListContents .menuListFilters .inner{
        /* max-width: 55vw; */
        margin: auto;
        width: 100%;
    }
    .menuListWrapper{
        padding: 20px 50px;
    }
}
@media (max-width:520px){
    .menuListWrapper .menuListContents .pagination{
        max-width: none;
        width: 100%;
        margin: 1rem 0;
    }
    .menuListWrapper{
        padding: 20px 20px;
    }
}

@media (min-width:450px) and (max-width:1000px){
    .menuListWrapper{
        grid-template-columns: minmax(0, 1fr);
    }
    .menuListWrapper .menuListInfo{
        display: none;
    }
    .menuListWrapper .menuListContents{
        padding-left: 0;
        border: none;
    }
    .menuListWrapper .menuListContents .cardList{
        columns: 2;
        -webkit-columns: 2;
        /* grid-template-columns: minmax(0,1fr) minmax(0,1fr); */
        /* gap: 1.5rem; */
    }
}

@media (min-width: 0) and (max-width:280px){
    .menuListWrapper{
        grid-template-columns: minmax(0,1fr);
    }
    .menuListWrapper .menuListInfo{
        display: none;
    }
    .menuListWrapper .menuListContents{
        padding-left: 0;
        border: none;
    }
    .byCategoryCards .byCategoryCardsContents{
        padding: 0.7rem;
    }
    .byCategoryCardsContents .head h1{
        font-size: 6vw;
    }
    .byCategoryCards .head .headSeats p{
        font-size: 4vw;
    }
    .byCategoryCards .body p{
        font-size: 5vw;
    }
    .byCategoryCards .body .bodyChefs h1,
    .byCategoryCards .head .headSeats h1,
    .byCategoryCards .price p,
    .menuListWrapper .menuListContents .dataNotfound p,
    .menuListWrapper .menuListContents .pagination p,
    .menuListWrapper .menuListContents .pagination ul li{
        font-size: 4.5vw;
    }
    .byCategoryCards .price h1{
        font-size: 5.5vw;
    }
}
@media (min-width:281px) and (max-width:449px){
    .menuListWrapper{
        grid-template-columns: minmax(0,1fr);
    }
    .menuListWrapper .menuListInfo{
        display: none;
    }
    .menuListWrapper .menuListContents{
        padding-left: 0;
        border: none;
    }
}