.flex_wrap{
    flex-wrap: wrap;
}
.setScheduleWrapper{
    display: grid;
    grid-template-columns: 1fr;
    padding: 100px 20px 20px 20px;
    gap: 1rem;
}

.lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform:scale(0.6);
  }
  .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #dfdfdf;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }

.PSScontainer{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20vh;
    padding-bottom: 10vh;
    font-family: Arial, Helvetica, sans-serif;
}

.PSStrans-appear {
    opacity: 0;
    transform: translateX(50%);
}
.PSStrans-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1000ms;
}
.PSStrans-enter {
    opacity: 0;
}
.PSStrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.PSStrans-exit {
    opacity: 1; 
    transform: translateX(0);
}
.PSStrans-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 1000ms;
}

.PSStitle{
    padding-left: 20px;
}

.PSSkontenkiri{
    flex: 50%;
    padding-left: 20px;
    padding-right: 20px;
}

.PSSkontenkanan{
    flex:50%;
    margin-left:40px;
    margin-right: 20px;
    position: relative;
}

.PSSyellow{
    border: 1px solid #FCCC24!important;
}
@keyframes informasiIn{
    from{
        top: 150%;
    }
    top{
        top: 50%;
    }
}
@keyframes informasiOut{
    from{
        top: 50%;
    }
    to{
        top: 150%;
    }
}
.informasiIn {
    animation: informasiIn .6s;
    animation-fill-mode: forwards;
}
.informasiOut {
    animation: informasiOut .6s;
    animation-fill-mode: forwards;
}
.informasiWrapper.cancellation{
    max-width:700px;
}
.informasiWrapper{
    max-width: 400px;
    width: 100%;
    height: fit-content;
    position: fixed;
    z-index: 1001;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    background-color: #181818;
}
.informasiWrapper *{
    margin: 0 !important;
    box-sizing: border-box;
}
.informasiWrapper .informasiHead *{
    color: #d9d9d9 !important;
}
.informasiWrapper .informasiHead{
    background-color: #1F1F1F;
    width: 100%;
    align-items: center;
    display: flex;
    gap: 0.5rem;
    padding: 1rem 1rem;
    border-radius: 1rem 1rem 0 0;
}
.informasiWrapper .informasiHead i{
    font-size: 1.5rem;
}
.informasiWrapper .informasiHead h1{
    font-size: 1rem;
}

.informasiWrapper .informasiBody *{
    color: #767676 !important;
}
.informasiWrapper.cancellation .informasiBody{
    /* min-height:230px; */
    padding: 1rem 1rem;
    max-height:230px;
    height:100%;
}
.informasiWrapper .informasiBody{
    min-height: 150px;
    max-height: 150px;
    overflow: scroll;
    height: 100%;
    padding: 0.5rem 1rem;
    background-color: #181818;
}
.informasiWrapper .informasiBody p{
    line-height: 1.2rem;
    font-size: 0.9rem;
}
.informasiWrapper .informasiBody p span{
    font-weight: bold;
}
.informasiWrapper.cancellation .informasiBody a img{
    margin-bottom: 1rem;
    width: 100%;
    height: auto;
}
/* 
.informasiWrapper.cancellation .informasiBody table,th,td{
    overflow-x: auto;
    border-collapse: collapse;
    text-align: center;
}
.informasiWrapper.cancellation .informasiBody table{
    width: 100%;
}
.informasiWrapper.cancellation .informasiBody table th{
    background-color: #FCCC24;
    border: 1px solid #FCCC24;
    color: #181818 !important;
    padding: 0.5rem;
}
.informasiWrapper.cancellation .informasiBody table td{
    padding: 0.5rem;
} */

.informasiWrapper .informasiFooter{
    border-top: 1px solid #1F1F1F;
    background-color: #181818;
    padding: 1rem 1rem;
    width: 100%;
    display: flex;
    justify-content: end;
    border-radius: 0 0 1rem 1rem;
}
.informasiWrapper .informasiFooter button{
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 0.5rem 2rem;
    background-color: #FCCC24;
    color: #181818;
    font-weight: bold;
    outline: none;
    border: none;
}
.backdrop{
    width: 100%;
    height: 100%;
    background-color: #151515;
    opacity: 80%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 900;
    cursor: pointer;
}
.infoPush{
    display: flex;
    align-items:center;
}
.infoPush .button{
    display: flex;
    align-items:center;
    justify-content:center;
    font-size: 0.8rem;
    font-weight: 600;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    border: 1px solid #6F6F6F;
    color: #6F6F6F;
    cursor: pointer;
}
.PSSred{
    border: 1px solid #FA591D!important;
}

.PSSmargin{
    margin-top: 68px;
}
.grey-text{
    color: #969696;
}

.PSSimg{
    flex-shrink:0;
    height: 165px;
    width: 165px;
    border-radius: 15px;
    margin-right: 44px;
    object-fit:cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.PSStextarea{
    resize: none;
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 25px;
    border-radius: 15px;
    margin-top: 28px;
    color: white;
    background: transparent;
}

.PSSkananwrapper{
    position: absolute;
    width: -webkit-fill-available;
    width: -moz-available;
    bottom: 0;
}

.PSSkananwrapper2{
    position: absolute;
    width: -webkit-fill-available;
    width: -moz-available;
    bottom: 0;
}

.setScheduleWrapper .PSSformkontainer{
    width: 100%;
    box-sizing: border-box;
}
.PSSformkontainer{
    background-color: white;
    padding: 35px;
    border-radius: 15px;    
}

.PSSpertanyaanflexGap{
    width: 30px;
    height: 25px;
}

.PSSformgroup {
    gap: 15px;
    /* -webkit-gap:15px; */
    /* margin-left: 15px; */
    margin-top: 26px;
    position: relative;
    /* cursor: pointer; */
}

.PSSpertanyaanflex{
    flex: 50%;
    display: flex;
    flex-direction: column;
}

.PSSpertanyaantext{
    color: #6F6F6F;
    margin-right: 15px;
}

.PSSpertanyaan{
    padding: 15px;
    margin-top: 12px;
    border-radius: 15px;
    border: 1px solid #C4C4C4;
    align-items: center;
    /* height: -webkit-fill-available; */
    width: -moz-available;
}

.questionWrapper{
    margin-top: 20px;
}

.inputWrapper{
    display: flex;
    cursor: pointer;
    max-width: 100%;
    border: solid 1px #C4C4C4;
    align-items: center;
    padding: 10px;
    border-radius: 14px;
    align-items: center;
}


.PSSiconkiri{
    height: 16px;
    margin-right: 8px;
}

.PSSiconkanan{
    height: 16px;
    margin-left: auto;
    cursor: pointer;
}

.PSSbutton{
    margin-top: 10px;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 15px;
    padding: 20px;
    font-size: 21px;
    background-color: #FCCC24;
    cursor: pointer;
}

.PSSbutton:active{
    background-color: rgba(216, 175, 32, 1);
}

.PSSopsi{
    margin-top: 16px;
    width: -webkit-fill-available;
    width: -moz-available;
    cursor: pointer;
    margin-bottom: 16px;
}

.PSSpopupkontainer2{
    position: absolute;
    width: -webkit-fill-available;
    width: -moz-available;
    height: fit-content;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin-top: 75px;
}

.highlight-day{
    background-color: green;
}

.PSSpopupkontainer{
    position: absolute;
    /* position: -webkit-absolute; */
    width: auto;
    height: fit-content;
    height: -webkit-fit-content;
    border:1px solid #f3f3f3;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin-top: 75px;
}
.PSSpopupkontainer3{
    right: 0;
    position: absolute;
    width: 50%;
    height: 100px !important;
    overflow-y: auto;
    height: fit-content;
    border:1px solid #f3f3f3;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin-top: 75px;
}
.PSSpopupkontainer3 p{
    padding:15px;
    margin: 0;
}
.PSSpopupkontainer3 p:hover{
    background-color: #f7f7f7;
}

.popupfont{
    font-family: Arial, Helvetica, sans-serif;
    font-size: calc(1vh + 0.6vw);
    margin-left:30px;
    cursor: pointer;
}

.react-datepicker__month-container{
    width: 100%;
}

.react-datepicker{
    width: 100%;
    border: none!important;
    font-size:0.8rem;
    margin-bottom: 15px;
    margin-top: 20px;
}

.react-datepicker__header {
    border:none!important;
    background-color: white!important;
}

.react-datepicker__current-month{
    color:grey
}

.calendarkontainer::-webkit-scrollbar{
    width:0;
    height:0;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #FCCC24!important;
    border-radius: 50%!important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: white!important;
    color: black;
}

@media (min-width: 1000px) {
    .backmobile{
        display: none;
    }
}

@media (max-width: 1000px) {
    .PSScontainer{
        flex-direction: column;
        padding-top: 80px;
    }

    .PSSmainwrapper{
        flex-direction: column;
    }

    .PSSkontenkanan{
        margin-left: 20px;
        margin-top: 32px;
    }
    

    .PSSkananwrapper{
        position: relative;
        width: -webkit-fill-available;
        width: -moz-available;
        margin-left: 0;
        margin-right: 0;
    }

    .PSSkananwrapper2{
        position: relative;
        width: -webkit-fill-available;
        width: -moz-available;
        margin-left: 0;
        margin-right: 0;
    }
    
    .PSSpertanyaan{
        padding: 10px;
        margin-top: 12px;
        border-radius: 15px;
        border: 1px solid #C4C4C4;
        align-items: center;
    }
    
    .PSSiconkiri{
        margin-right: 8px;
    }
    
    .PSSiconkanan{
        margin-left: auto;
        cursor: pointer;
    }

    .PSSbutton{
        font-size: 16px;
    }

    .PSSmargin{
        margin-top: 48px;
    }

    .PSSimg{
        margin-right: 24px;
    }

    .PSSimg2{
        flex: 50%;
    }

    
}

@media (max-width:450px){
    .PSSformkontainer{
        padding: 35px 20px;
    }
    .informasiWrapper{
        width: calc(90% - 1rem);
    }
}

@media (max-width:400px){
    .PSSformgroup{
        flex-wrap: wrap;
    }
    .PSSpopupkontainer3{
        width: 100%;
        margin-top: 160px;
    }
    
    .PSSpopupkontainer{
        width: 100%;
    }
}

@media (min-width:750px){
    .setScheduleWrapper{
        max-width: 1100px;
        margin: auto;
        box-sizing: border-box;
        padding: 100px 50px 50px 50px;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .flex_wrap{
        flex-wrap: nowrap;
    }
}