.PSVcontainer{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20vh;
    padding-bottom: 10vh;
    font-family: Arial, Helvetica, sans-serif;
}
.justify-between{
    justify-content: space-between;
}
.full-center{
    display: block;
    text-align: center;
    word-break: break-all;
}
.orSetVenue{
    display: flex;
    align-items: center;
    gap: 5px;
    color: #e3e3e3;
    margin: 25px 0;
}
.orSetVenue::before,
.orSetVenue::after{
    content: "";
    width: 100%;
    height: 2px;
    background-color: #e3e3e3;
}

.PSVtrans-appear {
    opacity: 0;
    transform: translateX(50%);
}
.PSVtrans-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1000ms;
}
.PSVtrans-enter {
    opacity: 0;
}
.PSVtrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.PSVtrans-exit {
    opacity: 1; 
    transform: translateX(0);
}
.PSVtrans-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 1000ms;
}

.PSVtittle{
    margin-left: 20px;
}

.PSVkontainerkiri{
    flex: 50%;
    padding-left: 20px;
    padding-right: 20px;
}

.PSVkontenwrapper{
    width: auto;
    padding: 60px 35px 60px 35px;
    background-color: white;
    border-radius: 15px;
    margin-top: 36px;
}

.PSVprefix{
    position: relative;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #C4C4C4;
    flex:10%;
    text-align: center;
    margin-right: 2px;
    cursor: pointer;
}

.PSVmargin{
    margin-top: 32px;
}

.PSVmargin2{
    margin-bottom: 32px;
}

.PSVphone{
    width:100%;
    margin-left: 2px;
    position: relative;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #C4C4C4;
    flex:90%;
    outline: none;
}

.PSVphone:focus{
    border: 1px solid #FCCC24;
}

.PSVform:focus{
    border: 1px solid #FCCC24;
}

.PSVyellow{
    border: 1px solid #FCCC24!important;
}
.PSVred{
    border: 1px solid #FA591D!important;
}
.PSVchoose{
    position: relative;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #C4C4C4;
    align-items: center;
    cursor: pointer;
    /* margin-bottom: 32px; */
}

.PSVpopup{
    position:absolute;
    height: 100px;
    overflow-y: auto;
    margin-top: 20px;
    background-color: white;
    border:1px solid #f3f3f3;
    border-radius: 5px;
    width: -webkit-fill-available;
    width: -moz-available;
    min-width: min-content;
    left: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 10; 
}
.PSVpopup p{
    margin: 0;
    padding: 15px;
}
.PSVpopup p:hover{
    background-color: #f7f7f7;
}

.PSVform{
    position: relative;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #C4C4C4;
    outline: none;
    width: -webkit-fill-available;
    width: -moz-available;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
}

.PSVprofile{
    width:20%;
    border-radius: 15px;
    border: 1px solid #C4C4C4;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
    position: relative;
}

.PSVprofile2{
    width:20%;
    border-radius: 15px;
    border: 1px solid #FCCC24;
    background-color: #FFEAB6;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;
    position: relative;
}

.PSVimageadd{
    border-radius: 15px;
    background-color: #DADADA;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 12px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.PSVicon{
    height: 32px;
    margin-bottom: 4px;
}

.PSVicon2{
    height: 24px;
    position: absolute;
    top: 5%;
    right: 5%;
}

.PSVicon3{
    height: 16px;
    margin: 0;
    margin-left: auto;
}

::placeholder {
    font-size: 12px;
}

.PSVimagebutton{
    border: none;
    outline: none;
    background-color: #FCCC24;
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    cursor: pointer;
}

.PSVimagebutton:active{
    background-color: rgba(216, 175, 32, 1);
}

.PSVbutton{
    border: none;
    outline: none;
    background-color: #FCCC24;
    padding: 15px 150px 15px 150px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    margin-left: auto;
}

.PSVbutton:active{
    background-color: rgba(216, 175, 32, 1);
}

@media (min-width: 1000px) {
    .backmobile{
        display: none;
    }
}

@media (max-width: 1000px) {
    .PSVcontainer{
        padding-top: 80px;
    }

    .PSVflex{
        flex-direction: column;
    }

    .PSVchoose{
        padding:14px;
    }

    .PSVicon{
        height: 18px;
    }

    .PSVprofile{
        width: 50px;
        height: 60px;
    }

    .PSVprofile2{
        width: 50px;
        height: 60px;
    }

    .PSVicon2{
        height: 18px;
    }

    ::placeholder {
        font-size: 9px;
    }

    .PSVimageadd{
        height: 87px;
    }

    .PSVmargin{
        margin-top: 24px;
    }

    .PSVmargin2{
        margin-bottom: 24px;
    }

    .PSVbutton{
        padding: 15px 15px;
        width: 100%;
        display: block;
    }
}

@media(max-width:400px){
    .PSVkontenwrapper{
        padding: 60px 25px 60px 25px;
    }
}