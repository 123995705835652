.SBEPtrans-appear {
    opacity: 0;
    transform: translateX(50%);
}
.SBEPtrans-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1000ms;
}
.SBEPtrans-enter {
    opacity: 0;
}
.SBEPtrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.SBEPtrans-exit {
    opacity: 1; 
    transform: translateX(0);
}
.SBEPtrans-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 1000ms;
}

.SBEPkontainer{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20vh;
    padding-bottom: 10vh;
    font-family: Arial, Helvetica, sans-serif;
}

.SBEPkontainerkiri{
    flex: 65%;
    padding-left: 20px;
    padding-right: 20px;
}

.SBEPkontainerkanan{
    flex: 35%;
    padding-left: 20px;
    padding-right: 20px;
}

.SBEPkonten{
    margin-top: 18px;
}

.SBEPkonten2{
    margin-top: 64px;
}

.SBEPtittle{
    margin-left: 20px;
}

.SBEPkontenputih{
    background-color: white;
    padding: 45px;
    border-radius: 15px;
    height: calc(100% - 90px);
}

.SBEPstatus{
    padding: 15px;
    border: 2px solid #7F6200;
    border-radius: 15px;
    margin: 0;
    background-color: white;
    color: #7F6200;
    margin-left: auto;
    cursor: pointer;
    transition: all 0.5s;
}

.SBEPstatus:hover{
    color: white;
    background-color: #7F6200;
}

.SBEPmargin{
    margin-top: 32px;
}

.SBEPmargin2{
    margin-top: 46px;
}

.SBEPmargin3{
    margin-top: 20px;
}

.SBEPmargin4{
    margin-top: 12px;
}

.SBEPimage{
    height: 71px;
    width: 71px;
    border-radius: 15px;
    margin-right: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.SBEPicon{
    height: 24px;
    margin-right: 8px;
}

.SBEPline{
    height: 0.5px;
    background-color: #C4C4C4;
}

.SBEPbutton{
    width: -webkit-fill-available;
    width: -moz-available;
    height: 60px;
    border: none;
    outline: none;
    background-color: #FCCC24;
    cursor: pointer;
    border-radius: 15px;
}

.SBEPbutton:active{
    background-color: rgba(216, 175, 32, 1);
}

.SBEPbutton2{
    width: -webkit-fill-available;
    width: -moz-available;
    height: 60px;
    border: 1px solid #FCCC24;
    color:#FCCC24;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 15px;
}

@media (max-width: 1000px) {
    .SBEPkonten{
        flex-direction: column;
    }

    .SBEPkonten2{
        flex-direction: column;
        margin-top: 32px;
    }

    .SBEPkontainer{
        padding-top: 150px;
    }

    .SBEPmargin{
        margin-top: 28px;
    }
    
    .SBEPmargin2{
        margin-top: 32px;
    }
    
    .SBEPmargin3{
        margin-top: 16px;
    }

    .SBEPmargin4{
        margin-top: 10px;
    }

    .SBEPkontainerkanan{
        margin-bottom: 0px;
    }

    .SBEPkontainerkiri{
        margin-bottom: 32px;
    }

    .SBEPkontenputih{
        padding: 30px;
        height: calc(100% - 60px);
    }

    .SBEPstatus{
        padding: 10px;
        font-size: 11px!important;
    }
}