*:not(input.field){
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.kontainerList.PageEvent{
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    gap: 20px;
    margin: 50px !important;
}
.kontainerList.PageEvent .card{
    width: 100%;
    margin-right: 0;
}

.kontainerList.PageEventWide{
    margin: 50px;
}
.kontainerList.PageEventWide .card{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 400px;
}
.kontainerList.PageEventWide .card .kontainerImage{
    height: 100%;
}
.kontainerList.PageEventWide .card .kontainerDetail{
    height: 100%;
}
.detailEventWide{
    /* width: 70%; */
    width: 100%;
}
.sold-out{
    filter: grayscale(1);
    opacity: 0.5;
}
.card{
    flex-shrink: 0;
    border-radius: 15px;
    overflow: hidden;
    width: 375px;
    background-color: #1F1F1F;
    scroll-snap-align: start;
    margin-right: 20px;
    cursor:pointer;
    border: 2px solid #292929;
}
.card:last-child{
    margin-right: 0;
}
.card .kontainerImage{
    width: 100%;
    aspect-ratio: 1/1;
    /* height: 285px; */
    position: relative;
    overflow:hidden;
}
.card .kontainerImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .5s;
    transform:scale(1);
}
.card .kontainerImage:hover img{
    transform:scale(1.5);
}
.card .kontainerImage .sold-out-badge{
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 10px 15px  ;
    background-color: #FCCC24;
    color: #1F1F1F;
    border-radius: 10px;
    font-weight: 600;
    box-shadow:
        2.8px 2.8px 2.2px rgba(0, 0, 0, 0.005),
        6.7px 6.7px 5.3px rgba(0, 0, 0, 0.013),
        12.5px 12.5px 10px rgba(0, 0, 0, 0.022),
        22.3px 22.3px 17.9px rgba(0, 0, 0, 0.033),
        41.8px 41.8px 33.4px rgba(0, 0, 0, 0.047),
        100px 100px 80px rgba(0, 0, 0, 0.07)
    ;
}
.card .kontainerDetail{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 310px;
    padding: 20px;
}
.kontainerDetail .HTCjoinnow{
    text-align: right;
    font-size: 16px;
    color: #FCCC24;
    flex-shrink: 0;
}
.card .kontainerDetail .kontainerDetailHeader{
    margin-bottom: 20px;
}
.card .kontainerDetail .kontainerDetailHeader h1{
    font-size: 28px;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 10px;
}.card .kontainerDetail .kontainerDetailHeader p{
    font-size: 18px;
    font-weight: 600;
    color: #969696;
}

.card .kontainerDetail .kontainerSeats p{
    font-size: 16px;
    color: white;
}.red-seats{
    color: #FF7D7D !important;
}.from{
    margin-top: 5px;
}

.card .kontainerDetail .kontainerJam{
    display: flex;
    align-items: center;
    gap: 5px;
}.card .kontainerDetail .kontainerJam .detailFlex{
    display: flex;
    align-items: center;
    gap: 5px;
}.card .kontainerDetail .kontainerJam .detailFlex p{
    color: #616161;
    font-size: 16px;
    font-weight: 500;
}

.card .kontainerDetail .kontainerAlamat{
    margin-top: 10px;
    display: flex;
    align-items: start;
    gap: 5px;
}.card .kontainerDetail .kontainerAlamat p{
    color: #616161;
    font-size: 16px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
@media (max-width:1500px){
    .card:last-child{
        margin-right: 50px;
    }
}
@media (max-width:1132px){
    .kontainerList.PageEvent{
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    }
}
@media (max-width: 1000px){
    .detailEventWide{
        width: 90%;
    }
    .card .kontainerDetail .kontainerDetailHeader h1{
        font-size:24px;
    }
    .card .kontainerDetail .kontainerDetailHeader p{
        font-size: 16px;
    }
}
@media (max-width: 632px){
    .kontainerList.PageEvent{
        grid-template-columns: minmax(0,1fr);
    }
    .kontainerList.PageEventWide .card .kontainerDetail{
        gap: 25px;
    }
    .kontainerList.PageEvent .card{
        width: 100%;
    }
    .kontainerList.PageEventWide .card{
        flex-direction: column;
        height: 100%;
    }
}
@media (max-width:520px){
    .kontainerList.PageEvent{
        margin: 20px !important;
    }
    .card .kontainerImage .sold-out-badge{
        font-size: 3vw;
    }
}
@media (max-width: 460px){
    .kontainerList.PageEventWide .card .kontainerDetail{
        gap: 0;
    }
    .card .kontainerDetail .kontainerDetailHeader h1{
        font-size: 22px;
    }
    .card .kontainerDetail .kontainerDetailHeader p,
    .card .kontainerDetail .kontainerSeats p,
    .card .kontainerDetail .kontainerJam .detailFlex p,
    .card .kontainerDetail .kontainerAlamat p{
        font-size: 14px;
    }
    .card .kontainerDetail{
        height: 270px;
        padding: 15px;
    }
}