body{
    background-color:"#111111";
}
.hidden{
    display: none;
}
.show{
    display: block;
}

.btn__primary.heading,
.btn__secondary.heading{
    margin: 1.5rem auto 0 auto;
}
.btn__primary{
    font-family: Roboto, sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    width: -moz-fit-content;
    width: fit-content;
    display: block;
    padding: 1rem 2rem;
    margin: 1.5rem 0 0 0;
    border-radius: 0.5rem;
    color: #111;
    background-color: #dcb010;
    text-decoration: none;
    transition: all .5s;
    cursor: pointer;
}
.btn__secondary{
    font-family: Roboto, sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    width: -moz-fit-content;
    width: fit-content;
    display: block;
    padding: 1rem 2rem;
    margin: 1.5rem 0 0 0;
    outline: 1px solid #fff;
    outline-offset: -1px;
    border-radius: 0.5rem;
    color: #fff;
    text-decoration: none;
    transition: all .5s;
    cursor: pointer;
}
.btn__primary:hover{
    transform: scale(1.04);
}

.main-appear {
    opacity: 0;
}
.main-appear-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.main-enter {
    opacity: 0;
}
.main-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.main-exit {
    opacity: 1; 
}
.main-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}

.yellow{
    border: 1px solid #FCCC24!important;
}
.pointer{
    cursor: pointer;
}
.line__thru{
    text-decoration: underline;
}

.header{
    height: 100vh;
    width: 100%;
    /* background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%); */
    background-image:linear-gradient(to top, #111 1%, transparent, #1414144b), url('../../assets/header.png');
    opacity: 0.3;
    background-size:cover;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 0;
}

@font-face {
    font-family: Athelas;
    src: url('../../assets/Athelas-Regular.woff');
}


.popupkontainer{
    position: absolute;
    width: 100%;
    height: 130px;
    overflow-y: auto;
    background-color: white;
    border-radius: 5px;
    margin-top: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
    display: flex;
    border: 1px solid #f3f3f3;
    flex-direction: column;
}
.popupkontainerDate{
    position: absolute;
    width: 100%;
    background-color: white;
    margin-top: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
    display: flex;
    border: 1px solid #f3f3f3;
    flex-direction: column;
}

.HPpopupfont{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    cursor: pointer;
    padding: 15px 30px;
}
.HPpopupfont:hover{
    background-color: #f7f7f7;
}

.react-datepicker__month-container{
    width: 100%;
}

.react-datepicker{
    width: 100%;
    border: none!important;
    font-size:0.8rem;
    margin-bottom: 15px;
    margin-top: 20px;
}

.react-datepicker__header {
    border:none!important;
    background-color: white!important;
}

.react-datepicker__current-month{
    color:grey
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: rgba(255, 0, 0, 0.5);
    color: white;
}

.calendarkontainer::-webkit-scrollbar{
    width:0;
    height:0;
}

.button2:active{
    background-color: rgba(216, 175, 32, 1);
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #FCCC24!important;
    border-radius: 50%!important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #FCCC24!important;
    border-radius: 50%!important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: white!important;
    color: black;
}

.miniMoto{
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 16px;
}

.button1{
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 7vh;
    padding: 15px 50px 15px 50px;
    background-color: white!important;
    font-size: 16px;
    border: none;
    border-radius: 14px;
    cursor: pointer;
    transition: all 0.3s;
}

.button1:hover{
    background-color: #FCCC24!important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.kontenHeader{
    /* max-width:1440px; */
    max-width:1100px;
    margin:auto;
    position: relative;
    display: flex;
    height: 100vh;
}

.mottoKontainer{
    flex: 50%;
    height: 100%;
    align-items: center;
    position: relative;
}

.mottoWrapper{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-40%);
    width: 80%;
}

.motto{
    max-width: 400px;
    margin:0;
    font-size: 60px;
    margin-bottom: 10px;
    color: #FFFFFF;
    font-family: Athelas;
    text-align: left;
}

.formKontainer{
    flex: 50%;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
}

.formWrapper{
    position: absolute;
    border-radius: 15px;
    width: 55%;
    background-color: white;
    padding: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
}

.inputWrapper{
    display: flex;
    cursor: pointer;
    max-width: 100%;
    border: solid 1px #C4C4C4;
    align-items: center;
    margin-top: 15px;
    padding: 10px;
    border-radius: 14px;
    align-items: center;
}

.inputfind{
    box-sizing:border-box;
    display: flex;
    cursor: pointer;
    margin: 10px 15px 10px 15px;
    border: 1px solid #C4C4C4;
    padding: 10px;
    border-radius: 16px;
    align-items: center;
}

.iconsearch{
    height: calc(1vh + 0.6vw);
    margin-right: 10px;
}

.fieldlocation{
    width: 100%;
    border: none;
    outline: none;
    font-size: 10px;
}

.fieldlocation:focus{
    border: none;
    outline: none;
}

.questionWrapper{
    width: 100%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.question{
    font-size: 0.9vw;
    margin-bottom: 8px;
    color: #6F6F6F;
}

.iconkiri{
    height: 16px;
    margin-top:1px;
}

.iconkanan{
    height: 16px;
    margin-left: auto;
    margin-top: 1px;
}

.inputquestion{
    font-size: 12px;
    margin: 0;
    color: #6F6F6F;
    margin-left: 12px;
}

.button2{
    margin-bottom: 20px;
    background-color: #FCCC24;
    transition: background-color 0.2s;
    border: none;
    font-weight: 600;
    width: 100%;
    margin-left: auto;
    display: flex;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    padding:12px;
    border-radius: 12px;
    cursor: pointer;
}

.kontainercardWrapper *{
    box-sizing: border-box;
    margin: 0;
}
.kontainercardWrapper{
    padding: 100px 0;
    max-width: 1100px;
    /* max-width: 1440px; */
    margin: auto;
}
.kontainercardWrapper.detailChef{
    padding: 100px 0 0 0 !important;
}.kontainercardWrapper.detailChef:last-of-type{
    padding: 50px 0 100px 0 !important;
}
.kontainercardWrapper .kontainerHeader{
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}.kontainercardWrapper .kontainerHeader h1{
    color: #d9d9d9;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 36px;
    font-weight:600;
}.kontainercardWrapper .kontainerHeader p{
    color: #969696;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.kontainercardWrapper .kontainerList{
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    margin: 50px 0 50px 50px;
    flex-wrap: nowrap;
}
.kontainercardWrapper .kontainerList.menuChef{
    margin: 50px;
    flex-wrap: wrap !important;
    gap: 20px;
}
.kontainerList::-webkit-scrollbar{
    width:0;
    height:0;
    scrollbar-width: none;
}

/* Taplak Friends  */
@keyframes sliderLoop{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}

/* Version 2  */
.TaplakFriends__v2{
    padding: 100px 0;
    max-width: 1100px;
    margin: 0 auto;
    overflow: hidden;
}
.TaplakFriends__v2 h1{
    font-size: 2.3rem;
    color: #d9d9d9;
    margin: 0 auto;
    max-width: 400px;
    width: calc(90% - 1rem);
    text-align: center;
    margin-bottom: 1rem;
}
.TaplakFriends__v2 .TaplakFriends__wrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 50px;
    gap: 5rem;
    justify-content: center;
    box-sizing: border-box;
}
.TaplakFriends__logo{
    width: 15%;
    height: auto;
    cursor: pointer;
}
.TaplakFriends__logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: .4;
    transition: all .5s;
}
.TaplakFriends__logo:hover img{
    transform: scale(1.1);
    opacity: 1;
}

/* Version 1  */
.TaplakFriends{
    max-width:1100px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
}
.TaplakFriends::after{
    content: "";
    width: 6rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #111111, #1111114e);
    z-index: 3;
}
.TaplakFriends::before{
    content: "";
    width: 6rem;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(to left, #111111, #1111114e);
    z-index: 3;
}
.TaplakFriends:hover .TaplakFriends__slider{
    animation-play-state: paused;
}
.TaplakFriends__slider{
    position: relative;
    z-index: 2;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    animation: 25s sliderLoop infinite linear;
}
.TaplakFriends__slider .logoFriends:hover img{
    transform: scale(1.1);
}
.TaplakFriends__slider .logoFriends img{
    cursor: pointer;
    width: 140px;
    margin: 0 50px;
    transition: all .5s;
}

/* Galleries Pop up  */
.taplakGalleries__popup{
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10001;
    position: fixed;

    height: fit-content;
    max-height: calc(100vh - 2rem);
    max-width: 500px;
    width: calc(100% - 2rem);

    border-radius: 1rem;
    overflow: hidden;
    background-color: #161616;
}

/* Taplak popup head part  */
.taplakGalleries__popup .head__part{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    padding: 1rem;
}
.taplakGalleries__popup .head__part i{
    font-size: 1.5rem;
    color: #6A6A6A;
    cursor: pointer;
}

/* Head profile  */
.taplakGalleries__popup .head__part .head__profile{
    display: flex;
    align-items: center;
    gap: .5rem;
}
.head__part .head__profile .head__images{
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 100%;
    border: 1px solid #1C1C1C;
}
.head__part .head__profile .head__images img{
    width: 100%;
    object-fit: cover;
}

/* Head text  */
.head__part .head__profile .head__texts h1{
    font-size: 1.3rem;
    line-height: 32px;
    margin: 0;
    color: #d9d9d9;
}
.head__part .head__profile .head__texts .texts__event{
    display: flex;
    gap: .5rem;
    align-items: center;
}
.head__part .head__profile .head__texts .texts__event i,
.head__part .head__profile .head__texts .texts__event p{
    font-size: 1rem;
    line-height: 28px;
    color: #6A6A6A;
    margin: 0;
}

/* Taplak popup body parts  */
.taplakGalleries__popup .body__part{
    position: relative;
    width: 100%;
    max-width: 500px;
    /* aspect-ratio: 16/9; */
    box-sizing: border-box;
    overflow: hidden;
    background-color: #111;
}
.taplakGalleries__popup .body__part::after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: #111;
    opacity: 0;
    transition: all .5s;
}
.taplakGalleries__popup .body__part .actions{
    display: flex;
    gap: 1rem;
    position: absolute;
    left: 50%;
    top: 150%;
    z-index: 6;
    transform: translate(-50%, -50%);
    transition: all .5s;
    opacity: 0;
}
/* .taplakGalleries__popup .body__part:hover::after{
    opacity: .3;
}
.taplakGalleries__popup .body__part:hover .actions{
    top: 50%;
    opacity: 1;
} */
.taplakGalleries__popup .body__part .actions a{
    width: 48px;
    height: 48px;
    border-radius: 100%;
    display: grid;
    place-content: center;
    background-color: #111;
    color: #d9d9d9;
    font-size: 1rem;
    line-height: 28px;
}
.taplakGalleries__popup .body__part img{
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* Taplak popup footer parts  */
.taplakGalleries__popup .footer__Part{
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    padding: 1rem;
    background-color: #161616;
    display: flex;
    align-items: center;
    place-content: center;
    justify-content: space-between;
}
.taplakGalleries__popup .footer__Part p{
    font-size: 1rem;
    line-height: 28px;
    color: #6A6A6A;
    margin: 0;
}
.taplakGalleries__popup .footer__Part .footer__social{
    display: flex;
    gap: .5rem;
}
.taplakGalleries__popup .footer__Part .footer__social a{
    cursor: pointer;
}
.taplakGalleries__popup .footer__Part .footer__social a i{
    font-size: 2rem;
    color: #6A6A6A;
}

/* Galerries  */
.TaplakGalleries{
    width: 100%;
    background: linear-gradient(to bottom, #161616, #111111);
}
.TaplakGalleries .section__wrapper{
    box-sizing: border-box;
    max-width: 1100px;
    margin: 0 auto;
    padding: 50px;
}

/* Galleries Footer Part  */
.TaplakGalleries__footer{
    padding-top: 3rem;
    width: 100%;
    display: grid;
    place-content: center;
}
.TaplakGalleries__footer a{
    max-width: 200px;
    width: calc(100% - 1rem);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 28px;
    color: #6A6A6A;
    background-color: #1F1F1F;
    padding: .5rem 1rem;
    border-radius: .5rem;
    border: 1px solid #6A6A6A;
    cursor: pointer;
}

/* Galleries body part  */
.TaplakGalleries__body{
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    grid-template-rows: 200px;
    grid-auto-flow: dense;
    gap: 1rem;
    /* columns: 3; */
    margin-top: 3rem;
}
.TaplakGalleries__body .TaplakGalleries__img.vertical{
    grid-row: 2 span;
}
.TaplakGalleries__body .TaplakGalleries__img.horizontal{
    grid-column: 1 span;
}
.TaplakGalleries__body .TaplakGalleries__img.square{
    grid-column: 1 span;
    grid-row: 1 span;
}
.TaplakGalleries__body .TaplakGalleries__img{
    max-width: 100%;
    border-radius: .5rem;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}
.TaplakGalleries__body .TaplakGalleries__img img{
    height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    display: block;
}
.TaplakGalleries__body .TaplakGalleries__img::after{
    pointer-events: none;
    width: 100%;
    height: 100%;
    content: "";
    transition: all .5s;
    position: absolute;
    opacity: 0;
    background-color: #111;
    top: 0;
    left: 0;
}
.TaplakGalleries__body .TaplakGalleries__img:hover::after{
    opacity: .5;
}

/* Galleries head text part  */
.TaplakGalleries .TaplakGalleries__head .text{
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
}
.TaplakGalleries .TaplakGalleries__head .text h1{
    font-size: 2.3rem;
    color: #d9d9d9;
    margin: 0 0 1rem 0;
}
.TaplakGalleries .TaplakGalleries__head .text p{
    font-size: 1rem;
    line-height: 28px;
    color: #6A6A6A;
}
/* Galleries head tabs part  */
.TaplakGalleries__head .tabs{
    margin-top: 3rem;
    display: flex;
    border-bottom: 1px solid #2F2F2F;
}
.TaplakGalleries__head .tabs .tabs__list{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    width: 100%;
    padding-bottom: 1rem;
    transition: all .5s;
}
.TaplakGalleries__head .tabs .tabs__list::after{
    content: "";
    width: 0;
    position: absolute;
    left: 50%;
    bottom: -1px;
    width: 0;
    height: 1px;
    transition: all .5s;
}
.TaplakGalleries__head .tabs .tabs__list img{
    filter: grayscale(1);
    opacity: .1;
    width: 36px;
    transition: all .5s;
}
.TaplakGalleries__head .tabs .tabs__list p{
    font-weight: 600;
    color: #2F2F2F;
    font-size: 1rem;
    line-height: 28px;
    margin: 0;
    transition: all .5s;
}

/* Active state Galleries  */
.TaplakGalleries__head .tabs .tabs__list.active img{
    filter: none;
    opacity: 1;
}
.TaplakGalleries__head .tabs .tabs__list.active::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background-color: #dcb010;
}
.TaplakGalleries__head .tabs .tabs__list.active p{
    color: #dcb010;
}

/* Hover state Galleries  */
.TaplakGalleries__head .tabs .tabs__list:hover img{
    filter: none;
    opacity: 1;
}
.TaplakGalleries__head .tabs .tabs__list:hover::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background-color: #dcb010;
}
.TaplakGalleries__head .tabs .tabs__list:hover p{
    color: #dcb010;
}

/* About us  */
.AboutUsWrapper{
    box-sizing: border-box;
    padding: 100px 50px 100px 50px;
    /* max-width:1440px; */
    max-width:1100px;
    margin: auto;
}
.AboutUsContent{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 50px 50px 0 50px;
}
.AboutUsContent .AboutUsTextContent{
    position: relative;
    z-index: 10;
}
.AboutUsContent .AboutUsBackground{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75%;
    border-radius: 0 0 1rem 1rem;
    background: linear-gradient(to bottom, #111111, #1F1F1F );
}
.AboutUsGridImage{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}
.AboutUsGridImage .AboutUsImage{
    border-radius: 0.8rem;
    aspect-ratio: 316/460;
    overflow: hidden;
}
.AboutUsGridImage .AboutUsImage.middle{
    aspect-ratio: 400/360;
    place-self: center;
}
.AboutUsImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.AboutUsHead{
    padding: 0 50px;
    margin-bottom: 30px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}
.AboutUsHead h1{
    font-size: 2.3rem;
    color: #d9d9d9;
    margin: 0 0 1rem 0;
}
.AboutUsHead p{
    width: calc(100% - 1rem);
    font-size: 1rem;
    line-height: 28px;
    color: #6A6A6A;
    margin: auto;
}
.AboutUsHead .AboutUsButton{
    cursor: pointer;
    width: fit-content;
    display: block;
    padding: 1rem 2rem;
    margin: 1.5rem auto 0 auto;
    border-radius: 0.5rem;
    background-color: #FCCC24;
    color: #1F1F1F;
    font-weight: 600;
    font-size: 0.9rem;
    text-decoration: none;
    transform: scale(1);
    transition: all .3s;
}.AboutUsHead .AboutUsButton:hover{
    transform: scale(1.05);
}.AboutUsHead .AboutUsButton:focus{
    transform: scale(0.98);
    background-color: #dcb010;
}

/* Line separator  */
.line-separator{
    box-sizing: border-box;
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 0 50px;
    /* max-width: 1440px; */
    max-width: 1100px;
    margin: auto;
    position: relative
}
.line-separator.list{
    padding: 0;
}
.line-separator .line-img{
    position: relative;
    z-index: 10;
    width: fit-content;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    border: 1px solid #202020;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.line-separator .line-img img{
    width: 60%;
}
.line-separator::after,
.line-separator::before{
    content: "";
    width: 100%;
    height: 1px;
    background-color: #202020;
}

/* how it work  */
.HowItWrapper{
    overflow: hidden;
    box-sizing: border-box;
    padding: 100px 50px;
    max-width:1100px;
    /* max-width:1440px; */
    margin:auto;
}
.HowItHeading{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}
.HowItHeading h1{
    font-size: 2.3rem;
    color: #d9d9d9;
    margin: 0 0 1rem 0;
}
.HowItHeading p{
    width: calc(50% - 5px);
    font-size: 1.3rem;
    font-weight: 500;
    color: #6A6A6A;
    margin: auto;
}
.HowItButton{
    width: fit-content;
    display: block;
    padding: 1rem 2rem;
    margin: 1.5rem auto 0 auto;
    border-radius: 0.5rem;
    background-color: #FCCC24;
    color: #1F1F1F;
    font-weight: 600;
    font-size: 0.9rem;
    text-decoration: none;
    transform: scale(1);
    transition: all .3s;
    cursor: pointer;
}.HowItButton:hover{
    transform: scale(1.05);
}.HowItButton:focus{
    transform: scale(0.98);
    background-color: #dcb010;
}
.HowItGrids{
    padding: 0 50px;
    box-sizing: border-box;
    margin-top: 3rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
}
.gridPointsImage{
    width: 100%;
    aspect-ratio: 1/1;
    /* height: 22rem; */
    border-radius: 0.8rem 0.8rem 0.8rem 0;
    overflow: hidden;
    position: relative;
}
.gridPointsImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.gridPointsIcon{
    position: absolute;
    background-color: #1F1F1F;
    border-radius: 0 1rem 0 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
}
.gridPointsIcon img{
    display: block;
    height: 2.5rem;
    width: 2.5rem;
}
.gridPointsText{
    font-family: Arial, Helvetica, sans-serif;
}
.gridPointsText h1{
    font-size: 1.1rem;
    color: #d9d9d9;
    margin: 1rem 0 0.5rem 0;
}
.gridPoints p{
    font-size: 1rem;
    line-height: 28px;
    color: #6A6A6A;
    margin: 0 0 0.5rem 0;
}

/* Frequently Asked  */
.faqWrapper{
    box-sizing: border-box;
    max-width: 1100px;
    /* max-width: 1440px; */
    padding: 100px 100px;
    margin: auto;
}
.faqGridMain{
    display: grid;
    grid-template-columns: 1.6fr 2fr;
    gap: 1rem;
}
.faqGridImg{
    width: 100%;
    height: 20rem;
    border-radius: 5rem 0.8rem 0.8rem 0;
    overflow: hidden;
    position: relative;
}
.faqGridImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.faqGridIcon{
    position: absolute;
    background-color: #1F1F1F;
    border-radius: 0 1rem 0 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
}

.faqInfos{
    width: 100%;
    border-radius: 0.8rem;
    box-sizing: border-box;
    padding: 25px;
    background-color: #1F1F1F;
    font-family: Arial, Helvetica, sans-serif;
}
.faqInfos h1{
    font-size: 1.5rem;
    color: #d9d9d9;
    margin: 0 0 0.5rem 0;
}
.faqInfos p{
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.3rem;
    color: #767676;
    margin: 0;
}
.faqInfosFlex{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
}
.faqHeadGrids{
    height: fit-content;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
}
.faqCollection{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.faqContents{
    width: 100%;
    height: fit-content;
    overflow: hidden;
}

.faqContents .faqHead{
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.3rem 1rem;
    background-color: #1F1F1F;
    border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
    cursor: pointer;
    position: relative;
    z-index: 999;
}
.faqContents .faqHead h1{
    margin: 0;
    color: #d9d9d9;
    font-size: 1.3rem;
}
.faqContents .faqHead img{
    width: 1.7rem;
    transition: all .5s;
}

.faqContents .faqBody{
    font-family: Arial, Helvetica, sans-serif;
    background-color: #181818;
    border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
    padding: 0 1rem;
    height: 0;
    opacity: 0;
    transform: translateY(-10px);
    overflow: hidden;
    transition: all .5s;
}
.faqContents .faqBody p,
.faqContents .faqBody span,
.faqContents .faqBody a{
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 1rem !important;
    font-weight: 500;
    line-height: 1.5rem;
    color: #767676 !important;
    margin: 0;
}

.faqContents.active .faqHead{
    border-radius: 0.8rem 0.8rem 0 0 !important;
}
.faqContents.active .faqBody{
    padding: 1.3rem 1rem;
    opacity: 1;
    height: fit-content;
    transform: translateY(0);
    border-radius: 0 0 0.8rem 0.8rem !important;
}
.faqContents.active .faqHead img{
    transform: rotate(180deg);
}

/* Header  */
.headerWrapper{
    box-sizing: border-box;
    position: relative;
    z-index: 10;
    max-width: 1000px;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
}
/* .headerWrapper{
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    max-width: 1100px;
    margin: auto;
    padding: 0 100px;
    height: 100vh;
    gap: 3rem;
    box-sizing: border-box;
} */
/* .headerWrapper .headerLeft{
    place-self: center;
    text-align: start;
} */
.headerWrapper .headerLeft h1{
    margin: 0;
    font-family: Athelas;
    font-size: 5.5rem;
    font-weight: 400;
    color: #fff;
}
.headerWrapper .headerLeft p{
    font-family: Roboto, sans-serif;
    width: calc(95% - 15px);
    margin: 2rem 0;
    font-size: 1rem;
    line-height: 1.8rem;
    font-weight: 400;
    color: #bfbdbd;
}
 .headerButtonWrap{
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
}
.headerButtonWrap.desktop{
    width: fit-content;
    margin: 0 auto;
}
 .headerButton.primary{
    border: none;
    background-color: #dcb010;
    color: #1F1F1F;
    margin-right: 1rem;
}
 .headerButton.primary::after{
    border: 1px solid #dcb010;
}
.btn{
    position: relative;
    font-family: Roboto, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    width: 270px;
    padding: 1rem 1rem;
    font-weight: 800;
    font-size: 0.83rem;
    cursor: pointer;
    border-radius: .5rem;
}
.btn i{
    font-size: 1.5rem;
}
.btn::after{
    border-radius: .5rem;
    position: absolute;
    content: "";
    width: 100%;
    height: 60%;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: all .73s;
    z-index: -5;
}
.btn:hover::after{
    left: 3px;
    bottom: -5px;
    opacity: 1;
}
.btn.primary::after{
    border: 1px solid #FCCC24;
}
.btn.secondary::after{
    border: 1px solid #fff;
}
.btn.primary{
    background: #FCCC24;
    color: #111;
    transition: all .73s;
}
.btn.primary:hover{
    background: #d1a609;
}
.btn.secondary{
    background: #fff;
    color: #111;
    width: 36px;
    transition: all .73s;
    overflow: hidden;
}
.btn.secondary:hover{
    background: #d6d6d6;
}
.btn span{
    white-space: nowrap;
}
.btn.secondary:hover{
    width: 270px;
}
.btn.secondary:hover span{
    display: block;
}
.btn.secondary span{
    display: none;
}
.headerButton{
    font-family: Roboto, sans-serif;
    width: fit-content;
    display: block;
    padding: 1rem 2rem;
    margin: 1.5rem 0 0 0;
    border-radius: 0.5rem;
    /* background-color: #fff; */
    border: 1px solid #fff;
    color: #fff;
    font-weight: 600;
    font-size: 0.9rem;
    text-decoration: none;
    transform: scale(1);
    transition: all .3s;
    cursor: pointer;
    position: relative;
} .headerButton:hover{
    transform: scale(1.05);
} .headerButton:focus{
    transform: scale(0.98);
    background-color: #dcb010;
    color: #1F1F1F;
}
 .headerButton::after{
    transition: all .3s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #ffffff9a;
    content: "";
    top: 0;
    bottom: 0;
    opacity: 0;
    right: 0;
    border-radius: 0.5rem;
}
 .headerButton:hover::after{
    opacity: 1;
    top: 0.5rem;
    right: -0.3rem;
}

.headerWrapper .headerRight{
    background-color: #fff;
    border-radius: 0.8rem;
    padding: 1rem;
    width: 75%;
    place-self: end;
    box-sizing: border-box;
}

/* 
.eventheader{
    margin: 0;
    margin-left: 20px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 45px;
    font-weight:600;
}

.eventsubheadline{
    margin:0;
    color: #AAAAAA;
    font-family: Arial, Helvetica, sans-serif;
    font-size: calc(1vh + 0.5vw);
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
} */
/* 
.kontainerList{
    display:flex;
    overflow: scroll;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    scrollbar-width: none;
    gap:25px;
}

.kontainerList::-webkit-scrollbar{
    width:0;
    height:0;
    scrollbar-width: none;
}

.kontainerList> .kontainerCard{
    flex: 33.3% 0;
    margin-top: 36px;
}

.kontainerList>.kontainerCard> .card{
    width: 100%;
    margin:0;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #1F1F1F;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
}

.kontainerList>.kontainerCard> .card>.gambarEvent{
    height: 160px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 15px 15px 0px 0px;
    transition: background-size 0.5s;
    margin: 0;
}

.kontainerList>.kontainerCard> .card>.gambarEvent:hover{
    background-size: 120% 120%;
}

.kontainerList>.kontainerCard> .card>.kontainerDetail{
    flex: 50%;
    padding: 0;
    margin-left: 20px;
    width: -webkit-fill-available;
    width: -moz-available;
    margin-top: 10px;
    margin-right: 20px;
    overflow-wrap: break-word;
}

.kontainerList>.kontainerCard> .card>.kontainerDetail>.judulEvent{
    color: white;
    font-size: 36px;
    margin: 0;
    width: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.kontainerList>.kontainerCard> .card>.kontainerDetail>.foodstyle{
    font-size: 14px;
    width: inherit;
    color: #AAAAAA;
    margin: 4px 0px 0px 0px;
}

.kontainerList>.kontainerCard> .card>.kontainerDetail>.seatleft{
    font-size: 14px;
    color: white;
    font-weight: 600;
    margin: 32px 0px 0px 0px;
}

.kontainerList>.kontainerCard> .card>.kontainerDetail>.seatleftred{
    font-size: 14px;
    color: #FF7D7D;
    font-weight: 600;
    margin: 32px 0px 0px 0px;
}


.kontainerList>.kontainerCard> .card>.kontainerDetail>.totalseat{
    font-size: 14px;
    color: white;
    margin: 4px 0px 0px 0px;
}


.kontainerList>.kontainerCard> .card>.kontainerDetail>.kontainerjam{
    font-size: 14px;
    color: white;
    margin-top: 32px;
    display: flex;
}

.kontainerList>.kontainerCard> .card>.kontainerDetail>.kontaineralamat{
    font-size: 14px;
    color: white;
    margin-top: 8px;
    display: flex;
}

.kontainerList>.kontainerCard> .card>.kontainerDetail>.kontainerjam>.iconcard{
    height: 16px;
    margin-right: 15px;
}

.kontainerList>.kontainerCard> .card>.kontainerDetail>.kontaineralamat>.iconcard{
    height: 16px;
    margin-right: 15px;
} */
/* Card animation  */
/* 
.visible-flex{
    display: flex !important;
}
.visible-grid{
    display: grid !important;
}
.visible{
    display: block !important;
}
.popup-byCategory{
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    z-index: 500;
}
.popup-byCategory .dark-overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #151515fa;
    z-index: 500;
    display: none;
}
.popup-byCategory.visible-flex{
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.popup-byCategory.visible-flex .dark-overlay,
.popup-byCategory.visible-flex .byCategoryWrapper{
    display: block !important;
}
.popup-byCategory .byCategoryWrapper{
    width: fit-content;
    z-index: 501;
    margin: auto;
    display: none;
}
.popup-byCategory .byCategoryWrapper .byCategoryTitle{
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}
.byCategoryWrapper .byCategoryTitle h1{
    font-size: 2.3rem;
    color: #d9d9d9;
    margin: 0 0 0.5rem 0;
}
.byCategoryWrapper .byCategoryTitle p{
    width: calc(60% - 5px);
    font-size: 1rem;
    font-weight: 500;
    color: #6A6A6A;
    margin: 0 auto 2rem auto;
}
.byCategoryWrapper .byCategoryContent{
    max-width: 650px;
    display: grid;
    grid-template-columns: 1fr 0.2fr 1fr;
    gap: 15px;
    margin: 1rem auto 0 auto;
}
.byCategoryWrapper .byCategoryContent .CardFlipWrap{
    position: relative;
    width: 100%;
    height: 430px;
    perspective: 1000px;
    cursor: pointer;
}
.byCategoryWrapper .byCategoryContent .CardFlipWrap .flipper-hug{
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}
.byCategoryWrapper .byCategoryContent .CardFlipWrap:hover .flipper-hug{
    transform: rotateY(180deg);
}
.byCategoryContent .CardFlipWrap .flipper-hug .front,
.byCategoryContent .CardFlipWrap .flipper-hug .back
{
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.byCategoryContent .CardFlipWrap .flipper-hug .back{
    transform: rotateY(180deg);
}
.CardFlipWrap .flipper-hug .front .byCategoryCard.chefs,
.CardFlipWrap .flipper-hug .back .byCategoryCard.chefs
{
    box-sizing: border-box;
    border-radius: 0.6rem;
    border: 2px solid #4D4D4D;
    overflow: hidden;
}
.CardFlipWrap .flipper-hug .front .byCategoryCard.menus,
.CardFlipWrap .flipper-hug .back .byCategoryCard.menus
{
    box-sizing: border-box;
    border-radius: 0.6rem;
    border: 2px solid #FFC700;
    overflow: hidden;
}
.back .byCategoryCard.menus{
    width: 100%;
    height: 100%;

    .img-1{
        position: absolute;
        top: 0;
        right: 0;
        left: auto;
        z-index: 5;
        bottom: auto;
    }
    .img-2{
        z-index: 10;
        position: absolute;
        top: -100px;
        right: -70px;
        left: auto;
        bottom: auto;
    }
    .img-3{
        z-index: 1;
        position: absolute;
        top: 20%;
        right: auto;
        left: -270px;
        bottom: auto;
    }
    .byCategoryCard-text{
        position: absolute;
        bottom: 2rem;
        left: 1.5rem;
        z-index: 20;
        font-family: Arial, Helvetica, sans-serif;
    }
    .byCategoryCard-text .btn-byCategoryCard{
        width: fit-content;
        font-family: Roboto, sans-serif;
        font-weight: 600;
        font-size: 0.8rem;
        border-radius: 20px;
        padding: 0.5rem 2rem;
        background-color: #151515;
        color: #fff;
    }
    .byCategoryCard-text h1{
        font-size: 1.3rem;
        font-weight: 800;
        margin: 0 0 0.5rem 0;
        color: #151515;
    }
    .byCategoryCard-text p{
        font-family: Roboto, sans-serif;
        margin: 0 0 1rem 0;
        width: calc(80% - 20px);
        font-size: 0.8rem;
        color: #343434;
    }
}
.front .byCategoryCard.menus{
    width: 100%;
    height: 100%;
    .img-1{
        position: absolute;
        top: -130px;
        left: -100px;
        bottom: auto;
        z-index: 10;
        bottom: auto;
    }
    .img-2{
        z-index: 5;
        position: absolute;
        top: 0;
        right: 0px;
        left: auto;
        bottom: auto;
    }
    .img-3{
        z-index: 5;
        position: absolute;
        bottom: -50px;
        left: -250px;
        right: auto;
        top: auto;
    }
    .img-4{
        z-index: 25;
        position: absolute;
        inset: 50%;
        transform: translate(-50%, -50%);
    }
}
.menus{
    position: relative;
    background: linear-gradient(to top left, #FCA624,#FCA624 , #FCCC24) !important;
}
.menus::after{
    z-index: 15;
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top right, #FCCC24, #fccd24c6, transparent);
}
.back .byCategoryCard.chefs{
    width: 100%;
    height: 100%;
    .img-1{
        position: absolute;
        bottom: 0;
        right: 0;
        left: auto;
        z-index: 10;
        top: auto;
    }
    .img-2{
        z-index: 5;
        position: absolute;
        top: -200px;
        right: -150px;
        left: auto;
        bottom: auto;
    }
    .img-3{
        z-index: 5;
        position: absolute;
        bottom: -50px;
        right: auto;
        left: -270px;
        top: auto;
    }
    .byCategoryCard-text{
        position: absolute;
        bottom: 2rem;
        left: 1.5rem;
        z-index: 20;
        font-family: Arial, Helvetica, sans-serif;
    }
    .byCategoryCard-text .btn-byCategoryCard{
        width: fit-content;
        font-family: Roboto, sans-serif;
        font-weight: 600;
        font-size: 0.8rem;
        border-radius: 20px;
        padding: 0.5rem 2rem;
        background-color: #FFFFFF;
        color: #151515;
    }
    .byCategoryCard-text h1{
        font-size: 1.3rem;
        font-weight: 800;
        margin: 0 0 0.5rem 0;
        color: #fff;
    }
    .byCategoryCard-text p{
        font-family: Roboto, sans-serif;
        margin: 0 0 1rem 0;
        width: calc(80% - 20px);
        font-size: 0.8rem;
        color: #A9A9A9;
    }
}
.front .byCategoryCard.chefs{
    width: 100%;
    height: 100%;
    .img-1{
        position: absolute;
        bottom: 0;
        right: 0;
        left: auto;
        z-index: 10;
        top: auto;
    }
    .img-2{
        z-index: 5;
        position: absolute;
        top: -200px;
        left: -250px;
        right: auto;
        bottom: auto;
    }
    .img-3{
        z-index: 5;
        position: absolute;
        bottom: -50px;
        right: -200px;
        left: auto;
        top: auto;
    }
    .img-4{
        z-index: 25;
        position: absolute;
        inset: 50%;
        transform: translate(-50%, -50%);
    }
}
.chefs{
    position: relative;
    background: linear-gradient(to top left, #3C3C3C,#3C3C3C , #3C3C3C) !important;
}
.chefs::after{
    z-index: 15;
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(35deg, #272727,#272727d5, transparent);
} */

.visible-flex{
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}
.visible-grid{
    display: -ms-grid !important;
    display: grid !important;
}
.visible{
    display: block !important;
}
.popup-byCategory{
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    z-index: 500;
}
.popup-byCategory .dark-overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #151515fa;
    z-index: 500;
    display: none;
}
.popup-byCategory.visible-flex{
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.popup-byCategory.visible-flex .dark-overlay,
.popup-byCategory.visible-flex .byCategoryWrapper{
    display: block !important;
}
.popup-byCategory .byCategoryWrapper{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 501;
    margin: auto;
    display: none;
}
.popup-byCategory .byCategoryWrapper .byCategoryTitle{
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}
.byCategoryWrapper .byCategoryTitle h1{
    font-size: 2.3rem;
    color: #d9d9d9;
    margin: 0 0 0.5rem 0;
}
.byCategoryWrapper .byCategoryTitle p{
    width: calc(60% - 5px);
    font-size: 1rem;
    font-weight: 500;
    color: #6A6A6A;
    margin: 0 auto 2rem auto;
}
.byCategoryWrapper .byCategoryContent{
    max-width: 650px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 15px 0.2fr 15px 1fr;
    grid-template-columns: 1fr 0.2fr 1fr;
    gap: 15px;
    margin: 1rem auto 0 auto;
}
@keyframes SwipeUpIn{
    from{
        transform: translateY(120%);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}
.SwipeUpIn{
    animation: SwipeUpIn .5s;
    animation-fill-mode: forwards;
}
.byCategoryWrapper .byCategoryContent .CardFlipWrap{
    transform: translateY(120%);
    opacity: 0;
    position: relative;
    width: 100%;
    height: 430px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    cursor: pointer;
}
.byCategoryWrapper .byCategoryContent .CardFlipWrap .flipper-hug{
    width: 100%;
    height: 100%;
    -webkit-transition: -webkit-transform 0.6s;
    transition: -webkit-transform 0.6s;
    -o-transition: transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}
.byCategoryWrapper .byCategoryContent .CardFlipWrap:hover .flipper-hug{
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}
.byCategoryContent .CardFlipWrap .flipper-hug .front,
.byCategoryContent .CardFlipWrap .flipper-hug .back
{
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.byCategoryContent .CardFlipWrap .flipper-hug .back{
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}
.CardFlipWrap .flipper-hug .front .byCategoryCard.chefs,
.CardFlipWrap .flipper-hug .back .byCategoryCard.chefs
{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 0.6rem;
    border: 2px solid #4D4D4D;
    overflow: hidden;
}
.CardFlipWrap .flipper-hug .front .byCategoryCard.menus,
.CardFlipWrap .flipper-hug .back .byCategoryCard.menus
{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 0.6rem;
    border: 2px solid #FFC700;
    overflow: hidden;
}

/* Menus part  */
.back .byCategoryCard.menus{
    width: 100%;
    height: 100%;
}
.back .byCategoryCard.menus .img-1{
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    z-index: 5;
    bottom: auto;
}
.back .byCategoryCard.menus .img-2{
    z-index: 10;
    position: absolute;
    top: -100px;
    right: -70px;
    left: auto;
    bottom: auto;
}
.back .byCategoryCard.menus .img-3{
    z-index: 1;
    position: absolute;
    top: 20%;
    right: auto;
    left: -270px;
    bottom: auto;
}
.back .byCategoryCard.menus .byCategoryCard-text{
    position: absolute;
    bottom: 2rem;
    left: 1.5rem;
    z-index: 20;
    font-family: Arial, Helvetica, sans-serif;
}
.back .byCategoryCard.menus .byCategoryCard-text .btn-byCategoryCard{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 20px;
    padding: 0.5rem 2rem;
    background-color: #151515;
    color: #fff;
}
.back .byCategoryCard.menus .byCategoryCard-text h1{
    font-size: 1.3rem;
    font-weight: 800;
    margin: 0 0 0.5rem 0;
    color: #151515;
}
.back .byCategoryCard.menus .byCategoryCard-text p{
    font-family: Roboto, sans-serif;
    margin: 0 0 1rem 0;
    width: calc(80% - 20px);
    font-size: 0.8rem;
    color: #343434;
}

.front .byCategoryCard.menus{
    width: 100%;
    height: 100%;
}
.front .byCategoryCard.menus .img-1{
    position: absolute;
    top: -130px;
    left: -100px;
    bottom: auto;
    z-index: 10;
    bottom: auto;
}
.front .byCategoryCard.menus .img-2{
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0px;
    left: auto;
    bottom: auto;
}
.front .byCategoryCard.menus .img-3{
    z-index: 5;
    position: absolute;
    bottom: -50px;
    left: -250px;
    right: auto;
    top: auto;
}
.front .byCategoryCard.menus .img-4{
    z-index: 25;
    position: absolute;
    inset: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.menus{
    position: relative;
    background: -webkit-gradient(linear, right bottom, left top, from(#FCA624),color-stop(#FCA624) , to(#FCCC24)) !important;
    background: -o-linear-gradient(bottom right, #FCA624,#FCA624 , #FCCC24) !important;
    background: linear-gradient(to top left, #FCA624,#FCA624 , #FCCC24) !important;
}
.menus::after{
    z-index: 15;
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left bottom, right top, from(#FCCC24), color-stop(#fccd24c6), to(transparent));
    background: -o-linear-gradient(bottom left, #FCCC24, #fccd24c6, transparent);
    background: linear-gradient(to top right, #FCCC24, #fccd24c6, transparent);
}

/* Chefs part  */
.back .byCategoryCard.chefs{
    width: 100%;
    height: 100%;
}
.back .byCategoryCard.chefs .img-1{
    position: absolute;
    bottom: 0;
    right: 0;
    left: auto;
    z-index: 10;
    top: auto;
}
.back .byCategoryCard.chefs .img-2{
    z-index: 5;
    position: absolute;
    top: -200px;
    right: -150px;
    left: auto;
    bottom: auto;
}
.back .byCategoryCard.chefs .img-3{
    z-index: 5;
    position: absolute;
    bottom: -50px;
    right: auto;
    left: -270px;
    top: auto;
}
.back .byCategoryCard.chefs .byCategoryCard-text{
    position: absolute;
    bottom: 2rem;
    left: 1.5rem;
    z-index: 20;
    font-family: Arial, Helvetica, sans-serif;
}
.back .byCategoryCard.chefs .byCategoryCard-text .btn-byCategoryCard{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 20px;
    padding: 0.5rem 2rem;
    background-color: #FFFFFF;
    color: #151515;
}
.back .byCategoryCard.chefs .byCategoryCard-text h1{
    font-size: 1.3rem;
    font-weight: 800;
    margin: 0 0 0.5rem 0;
    color: #fff;
}
.back .byCategoryCard.chefs .byCategoryCard-text p{
    font-family: Roboto, sans-serif;
    margin: 0 0 1rem 0;
    width: calc(80% - 20px);
    font-size: 0.8rem;
    color: #A9A9A9;
}

.front .byCategoryCard.chefs{
    width: 100%;
    height: 100%;
}
.front .byCategoryCard.chefs .img-1{
    position: absolute;
    bottom: 0;
    right: 0;
    left: auto;
    z-index: 10;
    top: auto;
}
.front .byCategoryCard.chefs .img-2{
    z-index: 5;
    position: absolute;
    top: -200px;
    left: -250px;
    right: auto;
    bottom: auto;
}
.front .byCategoryCard.chefs .img-3{
    z-index: 5;
    position: absolute;
    bottom: -50px;
    right: -200px;
    left: auto;
    top: auto;
}
.front .byCategoryCard.chefs  .img-4{
    z-index: 25;
    position: absolute;
    inset: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.chefs{
    position: relative;
    background: -webkit-gradient(linear, right bottom, left top, from(#3C3C3C),color-stop(#3C3C3C) , to(#3C3C3C)) !important;
    background: -o-linear-gradient(bottom right, #3C3C3C,#3C3C3C , #3C3C3C) !important;
    background: linear-gradient(to top left, #3C3C3C,#3C3C3C , #3C3C3C) !important;
}
.chefs::after{
    z-index: 15;
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -o-linear-gradient(55deg, #272727,#272727d5, transparent);
    background: linear-gradient(35deg, #272727,#272727d5, transparent);
}

/* End of animation card  */

.line-separator2{
    color: #6A6A6A;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
    gap: 3px;
    font-weight: 600;
    justify-content: center;
}
.line-separator2::after{
    content: "";
    height: 100%;
    width: 1px;
    background-color: #6A6A6A;
}

.line-separator2::before{
    content: "";
    height: 100%;
    width: 1px;
    background-color: #6A6A6A;
}
@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}

@media (min-width:1700px){
    .headerWrapper{
        height: 55rem;
    }
    .header{
        height: 55rem;
    }
}
@media (min-width:1500px){
    .headerWrapper{
        height: 45rem;
    }
    .header{
        height: 45rem;
    }
    .kontainercardWrapper .kontainerList{
        margin: 50px;
    }
}
@media (min-width: 1000px) {
   

    .formKontainer{
        animation: 1.5s ease slideInFromRight;
    }

    .mottoKontainer{
        animation: 1.5s ease slideInFromLeft;
    }
}
@media (max-width: 1000px) {
    .btn.secondary,
    .btn.primary{
        width: fit-content;
    }
    .btn.secondary span{
        display: block;
    }
    .btn.secondary:hover{
        width: fit-content;
    }
    /* .TaplakGalleries__head .tabs .tabs__list.active{
        background-color: #1a1a1a98;
        border-radius: .5rem;
    }
    .TaplakGalleries__head .tabs .tabs__list.active::after,
    .TaplakGalleries__head .tabs .tabs__list::after
    {
        content: none;
    } */

    /* .TaplakGalleries__head .tabs{
        flex-direction: column;
    } */
    .TaplakGalleries .TaplakGalleries__body{
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    }
    .TaplakGalleries__head .tabs .tabs__list{
        padding-top: 1rem;
    }
    
    .TaplakFriends__logo{
        width: 25%;
    }
    .header{
        height: 70vh;
        width: 100%;
    }

    .kontenHeader{
        padding-top: 15vh;
        flex-direction: column;
        min-height: 800px;
        gap: 5rem;
    }

    .motto{
        font-size: 48px;
    }

    .miniMoto{
        font-size: 12px;
    }
    

    .mottoWrapper{
        bottom: 0;
        left: 10%;
        transform: translateY(-40%);
    }

    .mottoKontainer{
        flex: 50%;
    }

    .button1{
        font-size: 14px;
        margin-top: 3vh;
        padding: 15px 30px 15px 30px;
    }

    /* width: 70%; */
    .formWrapper{
        box-sizing: border-box;
        /* padding: 50px; */
        width: 85%;
        top:0;
        transform: translate(-50%, -0%);
    }

    .question{
        font-size: 14px;
    }

    .inputquestion{
        font-size: 9px;
    }

    .eventheader{
        font-size: 24px;
    }
}
@media only screen and (min-width:521px) and (max-width:1000px){
    .TaplakFriends__v2{
        padding: 50px 0;
    }
    .TaplakFriends__v2 .TaplakFriends__wrapper{
        padding: 0 20px;
    }
    .AboutUsHead{
        padding: 0;
    }
    .TaplakGalleries .section__wrapper{
        padding: 50px 20px;
    }

    /* Header side  */
    .headerWrapper .headerLeft{
        place-self: start;
        width: calc(90% - 0.5rem);
    }
    .headerWrapper .headerLeft h1{
        font-size: 2.8rem;
    }
    .headerWrapper .headerLeft p{
        font-size: 0.8rem;
    }
    .HowItGrids{
        gap: 2rem;
        padding: 0;
        grid-template-columns: 1fr 1fr;
    }
    .faqWrapper{
        padding: 100px 50px;
    }
    .headerWrapper{
        padding: 150px 50px 0 50px;
        grid-template-columns: 1fr;
        height: auto;
    }
    .headerWrapper .headerRight{
        width: 100%;
    }

    /* About us & How side  */
    .AboutUsWrapper{
        padding: 50px 20px;
    }
    .AboutUsContent{
        padding: 50px 20px 0 20px;
    }
    .AboutUsHead h1, 
    .HowItHeading h1{
        font-size: 1.7rem;
        margin: 0 0 0.5rem 0;
    }
    .AboutUsHead p,
    .HowItHeading p{
        width: calc(100% - 5px);
        font-size: 0.8rem;
    }
    .HowItWrapper,.faqWrapper{
        padding: 50px 20px;
    }
    .gridPointsText h1{
        font-size: 1.3rem;
    }
    .gridPoints p{
        font-size: 0.8rem;
    }
    .AboutUsContent .AboutUsBackground{
        height: 85%;
    }
    
    /* Button compilation  */
    .AboutUsHead .AboutUsButton, 
    .HowItHeading .HowItButton{
        margin: 0.8rem auto 0 auto;
        padding: 0.7rem 2rem;
        font-size: 0.6rem;
    }
    .headerButton,
    .btn__primary,
    .btn__secondary{
        padding: 0.7rem 2rem;
        font-size: 0.6rem;
    }
    /* Faq side  */
    .faqGridMain{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .faqHeadGrids{
        grid-template-columns: 1fr 1fr;
    }
    .faqGridImg{
        height: 15rem;
    }
    .faqInfos h1,
    .faqContents .faqHead h1{
        font-size: 1.2rem;
    }
    .faqInfos p,
    .faqContents .faqBody p{
        font-size: 1rem !important;
    }
}
@media (min-width:521px){
    .btn__primary.body,
    .btn__secondary.body{
        display: none;
    }
}
@media only screen and (min-width: 0px) and (max-width: 520px){
    .TaplakFriends__v2{
        padding: 50px 0;
    }
    .TaplakFriends__v2 .TaplakFriends__wrapper{
        padding: 0 20px;
    }
    .AboutUsHead{
        padding: 0;
    }
    
    .TaplakGalleries .section__wrapper{
        padding: 50px 20px;
    }

    /* Header side  */
    .headerWrapper .headerLeft{
        place-self: start;
        width: calc(100% - 0.5rem);
    }
    .headerWrapper .headerLeft h1{
        font-size: 2.3rem;
    }
    .headerWrapper .headerLeft p{
        font-size: 0.8rem;
    }
    .headerWrapper{
        padding: 150px 20px 0 20px;
        grid-template-columns: 1fr;
        height: auto;
    }
    .headerWrapper .headerRight{
        width: 100%;
    }
    
    /* Card side  */
    .card .kontainerImage{
        height: 240px !important;
    }
    .kontainercardWrapper{
        padding: 50px 0;
    }
    .kontainercardWrapper .kontainerHeader{
        padding: 0 20px;
    }
    .kontainercardWrapper .kontainerHeader h1{
        font-size: 24px;
    }
    .kontainercardWrapper .kontainerHeader p{
        flex-shrink: 0;
        font-size: 14px;
    }
    .kontainercardWrapper .kontainerList{
        margin: 20px;
    }
    .kontainercardWrapper .kontainerList.PageEventWide{
        margin: 20px;
    }
    .kontainercardWrapper .kontainerList.menuChef{
        margin: 20px;
    }
    .card{
        scroll-snap-align: center;
        width: 100% !important;
    }

    /* About us & How side  */
    .AboutUsWrapper{
        padding: 50px 20px;
    }
    .AboutUsContent{
        padding: 50px 20px 0 20px;
    }
    .AboutUsHead h1, 
    .HowItHeading h1{
        font-size: 1.7rem;
        margin: 0 0 0.5rem 0;
    }
    .taplakGalleries__popup{
        max-height: calc(60vh - 2rem);
    }
    .TaplakGalleries__head .tabs{
        flex-direction: column;
        margin-top: 2rem;
    }
    .TaplakGalleries .TaplakGalleries__head .text h1{
        font-size: 1.7rem;
    }
    .TaplakFriends__v2 h1{
        font-size: 1.7rem;
        margin-bottom: 2rem;
    }
    .AboutUsHead p,
    .HowItHeading p{
        width: calc(100% - 5px);
        font-size: 0.8rem;
    }

    .HowItWrapper,.faqWrapper{
        padding: 50px 20px;
    }
    .HowItGrids{
        padding: 0;
        grid-template-columns: 1fr;
    }
    .gridPointsText h1{
        font-size: 1.3rem;
    }
    .TaplakGalleries__head .tabs .tabs__list img{
        width: 24px;
    }
    .gridPoints p,
    .TaplakGalleries .TaplakGalleries__head .text p,
    .TaplakGalleries__head .tabs .tabs__list p{
        font-size: 0.8rem;
    }
    .AboutUsContent .AboutUsBackground{
        height: 85%;
    }

    /* Button compilation  */
    .AboutUsHead .AboutUsButton, 
    .HowItHeading .HowItButton{
        margin: 0.8rem auto 0 auto;
        padding: 0.7rem 2rem;
        font-size: 0.6rem;
    }
    .headerButton,
    .btn__primary,
    .btn__secondary{
        padding: 0.7rem 2rem;
        font-size: 0.6rem;
    }
    .btn__primary.heading,
    .btn__secondary.heading{
        display: none;
    }
    .btn__primary.body,
    .btn__secondary.body{
        margin: .5rem 0 0 0;
    }

    /* line separator  */
    .line-separator{
        padding: 0 20px;
    }

    /* Faq side  */
    .faqGridMain{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .faqHeadGrids{
        grid-template-columns: 1fr;
    }
    .faqGridImg{
        display: none;
    }
    .faqInfos h1,
    .faqContents .faqHead h1{
        font-size: 1.2rem;
    }
    .faqInfos p,
    .faqContents .faqBody p,
    .faqContents .faqBody a{
        font-size: 0.8rem !important;
    }
    
}
@media (max-width: 420px){
    .TaplakGalleries__head .tabs{
        overflow-y: clip;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
    }
    .TaplakGalleries__head .tabs .tabs__list{
        flex-shrink: 0;
        scroll-snap-align: center;
    }
}

@media (max-width:768px){
    .byCategoryWrapper .byCategoryContent{
        max-width: none;
        width: calc(90% - 1rem);
        grid-template-columns: 1fr;
    }
    .byCategoryContent .CardFlipWrap .flipper-hug .front{
        display: none;
    }
    .byCategoryContent .CardFlipWrap .flipper-hug .back{
        transform: none;
    }
    .byCategoryWrapper .byCategoryContent .CardFlipWrap:hover .flipper-hug{
        transform: none;
    }
    .byCategoryWrapper .byCategoryContent .CardFlipWrap{
        height: 200px;
    }
    .back .byCategoryCard.chefs .img-3{
        bottom: -100px;
        right: auto;
        left: -150px;
        top: auto;
    }
    .back .byCategoryCard.chefs .img-1{
        bottom: -170px;
        right: 0;
        left: auto;
        top: auto;
    }
    .back .byCategoryCard.chefs .img-2{
        top: -200px;
        right: -150px;
        left: auto;
        bottom: auto;
    }
    .line-separator2{
        flex-direction: row;
        gap: 1rem;
    }
    .line-separator2::after,
    .line-separator2::before
    {
        height: 1px;
        width: 100%;
    }
    .byCategoryWrapper .byCategoryTitle h1{
        font-size: 1.7rem;
    }
    .byCategoryWrapper .byCategoryTitle p{
        font-size: 0.8rem;
    }
}
@media (max-width: 414px){
    .byCategoryWrapper .byCategoryTitle p{
        width: calc(95% - 1rem);
    }
    .back .byCategoryCard.menus .byCategoryCard-text h1,
    .back .byCategoryCard.chefs .byCategoryCard-text h1{
        font-size: 1rem;
    }
    .back .byCategoryCard.menus .byCategoryCard-text p,
    .back .byCategoryCard.chefs .byCategoryCard-text p{
        font-size: 0.7rem;
    }
    .back .byCategoryCard.menus .byCategoryCard-text .btn-byCategoryCard,
    .back .byCategoryCard.chefs .byCategoryCard-text .btn-byCategoryCard{
        font-size: 0.7rem;
        padding: 0.4rem 1rem;
    }
    .byCategoryWrapper .byCategoryContent .CardFlipWrap{
        height: 170px;
    }
}