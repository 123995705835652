.DMPkontenkontainer{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20vh;
    padding-bottom: 10vh;
}

.DMPtrans-appear {
    opacity: 0;
    transform: translateX(50%);
}
.DMPtrans-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 1000ms;
}
.DMPtrans-enter {
    opacity: 0;
}
.DMPtrans-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}
.DMPtrans-exit {
    opacity: 1; 
    transform: translateX(0);
}
.DMPtrans-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 1000ms;
}

.DMPbutton:active{
    background-color: rgba(216, 175, 32, 1);
}

.DMPkontenkiri{
    flex: 50%;
    width: auto;
    min-height: 500px;
    padding-left: 20px;
    padding-right: 50px;
    height: auto;
    flex-direction: column;
}

.DMPkontenkanan{
    flex: 50%;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    height: min-content;
}

.DMPmenunav{
    align-items: center;
    margin-bottom: 24px;
}

.DMPiconnav1{
    margin-top: 2px;
    height: 24px;
    margin-left: auto;
    margin-right: 16px;
    cursor: pointer;
}

.DMPiconnav2{
    margin-top: 2px;
    height: 24px;
    margin-left: 16px;
    cursor: pointer;
}

.DMPbutton{
    flex-direction: column;
    width: auto;
    background-color: #FCCC24;
    padding: 15px;
    border-radius: 15px;
    margin-top: 48px;
    cursor: pointer;
}

.DMPcardkontainer{
    background-color: #1F1F1F;
    width: auto;
    height: 100px;
    height: 100%;
    border-radius: 25px;
    flex-direction: column;
}

.DMPcardkontainer2{
    background-color: #1F1F1F;
    width: 80%;
    height: 100px;
    height: 100%;
    border-radius: 25px;
    flex-direction: column;
}

.DMPcardatas{
    flex: 65%;
    background-color: white;
    border-radius: 15px 15px 0 0px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.DMPcardbawah{
    flex:35%;
    padding: 15px 35px 15px 35px;
}

.marginbig{
    margin-top: 48px;
}

.DMPiconcard{
    height: 48px;
}

@media (min-width: 1000px) {
    .backmobile{
        display: none;
    }

    .DMPbuttonmobile{
        display: none;
    }
}

@media (max-width: 1000px) {
    .DMPkontenkontainer{
        flex-direction: column-reverse;
    }

    .DMPkontenkontainer{
        padding-top: 80px;
    }

    .big{
        font-size: 36px;
    }

    .medium{
        font-size: 18px;
    }

    .DMPiconnav1{
        height: 18px;
        margin-top: 0;
    }

    .DMPiconnav2{
        height: 18px;
        margin-top: 0;
    }

    .small{
        font-size: 14px;
    }

    .DMPiconcard{
        height: 36px;
    }

    .marginbig{
        margin-top: 32px;
    }

    .DMPkontenkanan{
        margin-bottom: 32px;
    }

    .DMPbutton{
        margin-bottom: 32px;
        display: none;
    }

    .DMPkontenkiri{
        padding-right: 20px;
    }

    .DMPcardatas{
        min-height: 200px!important;
    }

    .DMPbuttonmobile{
        flex-direction: column;
        width: 80%;
        background-color: #FCCC24;
        padding: 15px;
        border-radius: 15px;
        cursor: pointer;
        position: fixed;
        bottom: 7%;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}