p{
    margin: 0;
}
.kontainer{
    z-index:100;
    position:fixed;
    width:100%;
    /* padding: 30px 0; */
}
.z-indexnone{
    z-index: 0;
    overflow:hidden !important;
}

.LGpopupuser{
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 8px;
    width: max-content;
    padding: 25px;
    margin-right: 20px;
    background-color: #1F1F1F;
}

.kontennavbar{
    box-sizing:border-box;
    padding: 0 50px;
    max-width: 1100px;
    /* max-width: 1440px; */
    height: 90px;
    display:flex;
    margin: auto;
    align-items:center;
    position: relative;
}

.logo{
    height: 30px;
    cursor: pointer;
}

.navigasi{
    font-family: 'Roboto', sans-serif;
    color:white;
    font-size: 18px;
    margin-left: 5%;
    cursor: pointer;
    position: relative;
}

.login{
    font-family: 'Roboto', sans-serif;
    color:white;
    margin-left:auto;
    font-size: 18px;
    cursor: pointer;
}

.underline{
    border-bottom: 2px solid #FCCC24;
    padding-bottom: 8px;
    padding-top: 10px;
}

.textnav{
    transition: color 0.3s;
}

.textnav:hover{
    color:#FCCC24!important;
}

@media (max-width: 1000px) {
    .kontainer{
        display: none;
    }
}