.kontainerMobileNavbar{
    z-index:9999;
    position:fixed;
    display:flex;
    width: 100%;
    height: 90px;
    align-items:center;
    padding: 0 50px;
    box-sizing: border-box;
    /* padding: 10px; */
}

.kontainerMobileNavbar .logo{
    height: 24px!important;
    margin-left:auto;
    cursor: pointer;
}

.menu{
    width: 24px;
    height: 24px!important;
    cursor: pointer;
}

.menutext{
    font-size: 18px;
    cursor: pointer;
    color: white;
}

.menutext2{
    font-size: 24px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    color: white;
}

.z-indexnone{
    z-index: 0;
    overflow:hidden !important;
}
.kontainerMobileNavbar .navigasi{
    font-family: 'Roboto', sans-serif;
    color:white;
    font-size: 18px;
    cursor: pointer;
}

.kontainerMobileNavbar .login{
    font-family: 'Roboto', sans-serif;
    color:white;
    margin-left:auto;
    font-size: 18px;
    cursor: pointer;
}

.kontainerMobileMenu{
    z-index:99999;
    position:fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;
    width: 100%;
    height: auto;
    padding: 35px 50px;
    background-color: black!important;
    box-sizing: border-box;
}

.shadow{
    z-index: 2;
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: black!important;
    opacity: 0.5;
}

@media (min-width: 1001px) {
    .kontainerMobileNavbar{
        display: none;
    }
}

@media (max-width: 632px){
    .kontainerMobileMenu{
        height: 100%;
        justify-content: start;
    }
}

@media (max-width: 520px){
    .kontainerMobileNavbar{
        padding: 0 20px;
    }
    .kontainerMobileMenu{
        padding: 35px 20px;
    }
}