@keyframes OTPslideUP{
    0%{
        top: 150%;
        opacity: 0;
    }
    100%{
        top: 50%;
        opacity: 1;
    }
}
@keyframes OTPslideUP2{
    0%{
        top: 150%;
        opacity: 0;
    }
    100%{
        top: 50%;
        opacity: 1;
    }
}
@keyframes OTPslideUP3{
    0%{
        top: 150%;
        opacity: 0;
    }
    100%{
        top: 50%;
        opacity: 1;
    }
}
@keyframes popOut{
    0%{
        transform: scale(0.3);
        opacity: 0;
    }
    50%{
        transform: scale(1);
        opacity: 1;
    }
    80%{
        transform: scale(0.93);
        opacity: 1;
    }
    100%{
        transform: scale(1);
        opacity: 1;
        opacity: 1;
    }
}
.popOut{
    animation: popOut .5s;
    animation-fill-mode: forwards;
}
.OTPslideUP{
    animation: OTPslideUP .6s;
    animation-fill-mode: forwards;
}
.OTPslideUP2{
    animation: OTPslideUP2 .6s;
    animation-fill-mode: forwards;
}
.OTPslideUP3{
    animation: OTPslideUP3 .6s;
    animation-fill-mode: forwards;
}

.PFPkontainer{
    box-sizing: border-box;
    max-width: 1100px;
    margin: 0 auto;
    padding: 150px 50px 0 50px;
    font-family: Arial, Helvetica, sans-serif;
}

.PFPkonten{
    max-width: 500px;
    margin-left: 20px;
}

.LoginKontainer{
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.6;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    z-index: 100;
}

.LoginKonten.FPOTP{
    position: fixed;
    max-width:800px;
    width: 100%;
    height: auto;
    margin:auto;
    z-index: 120;
    background-color: white;
    border-radius: 15px;
    opacity: 0;
    left: 50%;
    padding: 50px;
    transition: all .5s;
}

.PFPbutton{
    width: 100%;
    border: none;
    outline: none;
    background-color: #FCCC24;
    padding: 15px 150px 15px 150px;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
}

.PFPunderline{
    text-decoration: underline;
    cursor: pointer;
}

.LoginKonten.FPOTP::-webkit-scrollbar{
    width:0;
    height:0;
}

.LGform{
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 15px;
    border-radius: 15px;
    outline: none;
    margin-bottom: 8px;
    border: 1px solid #6F6F6F;
}

.LGform:focus{
    border: 1px solid #FCCC24;
}

.LGerror{
    border: 1px solid #FA591D!important;
}

.PFPmargin{
    margin-top: 48px;
}

.PFPmargin2{
    margin-top: 12px;
}

@media (max-width: 1000px) {
    .PFPkontainer{
        padding: 150px 20px;
    }

    .LoginKonten.FPOTP{
        max-width: 600px;
    }

    .LGform[placeholder] {
        overflow: hidden;  
        text-overflow:ellipsis;
        white-space: nowrap;
    }

    .PFPkonten{
        width: unset;
        margin-right: 20px;
    }

    .PFPbutton{
        padding: 15px 15px;
        width: 100%;
        display: block;
    }

    .PFPmiddle{
        text-align: center;
    }
}

@media (max-width: 735px){
    .LoginKonten.FPOTP{
        max-width: 450px;
    }
}
@media (max-width: 570px){
    .LoginKonten.FPOTP{
        max-width: 350px;
        padding: 30px;
    }
}
@media (max-width: 490px){
    .LoginKonten.FPOTP{
        width: 70%;
        padding: 25px;
    }
}